import { useEffect, useState } from "react";

function PreviewImage({ mainImage, secure_url }) {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    console.log(mainImage);

    if (mainImage) {
      const reader = new FileReader();
      reader.readAsDataURL(mainImage);
      reader.onload = () => {
        setPreview(reader.result);
      };
    } else {
      setPreview(null);
    }
  }, [mainImage]);
  return (
    <div className="w-full">
      <img src={preview || secure_url} className="w-full" alt="" />
    </div>
  );
}

export default PreviewImage;

// import { useEffect, useState } from "react";

// function PreviewImage({ file, mainImage }) {
//   const [previewImage, setPreviewImage] = useState(null);

//   useEffect(() => {
//     console.log(file);

//     if (file) {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => {
//         setPreviewImage(reader.result);
//       };
//     }
//   }, []);
//   return (
//     <div className="w-full">
//       <img src={previewImage || mainImage} className="w-full" alt="" />
//     </div>
//   );
// }

// export default PreviewImage;

// <div className="col-span-full">
//   <label
//     htmlFor="mainImage"
//     className="font-medium text-sm md:text-base"
//   >
//     Main image
//   </label>
//   <input
//     className="hidden"
//     id="mainImage"
//     name="mainImage"
//     type="file"
//     onChange={handleFileChange}
//     accept="image/*"
//   />

//   <label
//     className={`mt-1 ${
//       formik.errors.mainImage && formik.touched.mainImage ? "" : "mb-3"
//     } bg-white  font-medium border-2 focus:border-main cursor-pointer pt-2 pb-10 px-4 flex items-center gap-3 w-full`}
//     htmlFor="mainImage"
//   >
//     attach a file <BsCloudUpload />
//     <div className="w-20">
//       {mainImage?.name ? (
//         <PreviewImage
//           file={mainImage}
//         />
//       ) : (
//         <PreviewImage
//           mainImage={product?.mainImage?.secure_url}
//         />
//       )}
//     </div>
//   </label>

//   {formik.errors.mainImage && formik.touched.mainImage ? (
//     <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
//       {formik.errors.mainImage}
//     </div>
//   ) : (
//     ""
//   )}        <div className="col-span-full">
//             <label
//               htmlFor="mainImage"
//               className="font-medium text-sm md:text-base"
//             >
//               Main image
//             </label>
//             <input
//               className="hidden"
//               id="mainImage"
//               name="mainImage"
//               type="file"
//               onChange={handleFileChange}
//               accept="image/*"
//             />

//             <label
//               className={`mt-1 ${
//                 formik.errors.mainImage && formik.touched.mainImage ? "" : "mb-3"
//               } bg-white  font-medium border-2 focus:border-main cursor-pointer pt-2 pb-10 px-4 flex items-center gap-3 w-full`}
//               htmlFor="mainImage"
//             >
//               attach a file <BsCloudUpload />
//               <div className="w-20">
//                 {mainImage?.name ? (
//                   <PreviewImage
//                     file={mainImage}
//                   />
//                 ) : (
//                   <PreviewImage
//                     mainImage={product?.mainImage?.secure_url}
//                   />
//                 )}
//               </div>
//             </label>

//             {formik.errors.mainImage && formik.touched.mainImage ? (
//               <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
//                 {formik.errors.mainImage}
//               </div>
//             ) : (
//               ""
//     )}
//                </div>
