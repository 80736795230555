import React, { useContext, useState } from "react";
import header1 from "../../../assets/3.jpg";
import header2 from "../../../assets/2.jpg";
import header3 from "../../../assets/5.jpg";
import { MdOutlineSearch } from "react-icons/md";
import { useQuery } from "react-query";
import axios from "axios";
import { UserContext } from "../../../Context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { ProductContext } from "../../../Context/ProductContext";
import { CategoryContext } from "../../../Context/CategoryContext";
import { BrandContext } from "../../../Context/BrandContext";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";

function Header() {
  const {
    filteredProductNames,
    setSearchProduct,
    setChange,
    allProducts,
    productsLoading,
  } = useContext(ProductContext);
  const { filteredCategoryNames, setSelectedCategory } =
    useContext(CategoryContext);
  const { filteredBrandNames, setSelectedBrand } = useContext(BrandContext);
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery } = useContext(UserContext);

  const handleSearchSubmit = () => {
    if (searchQuery?.length && filteredProductNames?.length) {
      setSearchProduct(searchQuery);
      setSearchQuery("");
      navigate("products");
    }
  };

  const [activeTowns, setActiveTowns] = useState(false);
  const [activestudio, setActiveStudio] = useState(false);
  const [activeModern, setActiveModern] = useState(false);

  const [isOpenedFelter, setIsOpenedFelter] = useState(false);
  const [searchBy, setSearchBy] = useState("product");

  const mostViews = allProducts
    ?.sort((a, b) => b.visits?.length - a.visits?.length)
    .slice(0, 3);

  const activestudioLink = () => {
    setActiveStudio(!activestudio);
  };
  const activeTownLink = () => {
    setActiveTowns(!activeTowns);
  };
  const activeModernLink = () => {
    setActiveModern(!activeModern);
  };

  return (
    <header className="bg-headerColor h-screen flex items-center justify-center">
      <div className="container lg:w-[90%] max-w-screen-xl mx-auto px-5">
        <div className="grid grid-cols-12">
          <div className="col-span-12  flex flex-col justify-center gap-7 lg:col-span-6">
            <h1 className="text-2xl text-center font-medium lg:text-start md:font-semibold md:3xl lg:font-bold xl:text-4xl text-main">
              <span className="text-socend">The First </span> Handling <br />{" "}
              Equipment Marketplace
              <span className="text-socend"> In MEA</span>
            </h1>
            <div className="py-16">
              <label className="text-lg md:hidden">Enter Keyword</label>
              <div className="relative">
                <div className="absolute translate-x-0 w-fit flex items-center justify-center gap-2 lg:gap-8 pr-2 lg:px-10 bg-white top-2 lg:top-1/2 lg:-translate-y-1/2 -left-2 lg:left-0 py-2 rounded-md">
                  <div className="hidden md:flex flex-col items-start justify-start min-w-fit">
                    <p className="text-[#969696] text-sm md:text-[16px]">
                      Keyword
                    </p>
                    <p className="text-lg">Enter Keyword</p>
                  </div>

                  <div className="flex items-center gap-2 lg:gap-5 ">
                    <label htmlFor="search" className="">
                      <MdOutlineSearch className="text-xl hidden md:block  lg:text-4xl" />
                    </label>
                    <div
                      className={`relative ${
                        searchBy == "product" ? "w-32" : "w-fit"
                      }  lg:w-40`}
                    >
                      <input
                        type="text"
                        id="search"
                        placeholder={`${
                          searchBy == "product"
                            ? "Search by Product"
                            : searchBy == "category"
                            ? "Search by Category"
                            : searchBy == "brand"
                            ? "Search by Brand"
                            : "Search by Product"
                        }`}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="outline-0 md:border-l-2 pl-2  lg:pl-5 text-sm lg:text-[16px] py-2 w-full"
                      />
                      {searchBy == "product" ? (
                        !filteredProductNames?.length || !searchQuery ? (
                          ""
                        ) : (
                          <div className="absolute top-full left-0 w-full bg-white overflow-y-auto scrollbar-thin scrollbar-webkit flex flex-col gap-1 border-2 max-h-[20vh] rounded-md p-2">
                            {filteredProductNames.map((product) => {
                              return (
                                <Link
                                  to={`product/${product._id}`}
                                  className="py-1 px-2 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                                >
                                  <span className=" overflow-hidden text-ellipsis whitespace-nowrap block max-w-full">
                                    {product.enName}
                                  </span>
                                </Link>
                              );
                            })}
                          </div>
                        )
                      ) : searchBy == "category" ? (
                        !filteredCategoryNames?.length || !searchQuery ? (
                          ""
                        ) : (
                          <div className="absolute top-full left-0 w-full bg-white overflow-y-auto scrollbar-thin scrollbar-webkit flex flex-col gap-1 border-2 max-h-[20vh] rounded-md p-2">
                            {filteredCategoryNames.map((category) => {
                              return (
                                <button
                                  onClick={() => {
                                    setSelectedCategory(category);
                                    setChange({
                                      item: category,
                                      type: "Categories",
                                    });
                                    setSearchQuery("");
                                    navigate("/products");
                                  }}
                                  className="py-1 px-2 rounded-md cursor-pointer hover:bg-main font-medium text-main hover:text-white  duration-300"
                                >
                                  <span className=" overflow-hidden text-ellipsis whitespace-nowrap block max-w-full">
                                    {category.enName}
                                  </span>
                                </button>
                              );
                            })}
                          </div>
                        )
                      ) : searchBy == "brand" ? (
                        !filteredBrandNames?.length || !searchQuery ? (
                          ""
                        ) : (
                          <div className="absolute top-full left-0 w-full bg-white overflow-y-auto scrollbar-thin scrollbar-webkit flex flex-col gap-1 border-2 max-h-[20vh] rounded-md p-2">
                            {filteredBrandNames.map((brand) => {
                              return (
                                <button
                                  onClick={() => {
                                    setSelectedBrand(brand);
                                    setChange({
                                      item: brand,
                                      type: "Brands",
                                    });
                                    setSearchQuery("");
                                    navigate("/products");
                                  }}
                                  className="py-1 px-2 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                                >
                                  <span className=" overflow-hidden text-ellipsis whitespace-nowrap block max-w-full">
                                    {brand.enName}
                                  </span>
                                </button>
                              );
                            })}
                          </div>
                        )
                      ) : null}
                    </div>
                    <div className="flex items-center gap-1 lg:gap-3">
                      <div
                        onClick={() => setIsOpenedFelter((prev) => !prev)}
                        className={`${
                          isOpenedFelter ? "border-main" : ""
                        } bg-transparent relative select-none cursor-pointer flex items-center gap-2 rounded-md text-sm lg:text-[16px] w-28 py-1 px-2 lg:px-2 border-2   duration-300`}
                      >
                        Filter by
                        {isOpenedFelter ? (
                          <AiOutlineCaretUp />
                        ) : (
                          <AiOutlineCaretDown />
                        )}
                        <div
                          className={`${
                            isOpenedFelter ? "block" : "hidden"
                          } absolute p-2 border-2 bg-white z-30 left-0 top-full right-0 border-main text-center`}
                        >
                          <div className="flex gap-1 flex-col">
                            <div
                              onClick={() => {
                                setSearchBy("product");
                              }}
                              className="py-1 rounded-md cursor-pointer text-main  hover:text-white hover:bg-main font-medium duration-300"
                            >
                              Product
                            </div>
                            <div
                              onClick={() => {
                                setSearchBy("category");
                              }}
                              className="py-1 rounded-md cursor-pointer text-main  hover:text-white hover:bg-main font-medium duration-300"
                            >
                              Category
                            </div>
                            <div
                              onClick={() => {
                                setSearchBy("brand");
                              }}
                              className="py-1 rounded-md cursor-pointer text-main  hover:text-white hover:bg-main font-medium duration-300"
                            >
                              Brand
                            </div>
                          </div>
                        </div>
                      </div>
                      {searchBy == "product" ? (
                        <button
                          type="submit"
                          onClick={() => handleSearchSubmit()}
                          className="bg-main text-white rounded-md text-sm lg:text-[16px] py-1 px-2 lg:px-5 border-2 border-transparent hover:bg-[#fdb446] duration-300"
                        >
                          Search
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {productsLoading ? (
              <div className="flex items-center flex-wrap lg:flex-nowrap gap-2 ">
                <span className="whitespace-nowrap">Most views:</span>
                {Array(3)
                  .fill(0)
                  .map((item, index) => (
                    <Skeleton
                      key={index}
                      className={`rounded-3xl py-3 px-4 w-24  lg:w-32`}
                    />
                  ))}
              </div>
            ) : mostViews?.length ? (
              <div className="flex items-center flex-wrap lg:flex-nowrap gap-2 ">
                <span className="whitespace-nowrap">Most views:</span>
                {mostViews?.map((product) => (
                  <Link
                    key={product?._id}
                    to={`/product/${product?._id}`}
                    className={`rounded-3xl hover:text-white hover:bg-main hover:border-main duration-300 border-2 py-1 lg:py-2 px-2 lg:px-4 block overflow-hidden text-ellipsis whitespace-nowrap max-w-24  lg:max-w-32`}
                  >
                    {product.enName}
                  </Link>
                ))}
              </div>
            ) : (
              <div className="flex items-center flex-wrap lg:flex-nowrap gap-2 ">
                <span className="whitespace-nowrap">Most views:</span>
                <p>There is no product viewed</p>
              </div>
            )}
          </div>

          <div className="hidden lg:block lg:col-span-6">
            <div className="flex items-center mt-16">
              <div className="w-1/2 p-5 mt-12">
                <img
                  src={header1}
                  className="w-full rounded-3xl"
                  alt="Header 1"
                />
              </div>
              <div className="w-1/2  flex flex-col">
                <div className="flex-none  p-5">
                  <img
                    src={header2}
                    className="w-full rounded-3xl "
                    alt="Header 2"
                  />
                </div>
                <div className="flex-none p-5">
                  <img
                    src={header3}
                    className="w-full rounded-3xl"
                    alt="Header 3"
                  />
                </div>
              </div>
            </div>
            {/* <img className="w-full" src={mainImage} alt="mainImage" /> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
