import React, { useContext, useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../Context/UserContext";
import { VscThreeBars } from "react-icons/vsc";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

function Sidebar({ isOpened, setIsOpened }) {
  const { setToken, setUserData } = useContext(UserContext);
  const [isOpenedUser, setIsOpenedUser] = useState(false);
  const [isOpenedProduct, setIsOpenedProduct] = useState(false);
  const [isOpenedCategory, setIsOpenedCategory] = useState(false);
  const [isOpenedSubcategory, setIsOpenedSubcategory] = useState(false);
  const [isOpenedBrand, setIsOpenedBrand] = useState(false);
  const [isOpenedReview, setIsOpenedReview] = useState(false);
  const [isOpenedProfile, setIsOpenedProfile] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    setToken(null);
    setUserData(null);
    navigate("/login");
  };

  const { pathname } = useLocation();

  const activeLinkClasses = ({ type = "/dashboard/traderRequests" } = {}) => {
    let subPage;
    if (pathname.includes("trader") || pathname.includes("user")) {
      subPage = "traderRequests";
    } else {
      if (pathname.includes("category")) {
        subPage = "category";
      }
      if (pathname.includes("subcategory")) {
        subPage = "subcategory";
      }
      if (pathname.includes("brand")) {
        subPage = "brand";
      }
      if (pathname.includes("product")) {
        subPage = "product";
      }
      if (pathname.includes("reviewRequests")) {
        subPage = "reviewRequests";
      }
      if (
        pathname === "/dashboard" ||
        pathname === "/dashboard/" ||
        pathname.includes("profile")
      ) {
        subPage = "profile";
      }
    }

    let classes = "";
    if (type === subPage) {
      classes += "bg-main";
    }
    return classes;
  };

  return (
    <div
      className={` w-full h-screen bg-gray-900 text-white transition-all duration-300 ${
        isOpened ? "block" : "hidden"
      } lg:block`}
    >
      <div className="flex items-center border-b-2 border-gray-700 py-5 px-2 lg:px-5 justify-between gap-2">
        <h2 className="text-3xl font-bold text-main">Mnawla</h2>
        <button
          className={`lg:hidden outline-none ${isOpened ? "" : "hidden"}`}
          onClick={() => setIsOpened((prev) => !prev)}
        >
          <VscThreeBars className="text-3xl" />
        </button>
      </div>
      <ul className="flex flex-col text-lg font-medium gap-1 my-3">
        <li>
          <NavLink
            className={`hover:bg-main duration-300 py-2 px-3 w-full flex items-center gap-2 ${activeLinkClasses(
              { type: "profile" }
            )}`}
            onClick={() => setIsOpenedProfile((prev) => !prev)}
            to="profile"
          >
            {(pathname === "/dashboard" ||
              pathname === "/dashboard/" ||
              pathname.includes("profile")) &&
            isOpenedProfile ? (
              <FiMinusCircle />
            ) : (
              <FiPlusCircle />
            )}
            Profile
          </NavLink>
          <ul
            className={`${
              (pathname === "/dashboard" ||
                pathname === "/dashboard/" ||
                pathname.includes("profile")) &&
              isOpenedProfile
                ? "flex"
                : "hidden"
            } flex-col text-lg font-medium my-1`}
          >
            <li>
              <NavLink
                to="profile"
                className={`hover:text-main ${
                  pathname === "/dashboard" ||
                  pathname === "/dashboard/" ||
                  pathname.includes("profile")
                    ? "text-main"
                    : ""
                } duration-300 ml-10`}
              >
                Profile data
              </NavLink>
            </li>
          </ul>
        </li>

        <li>
          <NavLink
            className={`hover:bg-main duration-300 py-2 px-3 w-full flex items-center gap-2 ${activeLinkClasses(
              { type: "traderRequests" }
            )}`}
            onClick={() => setIsOpenedUser((prev) => !prev)}
            to="allusers"
          >
            {(pathname.includes("trader") || pathname.includes("user")) &&
            isOpenedUser ? (
              <FiMinusCircle />
            ) : (
              <FiPlusCircle />
            )}
            User
          </NavLink>
          <ul
            className={`${
              (pathname.includes("trader") || pathname.includes("user")) &&
              isOpenedUser
                ? "flex"
                : "hidden"
            } flex-col text-lg font-medium my-1`}
          >
            <li>
              <NavLink
                to="allusers"
                className={`hover:text-main ${
                  pathname === "/dashboard/allusers" ? "text-main" : ""
                } duration-300 ml-10`}
              >
                All users
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/traderRequests"
                className={`hover:text-main ${
                  pathname.includes("traderRequests") ? "text-main" : ""
                } duration-300 ml-10`}
              >
                Trader requests
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            className={`hover:bg-main duration-300 py-2 px-3 w-full flex items-center gap-2 ${activeLinkClasses(
              { type: "product" }
            )}`}
            onClick={() => setIsOpenedProduct((prev) => !prev)}
            to="allproducts"
          >
            {pathname.includes("product") && isOpenedProduct ? (
              <FiMinusCircle />
            ) : (
              <FiPlusCircle />
            )}
            Product
          </NavLink>
          <ul
            className={`${
              pathname.includes("product") && isOpenedProduct
                ? "flex"
                : "hidden"
            } flex-col text-lg font-medium my-1`}
          >
            <li>
              <NavLink
                to="allproducts"
                className={`hover:text-main ${
                  pathname.includes("allproducts") ? "text-main" : ""
                } duration-300 ml-10`}
              >
                All products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="productRequests"
                className={`hover:text-main ${
                  pathname.includes("productRequests") ? "text-main" : ""
                } duration-300 ml-10`}
              >
                Product reaquests
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            className={`hover:bg-main duration-300 py-2 px-3 w-full flex items-center gap-2 ${activeLinkClasses(
              { type: "category" }
            )}`}
            onClick={() => setIsOpenedCategory((prev) => !prev)}
            to="category"
          >
            {(pathname.includes("/category") ||
              pathname.includes("addcategory")) &&
            isOpenedCategory ? (
              <FiMinusCircle />
            ) : (
              <FiPlusCircle />
            )}
            Category
          </NavLink>
          <ul
            className={`${
              (pathname.includes("category") ||
                pathname.includes("addcategory")) &&
              isOpenedCategory
                ? "flex"
                : "hidden"
            } flex flex-col text-lg font-medium my-1`}
          >
            <li>
              <NavLink
                to="category"
                className={`hover:text-main ${
                  pathname === "/dashboard/category" ? "text-main" : ""
                } duration-300 ml-10`}
              >
                All categories
              </NavLink>
            </li>
            <li>
              <NavLink
                to="addcategory"
                className={`hover:text-main ${
                  pathname.includes("addcategory") ? "text-main" : ""
                } duration-300 ml-10`}
              >
                Create category
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            className={`hover:bg-main duration-300 py-2 px-3 w-full flex items-center gap-2 ${activeLinkClasses(
              { type: "subcategory" }
            )}`}
            onClick={() => setIsOpenedSubcategory((prev) => !prev)}
            to="createsubcategory"
          >
            {pathname.includes("subcategory") && isOpenedSubcategory ? (
              <FiMinusCircle />
            ) : (
              <FiPlusCircle />
            )}
            Subcategory
          </NavLink>
          <ul
            className={`${
              pathname.includes("subcategory") && isOpenedSubcategory
                ? "flex"
                : "hidden"
            } flex-col text-lg font-medium my-1`}
          >
            <li>
              <NavLink
                to="createsubcategory"
                className={`hover:text-main ${
                  pathname.includes("createsubcategory") ? "text-main" : ""
                } duration-300 ml-10`}
              >
                Create subcategory
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            className={`hover:bg-main duration-300 py-2 px-3 w-full flex items-center gap-2 ${activeLinkClasses(
              { type: "brand" }
            )}`}
            onClick={() => setIsOpenedBrand((prev) => !prev)}
            to="brand"
          >
            {pathname.includes("brand") && isOpenedBrand ? (
              <FiMinusCircle />
            ) : (
              <FiPlusCircle />
            )}
            Brand
          </NavLink>
          <ul
            className={`${
              pathname.includes("brand") && isOpenedBrand ? "flex" : "hidden"
            } flex-col text-lg font-medium my-1`}
          >
            <li>
              <NavLink
                to="brand"
                className={`hover:text-main ${
                  pathname === "/dashboard/brand" ? "text-main" : ""
                } duration-300 ml-10`}
              >
                All brands
              </NavLink>
            </li>
            <li>
              <NavLink
                to="addbrand"
                className={`hover:text-main ${
                  pathname.includes("addbrand") ? "text-main" : ""
                } duration-300 ml-10`}
              >
                Create brand
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            className={`hover:bg-main duration-300 py-2 px-3 w-full flex items-center gap-2 ${activeLinkClasses(
              { type: "reviewRequests" }
            )}`}
            onClick={() => setIsOpenedReview((prev) => !prev)}
            to="reviewRequests"
          >
            {pathname.includes("reviewRequests") && isOpenedReview ? (
              <FiMinusCircle />
            ) : (
              <FiPlusCircle />
            )}
            Review
          </NavLink>
          <ul
            className={`${
              pathname.includes("reviewRequests") && isOpenedReview
                ? "flex"
                : "hidden"
            } flex-col text-lg font-medium my-1`}
          >
            <li>
              <NavLink
                to="reviewRequests"
                className={`hover:text-main ${
                  pathname.includes("reviewRequests") ? "text-main" : ""
                } duration-300 ml-10`}
              >
                Review requests
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
      <button
        onClick={logout}
        className="flex items-center gap-2 hover:bg-main px-5 py-2 w-full duration-300"
      >
        <IoIosLogOut />
        Sign-out
      </button>
    </div>
  );
}

export default Sidebar;
