import React, { useContext, useEffect, useState } from "react";
import { IoStarSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { ProductContext } from "../../../Context/ProductContext";
import { UserContext } from "../../../Context/UserContext";
import Skeleton from "react-loading-skeleton";
import ProductsLoading from "../../Profile/MyProducts/SkeletonComponents/ProductsLoading";
import Product from "../../Products/Product/Product";

function RequiredProducts({ categories, categoryIsLoading }) {
  const { allProducts, productsLoading } = useContext(ProductContext);
  const [activeTab, setActiveTab] = useState("all");
  const handleTabLink = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="py-8">
      <div className="container lg:w-[90%] mx-auto px-5">
        <div className="">
          <p className="text-main font-thin uppercase">CHECKOUT OUR NEW</p>
          <h2 className="text-3xl font-bold mt-2 uppercase">most required</h2>
        </div>

        {!categoryIsLoading && !categories?.length ? (
          <div className="pt-5">
            <p className="text-xl font-bold">
              There is no data on site till now
            </p>
          </div>
        ) : (
          <>
            <ul className=" flex items-center flex-wrap justify-start lg:justify-center gap-2 lg:gap-5 mt-10">
              <li>
                <button
                  onClick={() => handleTabLink("all")}
                  className={`${
                    activeTab === "all" ? "text-white bg-main" : "bg-gray-300"
                  }  py-2 px-3 lg:px-5 rounded-lg`}
                >
                  All
                </button>
              </li>
              {categories.length
                ? categories.slice(0, 3).map((category) => (
                    <li key={category._id}>
                      <button
                        onClick={() => handleTabLink(category.enName)}
                        className={`${
                          activeTab === category.enName
                            ? "text-white bg-main"
                            : "bg-gray-300"
                        }  py-2 px-3 lg:px-5 rounded-lg`}
                      >
                        {category.enName}
                      </button>
                    </li>
                  ))
                : Array(3)
                    .fill(0)
                    .map((item, index) => (
                      <li key={index} className="w-24">
                        <Skeleton className="h-7 w-[full] rounded-lg" />
                      </li>
                    ))}
            </ul>
            <div>
              {activeTab === "all" && (
                <div className="grid grid-cols-12 gap-6 mt-10">
                  {!productsLoading && allProducts?.length ? (
                    allProducts.slice(0, 4).map((product) => {
                      return (
                        <Product
                          key={product._id}
                          product={product}
                          productType={"requiredProduct"}
                        />
                      );
                    })
                  ) : productsLoading ? (
                    Array(4)
                      .fill(0)
                      .map((item, index) => <ProductsLoading key={index} />)
                  ) : (
                    <div className="col-span-12">
                      <p className="text-lg font-bold">
                        There is no products in this category
                      </p>
                    </div>
                  )}
                </div>
              )}
              {categories?.slice(0, 3).map(
                (category) =>
                  activeTab === category.enName && (
                    <div
                      key={category._id}
                      className="grid grid-cols-12 gap-6 mt-10"
                    >
                      {!category.numOfProductsAccepted ? (
                        <div className="col-span-12">
                          <p className="text-lg font-bold">
                            There is no products in this category
                          </p>
                        </div>
                      ) : (
                        category.products
                          .filter((product) => product.accepted)
                          ?.slice(0, 4)
                          .map((product) => {
                            return (
                              <Product
                                key={product._id}
                                product={product}
                                productType={"requiredProduct"}
                              />
                            );
                          })
                      )}
                    </div>
                  )
              )}
            </div>
            <Link
              to={`products`}
              className="py-2 px-4 bg-main text-white rounded-xl block mt-10 w-fit mx-auto"
            >
              View More
            </Link>
          </>
        )}
      </div>
    </section>
  );
}

export default RequiredProducts;
