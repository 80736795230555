import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";
import { UserContext } from "../../Context/UserContext";
import mainLogo from "../../assets/mainLogos.jpg.png";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { socket } from "../../utils/Socket";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenedProfile, setIsOpenedProfile] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { token, setToken, setUserData, userData, avatar, profile } =
    useContext(UserContext);
  const navigate = useNavigate();

  // const getUserProfile = async () => {
  //   setIsLoading(true);
  //   const result = await profile();
  //   if (result.data?.user) {
  //       setIsLoading(false);
  //       setUserData(result.data.user);
  //       // setUser(result.data.user);
  //     }
  //     if (result?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
  //       setIsLoading(false);
  //       localStorage.clear();
  //       setToken(null);
  //       setUserData(null);
  //       navigate("/login");
  //     }
  //   };

  const getUserProfile = async () => {
    setIsLoading(true);
    const result = await profile();
    setIsLoading(false);

    if (result?.data?.user) {
      setUserData(result.data.user);
    }
    if (result?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      navigate("/login");
    }
  };
  useEffect(() => {
    if (token) {
      getUserProfile();
      socket.emit("updateSocketId", { authorization: `Hamada__${token}` });
      socket.on("updateSocketId", (data) => {
        // console.log(data);
      });
      socket.on("authSocket", (data) => {
        if (data == "Expire token") {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    }
  }, [token]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const { pathname } = useLocation();

  const activeLinkClasses = ({ type = null } = {}) => {
    let subPage;
    if (pathname == "/") {
      subPage = "home";
    } else {
      subPage = pathname.split("/")[1];
    }
    let classes = "text-lg duration-100 font-bold ";
    if (type == subPage) {
      classes += "text-main";
    } else {
      classes += "hover:text-main";
    }
    return classes;
  };

  const logout = () => {
    localStorage.clear();
    setToken(null);
    setUserData(null);
    setIsOpenedProfile(!isOpenedProfile);
    navigate("/login");
  };

  // Handle the scroll event
  const handleScroll = () => {
    if (window.scrollY > 0) {
      // Adjust this value based on when you want to add the background
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Add event listener for scroll when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`${
        scrolled
          ? "bg-headerColor"
          : ` ${
              isOpen ? "bg-headerColor lg:bg-transparent" : "bg-transparent"
            }  ${
              pathname == "/login" ||
              pathname == "/contact" ||
              pathname == "/about" ||
              pathname == "/products" ||
              pathname.includes("traderProducts") ||
              pathname.includes("category") ||
              pathname.includes("/Register") ||
              pathname.includes("/products/") ||
              pathname.includes("/subscribtionPlan") ||
              pathname.includes("Register") ||
              pathname.includes("forgetPassword")
                ? isOpen
                  ? "lg:text-white"
                  : "text-white"
                : ""
            }`
      } px-5 py-4 flex md:block fixed z-50 top-0 left-0 w-full`}
    >
      <div className="container  mx-auto px-5 flex gap-5 lg:flex-row items-start lg:items-center justify-between">
        <div className="">
          <NavLink
            className="text-main text-xl md:text-3xl  outline-none font-bold"
            to="/"
          >
            <img className="h-10 object-cover" src={mainLogo} alt="mainLogo" />
          </NavLink>
          <ul
            id="list"
            className={`${
              isOpen ? "flex" : "hidden"
            } lg:hidden flex-col items-start gap-3 mt-3`}
          >
            <li id="menu">
              <NavLink
                className={` ${activeLinkClasses({
                  type: "home",
                })} outline-none`}
                onClick={() => toggleNavbar()}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li id="menu">
              <NavLink
                className={` ${activeLinkClasses({
                  type: "category",
                })} outline-none`}
                onClick={() => toggleNavbar()}
                to="/category"
              >
                Categories
              </NavLink>
            </li>

            <li id="menu">
              <NavLink
                className={` ${activeLinkClasses({
                  type: "products",
                })} outline-none`}
                onClick={() => toggleNavbar()}
                to="/products"
              >
                Products
              </NavLink>
            </li>
            <li id="menu">
              <NavLink
                className={` ${activeLinkClasses({
                  type: "about",
                })} outline-none`}
                onClick={() => toggleNavbar()}
                to="/about"
              >
                About US
              </NavLink>
            </li>
            <li id="menu">
              <NavLink
                className={` ${activeLinkClasses({
                  type: "contact",
                })} outline-none`}
                onClick={() => toggleNavbar()}
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
            {token ? (
              <li id="menu">
                <NavLink
                  className={` ${activeLinkClasses({
                    type: "profile",
                  })} outline-none`}
                  onClick={() => toggleNavbar()}
                  to="/profile"
                >
                  Profile
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {token ? (
              <button
                onClick={logout}
                className="bg-transparent text-main rounded-md py-1 px-3 md:px-5 border-2 border-main hover:border-transparent hover:bg-main hover:text-white duration-300"
              >
                Logout
              </button>
            ) : (
              <>
                <Link
                  to="/login"
                  className="lg:hidden bg-main text-white rounded-md py-1 px-3 md:px-5 border-2 border-transparent hover:bg-[#fdb446] duration-300"
                >
                  Sign In
                </Link>
                <Link
                  to="/subscribtionPlan"
                  className="lg:hidden bg-transparent text-main rounded-md py-1 px-3 md:px-5 border-2 border-main hover:border-transparent hover:bg-main hover:text-white duration-300"
                >
                  Register
                </Link>
              </>
            )}
          </ul>
        </div>
        <ul
          id="list"
          className={`hidden lg:flex flex-col lg:flex-row items-start lg:items-center gap-5  xl:gap-10 2xl:gap-14`}
        >
          <li id="menu">
            <NavLink
              className={` ${activeLinkClasses({ type: "home" })} outline-none`}
              onClick={() => toggleNavbar()}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li id="menu">
            <NavLink
              className={` ${activeLinkClasses({
                type: "category",
              })} outline-none`}
              onClick={() => toggleNavbar()}
              to="/category"
            >
              Categories
            </NavLink>
          </li>
          {/* {localStorage.getItem("Token") ? (

          ) : (
            ""
          )} */}

          <li id="menu">
            <NavLink
              className={` ${activeLinkClasses({
                type: "products",
              })} outline-none`}
              onClick={() => toggleNavbar()}
              to="/products"
            >
              Products
            </NavLink>
          </li>
          <li id="menu">
            <NavLink
              className={` ${activeLinkClasses({
                type: "about",
              })} outline-none`}
              onClick={() => toggleNavbar()}
              to="/about"
            >
              About US
            </NavLink>
          </li>
          <li id="menu">
            <NavLink
              className={` ${activeLinkClasses({
                type: "contact",
              })} outline-none`}
              onClick={() => toggleNavbar()}
              to="/contact"
            >
              contact
            </NavLink>
          </li>
        </ul>
        <div className="hidden lg:flex items-center gap-3">
          {localStorage.getItem("Token") ? (
            <div className="relative">
              {isLoading || !userData ? (
                <div className="flex items-center gap-3">
                  <Skeleton className="size-12 rounded-full" />
                  <Skeleton className="h-7 w-32 rounded-md" />
                </div>
              ) : (
                <button
                  onClick={() => setIsOpenedProfile(!isOpenedProfile)}
                  className="flex items-center gap-3"
                >
                  <img
                    src={
                      userData?.image?.secure_url
                        ? userData.image.secure_url
                        : avatar
                    }
                    alt={userData?.userName}
                    className="size-12 rounded-full object-cover"
                  />
                  <h4>{userData?.userName}</h4>
                  {isOpenedProfile ? (
                    <AiOutlineCaretUp className="text-lg text-black" />
                  ) : (
                    <AiOutlineCaretDown className={`text-lg text-black`} />
                  )}
                </button>
              )}
              {/* {!isLoading ? (

              ) : (
                <div className="flex items-center gap-3">
                  <Skeleton className="size-12 rounded-full" />
                  <Skeleton className="h-7 w-32 rounded-md" />
                </div>
              )} */}
              <ul
                className={`${
                  isOpenedProfile ? "flex flex-col gap-1" : "hidden"
                } absolute top-full w-full text-blackColor text-center bg-white z-50 mt-4 left-1/2 border-2 rounded-md -translate-x-1/2`}
              >
                <li id="menu" className="w-full">
                  <NavLink
                    className={` ${activeLinkClasses({
                      type: "profile",
                    })} outline-none block py-2 hover:text-white hover:bg-main duration-300`}
                    onClick={() => {
                      toggleNavbar();
                      setIsOpenedProfile(!isOpenedProfile);
                    }}
                    to="/profile"
                  >
                    Profile
                  </NavLink>
                </li>
                <li id="menu" className="w-full">
                  <button
                    onClick={logout}
                    className="outline-none w-full block py-2 hover:text-white hover:bg-main duration-300"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <>
              <Link
                to="/login"
                className="bg-main text-white rounded-md py-1 px-3 md:px-5 border-2 border-transparent hover:bg-[#fdb446] duration-300"
              >
                Sign In
              </Link>
              <Link
                to="/subscribtionPlan"
                className="bg-transparent text-main rounded-md py-1 px-3 md:px-5 border-2 border-main hover:border-transparent hover:bg-main hover:text-white duration-300"
              >
                Register
              </Link>
            </>
          )}
        </div>
        <button className="lg:hidden" onClick={() => toggleNavbar()}>
          <VscThreeBars className="text-3xl" />
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
