import React, { useContext, useEffect, useState } from "react";
import Header from "../../Header/Header";
import {
  FaChevronDown,
  FaChevronUp,
  FaAngleUp,
  FaAngleDown,
} from "react-icons/fa";
import Pagination from "../../../utils/Pagination";
import { useQuery } from "react-query";
import Product from "../../Products/Product/Product";
import { UserContext } from "../../../Context/UserContext";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import FelterLoading from "./SkeletonComponents/FelterLoading";
import filterImage from "../../../assets/filrerIcon.svg";
import Skeleton from "react-loading-skeleton";
import ProductsLoading from "./SkeletonComponents/ProductsLoading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const MyProducts = () => {
  const { baseUrl, setUserData, setToken, token } = useContext(UserContext);
  const [sortedOpened, setSortedOpened] = useState(false);
  const [sortedValue, setSortedValue] = useState("New Arrivals");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsLimits, setProductsLimits] = useState(8);
  const navigate = useNavigate()
  const [isOpenedFilter, setIsOpenedFilter] = useState(false);
  const { data, isLoading, error } = useQuery("fetchMyProducts", () =>
    axios
      .get(`${baseUrl}/product/myProducts?sort=-createdAt`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      })
  );
  const lastProductIndex = currentPage * productsLimits;
  const firstProductIndex = lastProductIndex - productsLimits;

  const currentProducts = data?.data?.products
    .sort((a, b) =>
      sortedValue === "Price: ascending"
        ? a.price - b.price
        : sortedValue === "Price: descending"
        ? b.price - a.price
        : sortedValue === "New Arrivals" &&
          new Date(b.createdAt) - new Date(a.createdAt)
    )
    .slice(firstProductIndex, lastProductIndex);

  return (
    <>
      <section className="">
        <div className="">
          <div className="">

            <div className="bg-product p-2 rounded-md">
              <div className="flex items-center justify-between">
                {isLoading ? (
                  <Skeleton className="h-5 w-52" />
                ) : (
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => setIsOpenedFilter((prev) => !prev)}
                      className="lg:hidden p-2 rounded-md bg-white"
                    >
                      <img
                        className="w-7"
                        src={filterImage}
                        alt={filterImage}
                      />
                    </button>
                    <p className="text-blackColor">
                      We found{" "}
                      <span className="font-medium">
                        {data?.data?.products?.length || 0}
                      </span>{" "}
                      items for you!
                    </p>
                  </div>
                )}

                <button
                  className="p-1 relative px-2 rounded-md text-black bg-white flex items-center gap-3"
                  onClick={() => setSortedOpened((prev) => !prev)}
                >
                  <div>
                    Sort by: <span>{sortedValue}</span>
                  </div>
                  {sortedOpened ? <FaChevronUp /> : <FaChevronDown />}
                  <ul
                    className={`${
                      sortedOpened ? "block absolute" : "hidden"
                    } top-full left-0 w-full rounded-md z-30 overflow-hidden bg-white`}
                  >
                    <li>
                      <button
                        onClick={() => setSortedValue("New Arrivals")}
                        className="p-1 hover:bg-product w-full text-start"
                      >
                        New Arrivals
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setSortedValue("Price: ascending")}
                        className="p-1 hover:bg-product w-full text-start"
                      >
                        Price: ascending
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setSortedValue("Price: descending")}
                        className="p-1 hover:bg-product w-full text-start"
                      >
                        Price: descending
                      </button>
                    </li>
                  </ul>
                </button>
              </div>
            </div>

            <div
              className={`${
                isOpenedFilter ? "hidden" : "grid"
              } lg:grid grid-cols-12 gap-6 mt-6`}
            >
              {isLoading ? (
                Array(8)
                  .fill(0)
                  .map((item, index) => <ProductsLoading key={index} />)
              ) : currentProducts?.length ? (
                currentProducts.map((product) => (
                  <Product key={product._id} product={product} productType={"myProduct"} />
                ))
              ) : (
                <div className="flex items-center justify-center col-span-full h-[50vh] text-xl font-bold">
                  No products found with these filters
                </div>
              )}
              {currentProducts?.length && (
                <Pagination
                  currentPage={currentPage}
                  totalRecords={data?.data?.products?.length || 0}
                  recordsLimits={productsLimits}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyProducts;
