import React, { useContext, useState } from "react";
import seeMore from "../../../../../assets/see-more.svg";
import deleteProduct from "../../../../../assets/icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../Context/UserContext";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { useQueryClientContext } from "../../../../../Context/QueryClientContext";
import { toast } from "react-toastify";
function ProductsData({
  product,
  length,
  indexOfProduct,
  setIndex,
  setProduct,
  isNotCompleted,
}) {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [blockIsLoading, setBlockIsLoading] = useState(false);
  const queryClient = useQueryClientContext();
  const navigate = useNavigate();
  const blockProduct = async (id) => {
    setBlockIsLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${id}/block`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        console.log(err);

        setBlockIsLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");

        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    setBlockIsLoading(false);
    if (data?.data?.message == "Done") {
      setProduct(data.data.product);
      setIndex(indexOfProduct);
      queryClient.invalidateQueries("fetchInitialProducts");
      queryClient.invalidateQueries("getAllProductsNames");
    }
  };

  return (
    <tr className={`flex items-center w-full`}>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfProduct  &&  !isNotCompleted? "" : " border-b-2"
          }`}
        >
          <span
            title={product.enName}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {product.enName}
          </span>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfProduct  &&  !isNotCompleted? "" : " border-b-2"
          }`}
        >
          <span
            title={product.stock}
            className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
          >
            {product.stock}
          </span>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfProduct  &&  !isNotCompleted? "" : " border-b-2"
          }`}
        >
          <span
            className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  ${
              (!product.accepted && !product.refused) ||
              product.blocked ||
              product.deleted ||
              product.categoryDeleted ||
              product.subcategoryDeleted ||
              product.brandDeleted ||
              product.traderDeleted
                ? "text-red-400"
                : ""
            }`}
          >
            {!product.accepted && !product.refused
              ? "Not accepted"
              : product.blocked
              ? "Blocked"
              : product.deleted
              ? "Stopped"
              : product.categoryDeleted
              ? "Not active"
              : product.subcategoryDeleted
              ? "Not active"
              : product.brandDeleted
              ? "Not active"
              : product.traderDeleted
              ? "Not active"
              : "Active"}
          </span>
        </div>
      </td>
      <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <span
          className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
            length - 1 == indexOfProduct &&  !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          {blockIsLoading ? (
            <button
              disabled
              className={`py-2 px-3 rounded-full ${
                product.blocked ? "bg-main" : "bg-red-500"
              }  text-white`}
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => blockProduct(product?._id)}
              className={`py-2 px-3 rounded-full ${
                product.blocked ? "bg-main" : "bg-red-500"
              }  text-white`}
            >
              {product.blocked ? "Active" : "Block"}
            </button>
          )}
          <Link to={`/dashboard/productDetails/${product._id}`}>
            <img src={seeMore} alt="seeMore" />
          </Link>
        </span>
      </td>
    </tr>
  );
}

export default ProductsData;
