import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { RiInstagramLine } from "react-icons/ri";
import mainLogo from "../../assets/mainLogos.jpg.png";

function Footer() {
  return (
    <footer className="bg-headerColor">
      <div className="px-5 container lg:w-[80%] max-w-screen-xl mx-auto py-10">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 my-3 md:col-span-8 lg:col-span-4 xl:col-span-4 ">
            <h3 className="text-2xl font-bold uppercase">
              <img
                className=" object-cover h-12"
                src={mainLogo}
                alt="mainLogo"
              />
            </h3>
            <p className="my-5 font-light">
              Mnawlah is an innovative platform that connects buyers and sellers
              in the material handling and storage equipment sector in Egypt,
              providing a seamless online marketplace for
              efficient transactions.
            </p>
            <div className="flex items-center gap-3">
              <button
                onClick={() => {
                  window.open("https://www.facebook.com/Mnawlah", "_blank"); // "_blank" opens the link in a new tab
                }}
                className="p-2 rounded-full border-2 border-blackColor hover:text-white  outline-none hover:bg-blackColor duration-300"
              >
                <FaFacebookSquare />
              </button>
              <button
                onClick={() => {
                  window.open("https://x.com/mnawlahmarket", "_blank"); // "_blank" opens the link in a new tab
                }}
                className="p-2 rounded-full border-2 border-blackColor hover:text-white  outline-none hover:bg-blackColor duration-300"
              >
                <FaTwitter />
              </button>
              <button
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/mnawlah/about/?viewAsMember=true",
                    "_blank"
                  ); // "_blank" opens the link in a new tab
                }}
                className="p-2 rounded-full border-2 border-blackColor hover:text-white  outline-none hover:bg-blackColor duration-300"
              >
                <FaLinkedinIn />
              </button>
              <button
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/mnawlahmarket/",
                    "_blank"
                  ); // "_blank" opens the link in a new tab
                }}
                className="p-2 rounded-full border-2 border-blackColor hover:text-white  outline-none hover:bg-blackColor duration-300"
              >
                <RiInstagramLine />
              </button>
            </div>
          </div>
          <div className="col-span-12 my-3 lg:pr-10 md:col-span-4 lg:col-span-3 xl:col-span-2">
            <h5 className="text-xl border-b-2 w-fit border-blackColor font-bold">
              Quick Link
            </h5>
            <ul className="text-lg flex flex-col gap-2 mt-5">
              <li>
                <Link
                  to="/about"
                  className="hover:text-main hover:underline outline-none duration-100"
                >
                  About
                </Link>
              </li>

              <li>
                <Link
                  to="/contact"
                  className="hover:text-main hover:underline outline-none duration-100"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to={"/category"}
                  className="hover:text-main hover:underline outline-none duration-100"
                >
                  Categories
                </Link>
              </li>
              <li>
                <Link
                  to={"/products"}
                  className="hover:text-main hover:underline outline-none duration-100"
                >
                  Products
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-12 my-3 lg:pr-10 md:col-span-7  lg:col-span-5 xl:col-span-4 ">
            <h5 className="text-xl border-b-2 w-fit border-blackColor font-bold">
              Contact Info
            </h5>
            <ul className="text-lg flex flex-col gap-2 mt-5">
              <li>
                <span className="font-bold text-main">Address:</span>{" "}
                <span> 6 October city, Giza</span>
              </li>
              <li>
                <span className="font-bold text-main">Email:</span>{" "}
                <span> info@mnawlah.com</span>
              </li>
              <li>
                <span className="font-bold text-main">Phone:</span>{" "}
                <span>01118182323</span>
              </li>
              <li className="flex gap-1">
                <span className="font-bold  block text-main">Work Time:</span>{" "}
                <span className=" block ">
                  <span className="lg:block">Sun-Fri,</span> 09.00am-17.00pm
                </span>
              </li>
            </ul>
          </div>
          <div className="col-span-12 my-3 md:col-span-5  lg:col-span-12 xl:col-span-2">
            <form
              action=""
              className="block lg:flex justify-start lg:justify-center items-center gap-5 xl:block"
            >
              <label className="text-xl font-bold uppercase">
                Join Newsletter
              </label>
              <div className="flex mt-4">
                <input
                  type="email"
                  className="outline-none bg-white rounded-l-full teext-lg py-2 pr-6 pl-4"
                  placeholder="Your email"
                />
                <button className="rounded-full  outline-none bg-main text-white py-2 px-4 text-lg -translate-x-5 font-semibold">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
