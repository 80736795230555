import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../Context/UserContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import TraderDetailsLoading from "./TraderDetailsLoading/TraderDetailsLoading";
import { toast } from "react-toastify";

function TraderDetails() {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const navigate = useNavigate();
  const [blockIsLoading, setBlockIsLoading] = useState(false);

  const acceptAccount = async (id) => {
    console.log(id);

    setAcceptLoading(true);
    const { data } = await axios
      .patch(
        `${baseUrl}/user/${id}/accept`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setAcceptLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data.message == "Done") {
      setAcceptLoading(false);
      console.log(data);
      navigate("/dashboard/traderRequests");
    }
  };
  const rejectAccount = async (id) => {
    console.log(id);

    setRejectLoading(true);
    const { data } = await axios
      .patch(
        `${baseUrl}/user/${id}/refused`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setRejectLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data.message == "Done") {
      setRejectLoading(false);
      console.log(data);
      navigate("/dashboard/traderRequests");
    }
  };

  const getUser = async () => {
    setIsLoading(true);
    const data = await axios.get(`${baseUrl}/user/${id}`).catch((err) => {
      if (!err.response) {
        // If there's no response (network error), handle it
        console.log("Network Error: Please check your internet connection.");

        // You can also log this error or perform other actions like redirecting to a "no internet" page
      }
      if (err.response?.data?.errMass == "In-valid user") {
        const customPath = pathname.includes("userDetails")
          ? "allusers"
          : "traderRequests";
        navigate(`/dashboard/${customPath}`);
      }
    });
    setUser(data?.data?.user);
    setIsLoading(false);
  };

  const blockUser = async () => {
    console.log(id);
    setBlockIsLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/user/${id}/blockUser`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        setBlockIsLoading(false);
        console.log(err);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });

    setBlockIsLoading(false);
    if (data?.data?.message == "Done") {
      setUser(data.data.user);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <h2 className="text-2xl font-bold p-5 pb-0">
        {" "}
        {pathname.includes("userDetails") ? "User deatails" : "Trader request"}
      </h2>
      {isLoading || !user ? (
        <TraderDetailsLoading />
      ) : (
        <div className="grid grid-cols-4 gap-4 p-5 ">
          <div className="col-span-full lg:col-span-2">
            <label className="font-medium">User name</label>
            <div className="text-textbody p-2 w-full bg-white border-2 border-gray-300">
              {user.userName}
            </div>
          </div>
          <div className="col-span-full lg:col-span-2">
            <label className="font-medium">Email</label>
            <div className="text-textbody p-2 w-full bg-white border-2 border-gray-300">
              {user.email}
            </div>
          </div>
          {user.address ? (
            <div className="col-span-full lg:col-span-2">
              <label className="font-medium">Address</label>
              <div className="text-textbody p-2 w-full bg-white border-2 border-gray-300">
                {user.address}
              </div>
            </div>
          ) : (
            <div className="col-span-full lg:col-span-2 py-2"></div>
          )}
          {user.phone ? (
            <div className="col-span-full lg:col-span-2">
              <label className="font-medium">Phone</label>
              <div className="text-textbody p-2 w-full bg-white border-2 border-gray-300">
                {user.phone}
              </div>
            </div>
          ) : (
            <div className="col-span-full lg:col-span-2 py-2"></div>
          )}

          <div className="col-span-full lg:col-span-2">
            <label className="font-medium">Status</label>
            <div
              className={` p-2 w-full bg-white border-2 border-gray-300 ${
                !user.accepted ||
                user.status == "blocked" ||
                !user.confirmEmail ||
                user.deleted
                  ? "text-red-400"
                  : "text-textbody"
              }`}
            >
              {!user.accepted
                ? "Not accepted"
                : user.status == "blocked"
                ? "Blocked"
                : !user.confirmEmail
                ? "Not confirmed"
                : user.deleted
                ? "Stopped"
                : "Active"}
            </div>
          </div>

          <div className="grid grid-cols-4 col-span-full gap-4">
            {user.image?.secure_url ? (
              <div className="col-span-full lg:col-span-4">
                <div className="w-1/2">
                  <label className="font-medium">Image</label>
                  <img
                    src={user.image.secure_url}
                    className="size-[50vh] object-cover"
                    alt={user.userName}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {user.role == "Business" && user.commercialRegister?.secure_url ? (
              <div className="col-span-full lg:col-span-2">
                <label className="font-medium">Commercial register</label>
                <img
                  src={user.commercialRegister.secure_url}
                  className="size-[50vh] object-cover"
                  alt={user.userName}
                />
              </div>
            ) : (
              ""
            )}
            {user.role == "Individual" && user.personalId?.secure_url ? (
              <div className="col-span-full lg:col-span-2">
                <label className="font-medium">Personal Id</label>
                <img
                  src={user.personalId.secure_url}
                  className="size-[50vh] object-cover"
                  alt={user.userName}
                />
              </div>
            ) : (
              ""
            )}
            {user.role == "Business" && user.taxCard?.secure_url ? (
              <div className="col-span-full lg:col-span-2">
                <label className="font-medium">Tax Card</label>
                <img
                  src={user.taxCard.secure_url}
                  className="size-[50vh] object-cover"
                  alt={user.userName}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-full grid grid-cols-4 gap-4">
            <div
              className={` ${
                pathname.includes("userDetails") ? "" : "hidden"
              } col-span-full lg:col-span-2`}
            >
              {blockIsLoading ? (
                <button
                  disabled
                  className={`py-2 px-4 outline-none rounded-md w-full ${
                    user.status == "blocked" ? "bg-main" : "bg-red-500"
                  }`}
                >
                  <PulseLoader
                    color="#fff"
                    cssOverride={{}}
                    loading
                    margin={2}
                    size={7}
                    speedMultiplier={1}
                  />
                </button>
              ) : (
                <button
                  onClick={() => blockUser()}
                  className={`py-2 px-4 outline-none rounded-md w-full text-white ${
                    user.status == "blocked" ? "bg-main" : "bg-red-500"
                  } `}
                >
                  {user.status == "blocked" ? "Active user" : "Block user"}
                </button>
              )}
            </div>
            <div
              className={` ${
                pathname.includes("userDetails") ? "hidden" : ""
              } col-span-full lg:col-span-2`}
            >
              {acceptLoading ? (
                <button
                  disabled
                  className="py-2 px-4 outline-none rounded-md w-full bg-main"
                >
                  <PulseLoader
                    color="#fff"
                    cssOverride={{}}
                    loading
                    margin={2}
                    size={7}
                    speedMultiplier={1}
                  />
                </button>
              ) : (
                <button
                  onClick={() => acceptAccount(user._id)}
                  className="py-2 px-4 outline-none rounded-md text-white w-full bg-main"
                >
                  Accept
                </button>
              )}
            </div>
            <div
              className={` ${
                pathname.includes("userDetails") ? "hidden" : ""
              } col-span-full lg:col-span-2`}
            >
              {rejectLoading ? (
                <button
                  disabled
                  className="py-2 px-4 outline-none rounded-md w-full bg-profileColor"
                >
                  <PulseLoader
                    color="#000"
                    cssOverride={{}}
                    loading
                    margin={2}
                    size={7}
                    speedMultiplier={1}
                  />
                </button>
              ) : (
                <button
                  onClick={() => rejectAccount(user._id)}
                  className="py-2 px-4 outline-none rounded-md w-full bg-profileColor"
                >
                  Reject
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TraderDetails;
