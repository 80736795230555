
function Pagination({ totalRecords, currentPage, recordsLimits, setCurrentPage }) {

    const totalPages = Math.ceil(totalRecords / recordsLimits);
    const getPageRange = () => {
        let start = Math.max(1, currentPage - 1);
        let end = Math.min(start + 2, totalPages);

        if (currentPage === 1) {
            start = 1;
            end = Math.min(3, totalPages);
        } else if (currentPage === totalPages) {
            start = Math.max(totalPages - 2, 1);
            end = totalPages;
        }

        let pages = [];
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }
        return pages;
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(Math.max(1, currentPage - 1));
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(Math.min(totalPages, currentPage + 1));
        }
    };

    return (
        <div className='flex justify-center col-span-12 text-center items-center gap-3'>
     
            <button
                onClick={handlePrev}
                className='py-2 px-3 rounded-md bg-product hover:bg-main duration-300'
                disabled={currentPage <= 1}
            >
                Prev
            </button>

            <div
                className={`flex gap-3`}
            >
                {getPageRange().map((page) => (
                    <button
                        key={page}
                        className={`py-2 px-3 rounded-md ${page === currentPage ? 'bg-main' : 'bg-product'} hover:bg-main duration-300`}
                        onClick={() => setCurrentPage(page)}
                    >
                        {page}
                    </button>
                ))}
            </div>

            <button
                onClick={handleNext}
                className='py-2 px-3 rounded-md bg-product hover:bg-main duration-300'
                disabled={currentPage >= totalPages}
            >
                Next
            </button>
        </div>
    );
}

export default Pagination;
