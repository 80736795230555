import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
function ProductsDataLoading() {
  const array = Array(5).fill(0);

  return (
    <>
      {array.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-4 items-center justify-between gap-10 ${
            array.length - 1 == index ? "border-b-2" : ""
          }`}
        >
          <tr
            className={`h-20 col-span-1   flex items-center justify-center ${
              array.length - 1 == index ? "" : " border-b-2"
            }`}
          >
            <td>
              <Skeleton className="h-7 rounded-md w-32" />
            </td>
          </tr>
          <tr
            className={`h-20 col-span-1   flex items-center justify-center ${
              array.length - 1 == index ? "" : " border-b-2"
            }`}
          >
            {" "}
            <td>
              <Skeleton className="h-7 rounded-md w-32" />
            </td>
          </tr>
          <tr
            className={`h-20 col-span-1   flex items-center justify-center ${
              array.length - 1 == index ? "" : " border-b-2"
            }`}
          >
            {" "}
            <td>
              <Skeleton className="h-7 rounded-md w-32" />
            </td>
          </tr>
          <tr
            className={`h-20 col-span-1   flex items-center justify-center ${
              array.length - 1 == index ? "" : " border-b-2"
            }`}
          >
            {" "}
            <td>
              <Skeleton className="h-8 rounded-md w-32" />
            </td>
          </tr>
        </div>
      ))}
    </>
  );
}

export default ProductsDataLoading;
