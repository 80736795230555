import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../../Context/UserContext";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import seeMore from "../../../../../../assets/see-more.svg";
import deleteProduct from "../../../../../../assets/icon.svg";
import { toast } from "react-toastify";
function TraderData({
  user,
  length,
  indexOfUser,
  setUserDeleted,
  setIsDone,
  setIndex,
  setUser,
  isNotCompleted,
}) {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [blockIsLoading, setBlockIsLoading] = useState(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const blockUser = async (id) => {
    console.log(id);
    setBlockIsLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/user/${id}/blockUser`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        setBlockIsLoading(false);
        console.log(err);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });

    setBlockIsLoading(false);
    if (data?.data?.message == "Done") {
      console.log(data.data);

      setUser(data.data.user);
      setIndex(indexOfUser);
    }
  };

  const acceptAccount = async (id) => {
    setAcceptLoading(true);
    const { data } = await axios
      .patch(
        `${baseUrl}/user/${id}/accept`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setAcceptLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data.message == "Done") {
      setUserDeleted(user);
      setIsDone(true);
      setAcceptLoading(false);
    }
  };
  const rejectAccount = async (id) => {
    console.log(id);

    setRejectLoading(true);
    const { data } = await axios
      .patch(
        `${baseUrl}/user/${id}/refused`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setRejectLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data.message == "Done") {
      setUserDeleted(user);
      setIsDone(true);
      setRejectLoading(false);
    }
  };

  return (
    <tr className={`flex items-center w-full`}>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfUser && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={user.userName}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {user.userName}
          </span>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfUser && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={user.role}
            className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
          >
            {user.role}
          </span>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfUser && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={user.role}
            className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  ${
              !user.accepted ||
              user.status == "blocked" ||
              !user.confirmEmail ||
              user.deleted
                ? "text-red-400"
                : "text-textbody"
            } `}
          >
            {!user.accepted
              ? "Not accepted"
              : user.status == "blocked"
              ? "Blocked"
              : !user.confirmEmail
              ? "Not confirmed"
              : user.deleted
              ? "Stopped"
              : "Active"}
          </span>
        </div>
      </td>
      {pathname == "/dashboard/traderRequests" ? (
        <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
          <span
            className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
              length - 1 == indexOfUser && !isNotCompleted ? "" : " border-b-2"
            }`}
          >
            {acceptLoading ? (
              <button
                disabled
                className="py-2 px-3 rounded-full bg-main text-white"
              >
                <PulseLoader
                  color="#fff"
                  cssOverride={{}}
                  loading
                  margin={2}
                  size={7}
                  speedMultiplier={1}
                />
              </button>
            ) : (
              <button
                onClick={() => acceptAccount(user?._id)}
                className="py-2 px-3 rounded-full bg-main text-white"
              >
                Accept
              </button>
            )}

            <Link to={`/dashboard/traderDetails/${user._id}`}>
              <img src={seeMore} alt="seeMore" />
            </Link>

            {rejectLoading ? (
              <button disabled className="P-2 outline-none">
                <PulseLoader
                  color="#000"
                  cssOverride={{}}
                  loading
                  margin={2}
                  size={7}
                  speedMultiplier={1}
                />
              </button>
            ) : (
              <button
                onClick={() => rejectAccount(user._id)}
                className="P-2 outline-none"
              >
                <img src={deleteProduct} alt="deleteProduct" />
              </button>
            )}
          </span>
        </td>
      ) : (
        <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
          <span
            className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
              length - 1 == indexOfUser && !isNotCompleted ? "" : " border-b-2"
            }`}
          >
            {blockIsLoading ? (
              <button
                disabled
                className={`py-2 px-3 rounded-full ${
                  user.status == "blocked" ? "bg-main" : "bg-red-500"
                } text-white`}
              >
                <PulseLoader
                  color="#fff"
                  cssOverride={{}}
                  loading
                  margin={2}
                  size={7}
                  speedMultiplier={1}
                />
              </button>
            ) : (
              <button
                onClick={() => blockUser(user?._id)}
                className={`py-2 px-3 rounded-full ${
                  user.status == "blocked" ? "bg-main" : "bg-red-500"
                } outline-none text-white`}
              >
                {user.status == "blocked" ? "Active" : "Block"}
              </button>
            )}
            <Link to={`/dashboard/userDetails/${user._id}`}>
              <img src={seeMore} alt="seeMore" />
            </Link>
          </span>
        </td>
      )}
    </tr>

    // <div className="p-10 bg-white rounded-lg">
    //   <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-10">
    //     <p className="text-textbody">
    //       <span className="font-medium text-black">Name:</span> {user.userName}
    //     </p>
    //     <p className="text-textbody">
    //       <span className="font-medium text-black">E_mail:</span> {user.email}
    //     </p>
    //   </div>
    //   <Link
    //     to={`/dashboard/traderDetails/${user._id}`}
    //     className="text-textbody my-2 flex items-center"
    //   >
    //     view details <FaAngleRight />
    //   </Link>
    //   <div className="flex items-center gap-4">
    //     {acceptLoading ? (
    //       <button
    //         disabled
    //         className="py-2 px-4 outline-none rounded-md bg-main"
    //       >
    //         <PulseLoader
    //           color="#fff"
    //           cssOverride={{}}
    //           loading
    //           margin={2}
    //           size={7}
    //           speedMultiplier={1}
    //         />
    //       </button>
    //     ) : (
    //       <button
    //         onClick={() => acceptAccount(user?._id)}
    //         className="py-2 px-4 outline-none rounded-md bg-main"
    //       >
    //         Accept
    //       </button>
    //     )}
    //     {rejectLoading ? (
    //       <button
    //         disabled
    //         className="py-2 px-4 outline-none rounded-md bg-profileColor"
    //       >
    //         <PulseLoader
    //           color="#000"
    //           cssOverride={{}}
    //           loading
    //           margin={2}
    //           size={7}
    //           speedMultiplier={1}
    //         />
    //       </button>
    //     ) : (
    //       <button
    //         onClick={() => rejectAccount(user._id)}
    //         className="py-2 px-4 outline-none rounded-md bg-profileColor"
    //       >
    //         Reject
    //       </button>
    //     )}
    //   </div>
    // </div>
  );
}

export default TraderData;
