import React, { useContext, useEffect, useState } from "react";
import Header from "../Header/Header";
import {
  FaChevronDown,
  FaChevronUp,
  FaAngleUp,
  FaAngleDown,
} from "react-icons/fa";
import Pagination from "../../utils/Pagination";
import { useQueries, useQuery } from "react-query";
import Product from "./Product/Product";
import { UserContext } from "../../Context/UserContext";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import FelterLoading from "./SkeletonComponents/FelterLoading";
import filterImage from "../../assets/filrerIcon.svg";
import Skeleton from "react-loading-skeleton";
import ProductsLoading from "./SkeletonComponents/ProductsLoading";
import { ProductContext } from "../../Context/ProductContext";
import imageHeader from "../../assets/header/product.jpg";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { CategoryContext } from "../../Context/CategoryContext";
import { useQueryClientContext } from "../../Context/QueryClientContext";
import { toast } from "react-toastify";
import { BrandContext } from "../../Context/BrandContext";
// Filter Section for Categories and Brands
const FilterSection = ({ title, items, selectedItem, handleChange }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className="py-5 px-2 border-2 rounded-lg">
      <h2 className="font-bold px-5 text-2xl">{title}</h2>
      {!items?.length ? (
        <div className="py-2">
          <p>There is no {title}</p>
        </div>
      ) : (
        <div className="col-span-full lg:col-span-4">
          <label
            onClick={() => setIsOpened(!isOpened)}
            className={`p-2 mt-5 pr-[15%] block text-lg select-none w-full border-2 outline-none focus:border-main ${
              isOpened ? "border-main" : ""
            }  duration-300 relative 
            text-start `}
          >
            <p
              title={selectedItem?.enName}
              className="overflow-hidden text-ellipsis whitespace-nowrap max-w-40"
            >
              {selectedItem?._id ? selectedItem.enName : "all"}
            </p>
            {/* {!brandValue?.brandId ? "Brand" : brandValue.name} */}
            {isOpened ? (
              <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
            ) : (
              <AiOutlineCaretDown
                className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
              />
            )}
            <div
              className={`${
                isOpened ? "block" : "hidden"
              } absolute max-h-[20vh] p-2  overflow-y-auto scrollbar-thin scrollbar-webkit  flex flex-col gap-1 border-2 bg-white ${
                title == "Brands"
                  ? "z-10"
                  : title == "Subcategory"
                  ? "z-20"
                  : "z-30"
              } left-0 top-full right-0 border-main`}
            >
              <div
                onClick={() => {
                  handleChange(null, title);
                }}
                className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
              >
                all
              </div>

              {items.map((item) => (
                <div
                  key={item.enName}
                  onClick={() => {
                    handleChange(item, title);
                  }}
                  className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                >
                  <p
                    title={item.enName}
                    className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full"
                  >
                    {item.enName}
                  </p>
                </div>
              ))}
            </div>
          </label>
        </div>
      )}
    </div>
  );
};

const Products = () => {
  const { baseUrl } = useContext(UserContext);
  const { filteredProducts, setSearchProduct, change, setChange } =
    useContext(ProductContext);
  const {
    selectedFilter,
    setSelectedFilter,
    selectedCategory,
    setSelectedCategory,
    selectedSubcategory,
    setSelectedSubcategory,
  } = useContext(CategoryContext);
  const { selectedBrand, setSelectedBrand } = useContext(BrandContext);
  const [status, setStatus] = useState(null);
  const [sortedOpened, setSortedOpened] = useState(false);
  const [sortedValue, setSortedValue] = useState("New Arrivals");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsLimits, setProductsLimits] = useState(6);
  const [products, setProducts] = useState([]);
  const [isOpenedFilter, setIsOpenedFilter] = useState(false);
  const [isOpenedStatus, setIsOpenedStatus] = useState(false);
  const queryClient = useQueryClientContext();
  const {
    data: initialProductsData,
    isLoading,
    error,
  } = useQuery("fetchInitialProducts", () =>
    axios.get(`${baseUrl}/product?sort=-createdAt`).catch((err) => {
      if (!err.response) {
        toast.error("Check the internet and reload the site");
      }
    })
  );

  const getFelterProducts = async () => {
    const filters = {
      brandId: selectedBrand?._id,
      categoryId: selectedCategory?._id,
      subcategoryId: selectedSubcategory?._id,
      status: status,
    };

    !selectedBrand && delete filters.brandId;
    !selectedCategory && delete filters.categoryId;
    !selectedSubcategory && delete filters.subcategoryId;
    !status && delete filters.status;

    if (
      selectedFilter?.length ||
      selectedBrand ||
      selectedCategory ||
      selectedSubcategory ||
      status
    ) {
      const data = await axios
        .post(`${baseUrl}/product/felterProducts?sort=-createdAt`, filters)
        .catch((err) => {
          setProducts([]);
          if (!err.response) {
            toast.error("Check the internet and reload the site");
          }
        });

      if (data?.data?.products?.length) {
        setProducts(data?.data?.products);
      } else {
        setProducts([]);
      }
    } else {
      if (filteredProducts?.length) {
        setProducts(filteredProducts);
      } else {
        setProducts(initialProductsData?.data?.products);
      }
    }
  };

  useEffect(() => {
    getFelterProducts();
    if (change?.type) {
      handleChange(change.item, change.type);
      setChange(null);
    }
  }, [
    selectedBrand,
    selectedCategory,
    selectedSubcategory,
    status,
    initialProductsData,
  ]);

  const leaveComponent = () => {
    setSearchProduct("");
    handleClearAll();
  };

  useEffect(() => {
    return () => {
      leaveComponent();
    };
  }, []);

  const handleRemoveElement = (data) => {
    const { type, enName } = data;
    setSelectedFilter((prev) => prev.filter((item) => item.enName !== enName));
    if (type == "Brands") {
      setSelectedBrand(null);
    } else if (type == "Subcategory") {
      setSelectedSubcategory(null);
    } else if (type == "Categories") {
      setSelectedSubcategory(null);
      setSelectedCategory(null);
      setSelectedFilter((prev) =>
        prev.filter((item) => item.type !== "Subcategory")
      );
    }

    // type == "category"
    //   ? setSelectedCategory((prev) => prev.filter((item) => item !== id))
    //   : setSelectedBrand((prev) => prev.filter((item) => item !== id));
  };

  const handleChange = (item, type) => {
    if (type == "Categories") {
      setSelectedSubcategory(null);
      setSelectedCategory(item);
    } else if (type == "Subcategory") {
      setSelectedSubcategory(item);
    } else if (type == "Brands") {
      setSelectedBrand(item);
    } else if (type == "status") {
      setStatus(item);
    }
    const finalArray = [];
    for (const i of selectedFilter) {
      if (i.type != type) {
        finalArray.push(i);
      }
    }
    if (item) {
      if (type == "status") {
        finalArray.push({ enName: item, type });
      } else {
        finalArray.push({ enName: item.enName, type });
      }
    }
    setSelectedFilter([...finalArray]);
  };

  // const handleCheckboxCategoryChange = (item) => {
  //   console.log(item);

  //   // const keywords = { id: item._id, name: item.enName, type: "category" };

  //   // setSelectedFilter((prev) =>
  //   //   checked ? [...prev, keywords] : prev.filter((item) => item.id !== value)
  //   // );
  // };

  // const handleCheckboxSubcategoryChange = (item) => {
  //   console.log(item);

  //   // const keywords = { id: item._id, name: item.enName, type: "category" };

  //   // setSelectedFilter((prev) =>
  //   //   checked ? [...prev, keywords] : prev.filter((item) => item.id !== value)
  //   // );

  //   setSelectedSubcategory(item);
  // };

  // const handleCheckboxBrandChange = (item) => {
  //   // const { value, id, checked } = e.target;

  //   // const keywords = { id: value, name: id, type: "brand" };

  //   // setSelectedFilter((prev) =>
  //   //   checked ? [...prev, keywords] : prev.filter((item) => item.id !== value)
  //   // );

  //   console.log(item);
  // };

  const handleClearAll = () => {
    setSelectedFilter([]);
    setSelectedBrand(null);
    setStatus(null);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  };

  const getCategories = () =>
    axios.get(`${baseUrl}/category`).catch((err) => {
      if (!err.response) {
        // If there's no response (network error), handle it
        console.log("Network Error: Please check your internet connection.");
        // You can also log this error or perform other actions like redirecting to a "no internet" page
      }
    });
  const categories = useQuery("getCategories", getCategories);

  const getBrands = () =>
    axios.get(`${baseUrl}/brand`).catch((err) => {
      if (!err.response) {
        // If there's no response (network error), handle it
        console.log("Network Error: Please check your internet connection.");
        // You can also log this error or perform other actions like redirecting to a "no internet" page
      }
    });

  const brands = useQuery("getBrands", getBrands);

  const lastProductIndex = currentPage * productsLimits;
  const firstProductIndex = lastProductIndex - productsLimits;

  const currentProducts =
    products?.length &&
    products
      .sort((a, b) =>
        sortedValue === "Price: ascending"
          ? a.price - b.price
          : sortedValue === "Price: descending"
          ? b.price - a.price
          : sortedValue === "New Arrivals" &&
            new Date(b.createdAt) - new Date(a.createdAt)
      )
      .slice(firstProductIndex, lastProductIndex);

  return (
    <>
      <Header header="Products" nameComponent="Products" image={imageHeader} />
      <section className="py-8">
        <div className="container lg:w-[90%] px-5 mx-auto">
          <div className="grid grid-cols-4 gap-5">
            <div
              className={`hidden lg:flex flex-col gap-10 col-span-4 lg:col-span-1 text-black`}
            >
              {categories.isLoading ? (
                <FelterLoading header={"Categories"} />
              ) : (
                <FilterSection
                  title="Categories"
                  items={categories.data?.data.categories || []}
                  selectedItem={selectedCategory}
                  handleChange={handleChange}
                />
              )}

              {selectedCategory ? (
                <FilterSection
                  title="Subcategory"
                  items={selectedCategory.subcategory || []}
                  selectedItem={selectedSubcategory}
                  handleChange={handleChange}
                />
              ) : (
                ""
              )}

              {brands.isLoading ? (
                <FelterLoading header={"Brands"} />
              ) : (
                <FilterSection
                  title="Brands"
                  items={brands.data?.data.brands || []}
                  selectedItem={selectedBrand}
                  handleChange={handleChange}
                />
              )}

              <div className="py-5 px-2 border-2 rounded-lg">
                <h2 className="font-bold px-5 text-2xl">Status</h2>

                <div className="col-span-full lg:col-span-4">
                  <label
                    onClick={() => setIsOpenedStatus((prev) => !prev)}
                    className={`p-2 mt-5 pr-[15%] block text-lg select-none w-full border-2 outline-none focus:border-main ${
                      isOpenedStatus ? "border-main" : ""
                    }  duration-300 relative 
            text-start `}
                  >
                    <p
                      title={status}
                      className="overflow-hidden text-ellipsis whitespace-nowrap max-w-40"
                    >
                      {status || "all"}
                    </p>
                    {/* {!brandValue?.brandId ? "Brand" : brandValue.name} */}
                    {isOpenedStatus ? (
                      <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
                    ) : (
                      <AiOutlineCaretDown
                        className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
                      />
                    )}
                    <div
                      className={`${
                        isOpenedStatus ? "block" : "hidden"
                      } absolute max-h-[20vh] p-2  overflow-y-auto scrollbar-thin scrollbar-webkit  flex flex-col gap-1 border-2 bg-white  left-0 top-full right-0 border-main`}
                    >
                      <div
                        onClick={() => {
                          handleChange(null, "status");
                        }}
                        className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                      >
                        all
                      </div>

                      <div
                        onClick={() => {
                          handleChange("New", "status");
                        }}
                        className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                      >
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
                          New
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          handleChange("Used", "status");
                        }}
                        className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                      >
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
                          Used
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-span-4 lg:col-span-3">
              <div className="bg-product p-2 rounded-md">
                <div className="flex items-center justify-between">
                  {isLoading ? (
                    <Skeleton className="h-5 w-52" />
                  ) : (
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => setIsOpenedFilter((prev) => !prev)}
                        className="lg:hidden p-2 rounded-md bg-white"
                      >
                        <img
                          className="w-7"
                          src={filterImage}
                          alt={filterImage}
                        />
                      </button>
                      <p className="text-blackColor">
                        We found{" "}
                        <span className="font-medium">
                          {currentProducts?.length
                            ? products?.length ||
                              initialProductsData?.data?.products?.length
                            : 0}
                        </span>{" "}
                        items for you!
                      </p>
                    </div>
                  )}

                  <button
                    className="p-1 relative px-2 rounded-md text-black bg-white flex items-center gap-3"
                    onClick={() => setSortedOpened((prev) => !prev)}
                  >
                    <div>
                      Sort by: <span>{sortedValue}</span>
                    </div>
                    {sortedOpened ? <FaChevronUp /> : <FaChevronDown />}
                    <ul
                      className={`${
                        sortedOpened ? "block absolute" : "hidden"
                      } top-full left-0 w-full rounded-md z-30 overflow-hidden bg-white`}
                    >
                      <li>
                        <button
                          onClick={() => setSortedValue("New Arrivals")}
                          className="p-1 hover:bg-product w-full text-start"
                        >
                          New Arrivals
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => setSortedValue("Price: ascending")}
                          className="p-1 hover:bg-product w-full text-start"
                        >
                          Price: ascending
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => setSortedValue("Price: descending")}
                          className="p-1 hover:bg-product w-full text-start"
                        >
                          Price: descending
                        </button>
                      </li>
                    </ul>
                  </button>
                </div>
              </div>

              {selectedFilter?.length ? (
                <div className=" p-2 mt-3 rounded-md">
                  <div className="flex flex-wrap items-center gap-3">
                    <p className="text-blackColor">
                      Found{" "}
                      <span className="font-medium">
                        {currentProducts?.length
                          ? products?.length ||
                            initialProductsData?.data?.products?.length
                          : 0}
                      </span>{" "}
                      items
                    </p>
                    {selectedFilter.map((btn) => (
                      <button
                        onClick={() => handleRemoveElement(btn)}
                        className="text-white bg-main py-[6px] px-3 rounded-md flex flex-wrap items-center font-medium"
                      >
                        <div className="w-[150px] flex items-center gap-1">
                          {" "}
                          <IoClose className="max-w-[20px]" />
                          <p className="max-w-[130px] overflow-hidden text-ellipsis whitespace-nowrap"></p>
                          {btn.enName}
                        </div>
                      </button>
                    ))}
                    <button
                      onClick={handleClearAll}
                      className=" py-[6px] px-1 text-lg underline"
                    >
                      Clear all
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className={`${
                  isOpenedFilter ? "flex" : "hidden"
                }  lg:hidden flex-col gap-8 mt-5 col-span-4 lg:col-span-1 text-black`}
              >
                {categories.isLoading ? (
                  <FelterLoading header={"Categories"} />
                ) : (
                  <FilterSection
                    title="Categories"
                    items={categories.data?.data.categories || []}
                    selectedItem={selectedCategory}
                    handleChange={handleChange}
                  />
                )}

                {selectedCategory ? (
                  <FilterSection
                    title="Subcategory"
                    items={selectedCategory.subcategory || []}
                    selectedItem={selectedSubcategory}
                    handleChange={handleChange}
                  />
                ) : (
                  ""
                )}

                {brands.isLoading ? (
                  <FelterLoading header={"Brands"} />
                ) : (
                  <FilterSection
                    title="Brands"
                    items={brands.data?.data.brands || []}
                    selectedItem={selectedBrand}
                    handleChange={handleChange}
                  />
                )}

                <div className="py-5 px-2 border-2 rounded-lg">
                  <h2 className="font-bold px-5 text-2xl">Status</h2>

                  <div className="col-span-full lg:col-span-4">
                    <label
                      onClick={() => setIsOpenedStatus((prev) => !prev)}
                      className={`p-2 mt-5 pr-[15%] block text-lg select-none w-full border-2 outline-none focus:border-main ${
                        isOpenedStatus ? "border-main" : ""
                      }  duration-300 relative 
            text-start `}
                    >
                      <p
                        title={status}
                        className="overflow-hidden text-ellipsis whitespace-nowrap max-w-40"
                      >
                        {status || "all"}
                      </p>
                      {/* {!brandValue?.brandId ? "Brand" : brandValue.name} */}
                      {isOpenedStatus ? (
                        <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
                      ) : (
                        <AiOutlineCaretDown
                          className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
                        />
                      )}
                      <div
                        className={`${
                          isOpenedStatus ? "block" : "hidden"
                        } absolute max-h-[20vh] p-2  overflow-y-auto scrollbar-thin scrollbar-webkit  flex flex-col gap-1 border-2 bg-white  left-0 top-full right-0 border-main`}
                      >
                        <div
                          onClick={() => {
                            handleChange(null, "status");
                          }}
                          className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                        >
                          all
                        </div>

                        <div
                          onClick={() => {
                            handleChange("New", "status");
                          }}
                          className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                        >
                          <p className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
                            New
                          </p>
                        </div>
                        <div
                          onClick={() => {
                            handleChange("Used", "status");
                          }}
                          className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                        >
                          <p className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
                            Used
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <button
                  onClick={() => setIsOpenedFilter(false)}
                  className="py-2 px-4 bg-main flex items-center gap-1 text-white rounded-md w-fit"
                >
                  save (
                  <span className="font-medium">{products?.length || 0}</span>)
                </button>
              </div>

              <div
                className={`${
                  isOpenedFilter ? "hidden" : "grid"
                } lg:grid grid-cols-12 gap-6 mt-6`}
              >
                {isLoading ? (
                  Array(6)
                    .fill(0)
                    .map((item, index) => <ProductsLoading key={index} />)
                ) : currentProducts?.length ? (
                  currentProducts.map((product) => (
                    <Product key={product._id} product={product} />
                  ))
                ) : (
                  <div className="flex items-center justify-center col-span-full h-[50vh] text-xl font-bold">
                    No products found
                  </div>
                )}
                {products?.length > 6 && (
                  <Pagination
                    currentPage={currentPage}
                    totalRecords={
                      products?.length ||
                      initialProductsData?.data?.products?.length ||
                      0
                    }
                    recordsLimits={productsLimits}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
