import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ProductsData from "./ProductsData/ProductsData";
import ProductsDataLoading from "./ProductsDataLoading/ProductsDataLoading";
import ProductRequestDataLoading from "../ProductRequests/ProductRequestDataLoading/ProductRequestDataLoading";
import Pagination from "../../../../utils/Pagination";
import { MdOutlineSearch } from "react-icons/md";
import { toast } from "react-toastify";

const AllProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [index, setIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsLimits, setProductsLimits] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const getAllProducts = async () => {
    setIsLoading(true);
    const data = await axios
      .get(`${baseUrl}/product/allProductsForAdmin?sort=-createdAt`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setIsLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
      if (data?.data?.products) {
      setIsLoading(false);
      setProducts(data.data.products);
    }
  };

  if ((index || index == 0) && product?.enName) {
    if (index !== -1) {
      products[index] = product; // Removes 1 item at the found index
    }
    setProduct(null);
    setIndex(null);
  }

  const filteredProducts =
    products?.length &&
    products.filter((product) =>
      product.enName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const lastProductIndex = currentPage * productsLimits;
  const firstProductIndex = lastProductIndex - productsLimits;

  const currentProducts = searchQuery?.length
    ? filteredProducts.slice(firstProductIndex, lastProductIndex) || []
    : products?.length
    ? products.slice(firstProductIndex, lastProductIndex)
    : products;

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      <div className="w-full p-5">
        <label
          htmlFor="searchProduct"
          className="flex items-center gap-2 rounded-lg overflow-hidden px-5 py-2 border-2 mb-4 focus:border-main bg-white"
        >
          <MdOutlineSearch className="text-2xl lg:text-4xl" />
          {isLoading ? (
            <input
              type="text"
              id="searchProduct"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
            />
          ) : (
            <input
              type="text"
              id="searchProduct"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          )}
        </label>
        <div className="w-full pb-2 overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead>
              <tr className="flex items-center w-full rounded-t-md">
                <th className=" pr-3 lg:pr-5 w-[250px] xl:w-[25%]">
                  <span className="block w-full whitespace-nowrap  px-5 py-7 border-b-2">
                    Product name
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Stock
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Status
                  </span>
                </th>
                <th className="pl-3 lg:pl-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 bg-profileColor border-b-2">
                    Action
                  </span>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className=" w-full">
                <ProductRequestDataLoading />
              </tbody>
            ) : currentProducts?.length ? (
              currentProducts.length == 5 ? (
                <tbody className=" w-full">
                  {currentProducts.map((product) => (
                    <ProductsData
                      key={product._id}
                      product={product}
                      length={currentProducts.length}
                      indexOfProduct={currentProducts.indexOf(product)}
                      setIndex={setIndex}
                      setProduct={setProduct}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              ) : (searchQuery?.length
                  ? filteredProducts.length || 0
                  : products.length) > 5 ? (
                <tbody className=" w-full">
                  {currentProducts.map((product) => (
                    <ProductsData
                      key={product._id}
                      product={product}
                      length={currentProducts.length}
                      indexOfProduct={currentProducts.indexOf(product)}
                      setIndex={setIndex}
                      setProduct={setProduct}
                      isNotCompleted={true}
                    />
                  ))}

                  {Array(5 - currentProducts.length)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <tr className={`flex items-center w-full`}>
                          <td
                            className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.enName}
                                className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
                              >
                                {/* {product.enName} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.stock}
                                className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
                              >
                                {/* {product.stock} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <span
                              className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  `}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody className=" w-full">
                  {currentProducts.map((product) => (
                    <ProductsData
                      key={product._id}
                      product={product}
                      length={currentProducts.length}
                      indexOfProduct={currentProducts.indexOf(product)}
                      setIndex={setIndex}
                      setProduct={setProduct}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              )
            ) : (
              <tbody className=" w-full">
                <div className="size-full h-[50vh] flex items-center justify-center">
                  <p className="p-5 text-lg font-medium bg-white rounded-lg">
                    There is no products on site
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="pb-5">
        {(searchQuery?.length
          ? filteredProducts.length || 0
          : products.length) > 5 && (
          <Pagination
            currentPage={currentPage}
            totalRecords={
              searchQuery?.length
                ? filteredProducts.length || 0
                : products.length
            }
            recordsLimits={productsLimits}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};

export default AllProducts;
