import { createContext, useContext, useState } from "react";
import { UserContext } from "./UserContext";
import { useQuery } from "react-query";
import axios from "axios";
import { useQueryClientContext } from "./QueryClientContext";
import { toast } from "react-toastify";
export const CategoryContext = createContext()
function CategoryContextProvider(props) {
    const queryClient = useQueryClientContext();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const { searchQuery } = useContext(UserContext)

    const [selectedFilter, setSelectedFilter] = useState([]);

    const { baseUrl } = useContext(UserContext);

    let { data, isLoading, error } = useQuery("getAllCategoriesForAdmin", () =>
        axios.get(`${baseUrl}/category?sort=-createdAt"`).catch((err) => {
            if (!err.response) {
                toast.error("Check the internet and reload the site");
            }
        })
    );


    const filteredCategoryNames = data?.data?.categories?.filter((category) =>
        category.enName.toLowerCase().includes(searchQuery?.toLowerCase())
    )

    return (
        <CategoryContext.Provider value={{ filteredCategoryNames, selectedSubcategory, setSelectedSubcategory, selectedFilter, setSelectedFilter, selectedCategory, setSelectedCategory, categories: data?.data?.categories, categoryIsLoading: isLoading }}>
            {props.children}
        </CategoryContext.Provider>
    );
}

export default CategoryContextProvider;
