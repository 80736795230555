import React, { useContext, useEffect, useState } from "react";

import { useQuery, useQueryClient } from "react-query";
import { UserContext } from "../../../../Context/UserContext";
import axios from "axios";
import ProductRequestData from "./ProductRequestData/ProductRequestData";
import ProductRequestDataLoading from "./ProductRequestDataLoading/ProductRequestDataLoading";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../utils/Pagination";
import { toast } from "react-toastify";

function ProductRequests() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [producrDeleted, setProducrDeleted] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsLimits, setProductsLimits] = useState(5);
  const navigate = useNavigate();

  const getProductRequests = async () => {
    setIsLoading(true);
    const data = await axios
      .get(`${baseUrl}/product/productRequests?sort=-createdAt`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setIsLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.products) {
      setIsLoading(false);
      setProducts(data.data.products);
    }
  };

  const lastProductIndex = currentPage * productsLimits;
  const firstProductIndex = lastProductIndex - productsLimits;

  const currentProducts =
    products?.length && products.slice(firstProductIndex, lastProductIndex);

  useEffect(() => {
    getProductRequests();
  }, []);

  if (isDone && producrDeleted?.enName) {
    const index = products?.indexOf(producrDeleted);
    if (index !== -1) {
      products?.splice(index, 1); // Removes 1 item at the found index
    }
    setProducrDeleted(null);
    setIsDone(false);
  }

  return (
    <>
      <div className="w-full p-5">
        <div className="w-full pb-2 overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead>
              <tr className="flex items-center w-full rounded-t-md">
                <th className=" pr-3 lg:pr-5 w-[250px] xl:w-[25%]">
                  <span className="block w-full whitespace-nowrap  px-5 py-7 border-b-2">
                    Name of Trader
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Stock
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Price
                  </span>
                </th>
                <th className="pl-3 lg:pl-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 bg-profileColor border-b-2">
                    Action
                  </span>
                </th>
              </tr>
            </thead>

            {isLoading ? (
              <tbody className=" w-full">
                <ProductRequestDataLoading />
              </tbody>
            ) : currentProducts?.length ? (
              currentProducts.length == 5 ? (
                <tbody>
                  {currentProducts.map((product) => (
                    <ProductRequestData
                      key={product._id}
                      product={product}
                      length={currentProducts.length}
                      indexOfProduct={currentProducts.indexOf(product)}
                      setIsDone={setIsDone}
                      setProducrDeleted={setProducrDeleted}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              ) : products?.length > 5 ? (
                <tbody className=" w-full">
                  {currentProducts.map((product) => (
                    <ProductRequestData
                      key={product._id}
                      product={product}
                      length={currentProducts.length}
                      indexOfProduct={currentProducts.indexOf(product)}
                      setIsDone={setIsDone}
                      setProducrDeleted={setProducrDeleted}
                      isNotCompleted={true}
                    />
                  ))}

                  {Array(5 - currentProducts.length)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <tr className={`flex items-center w-full`}>
                          <td
                            className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <span
                              className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  `}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody className=" w-full">
                  {currentProducts.map((product) => (
                    <ProductRequestData
                      key={product._id}
                      product={product}
                      length={currentProducts.length}
                      indexOfProduct={currentProducts.indexOf(product)}
                      setIsDone={setIsDone}
                      setProducrDeleted={setProducrDeleted}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              )
            ) : (
              <tbody className=" w-full">
                <div className="size-full h-[50vh] flex items-center justify-center">
                  <p className="p-5 text-lg font-medium bg-white rounded-lg">
                    There is no requests of products
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
      </div>

      <div className="pb-5">
        {products?.length > 5 && (
          <Pagination
            currentPage={currentPage}
            totalRecords={products.length}
            recordsLimits={productsLimits}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}

export default ProductRequests;

// <div className="w-full p-5 bg-blue-500">
//   <div className="bg-slate-500 p-5 w-full overflow-x-auto">
// <table className="w-full">
//   <thead>
//     <tr className="flex items-center bg-white w-full">
//       <th
//         title="Name of Trader"
//         className="pr-3 lg:pr-5 min-w-[25%] max-w-[600px] px-10 border-b-2"
//       >
// <span className="block w-full overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
//         Name of Trader Name of Trader Name of Trader Name of Trader
//         Name of Trader Name of Trader Name of Trader Name of Trader
//         Name of Trader
// </span>
//       </th>

{
  /*                    <th className="px-3 lg:px-5 min-w-[25%] max-w-[600px] px-10 border-b-2">
                    <span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
                    Stock
                    </span>
                  </th>
                                    <th className="px-3 lg:px-5 min-w-[25%] max-w-[600px] px-10 border-b-2">
                    <span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
                    Price
                    </span>
                  </th>
                                    <th className="px-3 bg-profileColor lg:px-5 min-w-[25%] max-w-[600px] px-10 border-b-2">
                    <span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
                    Action
                    </span>
                  </th> */
}
{
  /* </tr>
              </thead> */
}

{
  /* <tbody className=" w-full">
                {products.map((product) => (
                  <ProductRequestData
                    key={product._id}
                    product={product}
                    length={products.length}
                    indexOfProduct={products.indexOf(product)}
                    setIsDone={setIsDone}
                    setProducrDeleted={setProducrDeleted}
                  />
                ))}
              </tbody> */
}
{
  /* </table>; */
}

// <div className="w-full p-5 bg-blue-500">
//   <div className="bg-slate-500 p-5 w-full overflow-x-auto">
//     <table className="w-full">
//       <thead>
//         <tr className="flex items-center bg-white w-full">
//           <th className="pr-3 lg:pr-5 min-w-[25%] max-w-[600px]">
//             <span className="block w-full overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
//               Lorem ipsum dolor sit, amet consectetur adipisicing elit.
//               Provident velit perspiciatis maxime ad voluptas nobis
//               tempore dolore recusandae ullam, obcaecati incidunt,
//               praesentium nulla quia excepturi labore corrupti accusamus
//               nesciunt! Quae.
//             </span>
//           </th>
//           <th className="px-3 lg:px-5 min-w-[25%] max-w-[600px]">
//             <span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
//               ahmed
//             </span>
//           </th>
//           <th className="px-3 lg:px-5 min-w-[25%] max-w-[600px]">
//             <span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
//               ahmed
//             </span>
//           </th>
//           <th className="pl-3 lg:pl-5 min-w-[25%] max-w-[600px]">
//             <span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap bg-red-400 px-5">
//               ahmed
//             </span>
//           </th>
//         </tr>
//       </thead>
//     </table>
//   </div>
// </div>;
