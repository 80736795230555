import React from "react";
import Skeleton from "react-loading-skeleton";
function CategoryLoading() {
  const arr = Array(5).fill(0);

  return (
    <>
      {arr.map((item, index) => (
        <tr key={index} className={`flex items-center w-full`}>
          <td className={`pr-3 lg:pr-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
            <div
              className={`flex items-center w-full  px-5 h-20 ${
                arr.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                <Skeleton className="w-full h-7 rounded-md" />
              </div>
            </div>
          </td>
          <td className={`pr-3 lg:pr-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
            <div
              className={`flex items-center justify-center  h-20 ${
                arr.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <Skeleton className="size-12" />
            </div>
          </td>
          <td className={`pr-3 lg:pr-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
            <div
              className={`flex items-center justify-center  h-20 ${
                arr.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                <Skeleton className="h-10 w-full rounded-md" />
              </div>
            </div>
          </td>
          <td className={`pr-3 lg:pr-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
            <div
              className={`flex items-center w-full  px-5 h-20 ${
                arr.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                <Skeleton className="w-full h-7 rounded-md" />
              </div>
            </div>
          </td>
          <td className={`pr-3 lg:pr-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
            <div
              className={`flex items-center justify-center px-10  h-20 ${
                arr.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                <Skeleton className="h-7 w-full rounded-md" />
              </div>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default CategoryLoading;
