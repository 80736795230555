import React, { useContext, useState } from "react";
import Header from "../Header/Header";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { CategoryContext } from "../../Context/CategoryContext";
import imageHeader from "../../assets/header/Categories.jpg";
import { useNavigate } from "react-router-dom";

function Category() {
  const {
    setSelectedFilter,
    setSelectedCategory,
    categories,
    categoryIsLoading,
  } = useContext(CategoryContext);
  const [categoryLimits, setCategoryLimits] = useState(5);
  const navigate = useNavigate();

  const handleSubmit = (category) => {
    setSelectedCategory(category);
    setSelectedFilter([{ enName: category.enName, type: "Categories" }]);
    navigate("/products");
  };

  return (
    <>
      <Header
        header="Category"
        nameComponent="Category"
        key={`header`}
        image={imageHeader}
      />
      <section className="text-blackColor py-10">
        <div className="container max-w-screen-xl px-5 md:w-[90%] lg:w-[85%] mx-auto">
          <h2 className="font-bold text-4xl text-center">Shop By Category</h2>
          {!categoryIsLoading && !categories?.length ? (
            <div className="pt-5">
              <p className="text-xl font-bold">
                There is no data on site till now
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-12 mt-10">
              {categories?.length
                ? categories.slice(0, categoryLimits).map((category) => {
                    return (
                      <div className="col-span-12 md:col-span-6 lg:col-span-4  p-3">
                        <button
                          onClick={() => handleSubmit(category)}
                          className="w-full"
                        >
                          <div className="bg-headerColor rounded-xl p-3 shadow-lg">
                            <div className="flex items-center gap-5">
                              <img
                                src={category.image.secure_url}
                                alt={category.enName}
                                className="size-14 object-cover"
                              />
                              <div className="">
                                <h3 className="text-2xl font-bold text-socend overflow-hidden text-ellipsis whitespace-nowrap max-w-40">
                                  {category.enName}
                                </h3>
                                {category.subcategory?.length ? (
                                  <p>
                                    ({category.subcategory?.length})
                                    subcategories
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  })
                : Array(5)
                    .fill(0)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="col-span-12 md:col-span-6 lg:col-span-4  p-3"
                      >
                        <div className="bg-headerColor rounded-xl p-3 shadow-lg">
                          <div className="flex items-center gap-5">
                            <Skeleton className="size-14 object-cover" />
                            <div className="">
                              <Skeleton className="h-7 rounded-md w-40" />
                              <Skeleton className="h-6 rounded-md w-32" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              <div
                className={`col-span-12 md:col-span-6 lg:col-span-4 p-3 ${
                  categoryIsLoading ? "hidden" : "flex"
                } items-center justify-center`}
              >
                <div className="">
                  <button
                    onClick={() => setCategoryLimits(categoryLimits + 6)}
                    className=" flex text-white"
                  >
                    <span className=" py-3 pl-3 pr-5 bg-main">See More</span>
                    <span className="bg-socend skew-x-12  py-3 px-2 -translate-x-2">
                      <MdKeyboardDoubleArrowRight className="text-2xl" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Category;
