import { useState, useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";

function PreviewImages({
  files,
  remaoveFromSubImages,
  remaoveFromOldImages,
  oldSubImages,
}) {
  const [preview, setPreview] = useState([]); // This will store the preview images with their associated ids.

  useEffect(() => {
    if (!files?.length) {

      setPreview([]);

    } else {
      const loadImagePromises = [];

      for (const image of files) {
        const file = image.file[0]; // Assuming the file is an array and you want the first file

        if (!file) continue;

        const id = image.id; // The id of the image from the file object
        const reader = new FileReader();

        const promise = new Promise((resolve) => {
          reader.onload = () => {
            resolve({ id, dataUrl: reader.result }); // Resolve with the id and the image data URL
          };
          reader.readAsDataURL(file);
        });

        // Push the promise to the array
        loadImagePromises.push(promise);
      }

      // Wait for all images to load, then update the state
      Promise.all(loadImagePromises).then((images) => {
        setPreview(images); // Set the images along with their ids in state
      });
    }
  }, [files]); // Re-run the effect when `files` changes

  return (
    <div className="grid grid-cols-6 xl:grid-cols-10 w-full items-center  gap-2">
      {oldSubImages?.length
        ? oldSubImages.map((image) => (
            <div
              className=" col-span-full sm:col-span-3  md:col-span-2 xl:col-span-2 rounded-md relative"
              key={image._id}
            >
              <div
                onClick={() => remaoveFromOldImages(image._id)}
                className="absolute rounded-full bg-white top-1 right-1 p-1 text-main cursor-pointer"
              >
                <IoClose />
              </div>
              <img
                src={image.secure_url} // Use the dataUrl from the state
                className="w-full h-40 object-cover rounded-md"
                alt={`Preview ${image.public_id}`}
              />
            </div>
          ))
        : ""}
      {preview?.length
        ? preview.map((image, index) => (
            <div
              className=" col-span-full sm:col-span-3  md:col-span-2 xl:col-span-2 rounded-md relative"
              key={image.id}
            >
              <div
                onClick={() => remaoveFromSubImages(image.id)}
                className="absolute rounded-full bg-white top-1 right-1 p-1 text-main cursor-pointer"
              >
                <IoClose className="" />
              </div>
              <img
                src={image.dataUrl} // Use the dataUrl from the state
                className="w-full h-40 object-cover rounded-md"
                alt={`Preview ${index}`}
              />
            </div>
          ))
        : ""}
    </div>
  );
}

export default PreviewImages;
