import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../../../../Context/UserContext";
import axios from "axios";
import TraderData from "./TraderRequestData/TraderRequestData";
import TraderDataLoading from "./TraderRequestDataLoading/TraderRequestDataLoading";
import { useNavigate } from "react-router-dom";
import ProductRequestDataLoading from "../../../Products/ProductRequests/ProductRequestDataLoading/ProductRequestDataLoading";
import Pagination from "../../../../../utils/Pagination";
import { toast } from "react-toastify";

function TraderRequests() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [isDone, setIsDone] = useState(false);
  const [userDeleted, setUserDeleted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersLimits, setUsersLimits] = useState(5);
  const navigate = useNavigate();
  const getTraderRequests = async () => {
    setIsLoading(true);

    const result = await axios
      .get(`${baseUrl}/user/usersForAdmin`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setIsLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });

      if (result?.data?.users) {
      setIsLoading(false);
      setUsers(result.data.users);
    }
  };

  useEffect(() => {
    getTraderRequests();
  }, []);

  if (isDone && userDeleted?.userName) {
    const index = users?.indexOf(userDeleted);
    if (index !== -1) {
      users?.splice(index, 1);
    }
  }

  const lastUserIndex = currentPage * usersLimits;
  const firstUserIndex = lastUserIndex - usersLimits;

  const currentUsers =
    users?.length && users.slice(firstUserIndex, lastUserIndex);

  return (
    <>
      <div className="w-full p-5">
        <div className="w-full pb-2 overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead>
              <tr className="flex items-center w-full rounded-t-md">
                <th className=" pr-3 lg:pr-5 w-[250px] xl:w-[25%]">
                  <span className="block w-full whitespace-nowrap  px-5 py-7 border-b-2">
                    Name
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    E-mail
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Role
                  </span>
                </th>
                <th className="pl-3 lg:pl-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 bg-profileColor border-b-2">
                    Action
                  </span>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className=" w-full">
                <ProductRequestDataLoading />
              </tbody>
            ) : currentUsers?.length ? (
              currentUsers.length == 5 ? (
                <tbody className=" w-full">
                  {currentUsers.map((user) => (
                    <TraderData
                      key={user._id}
                      length={currentUsers.length}
                      indexOfUser={currentUsers.indexOf(user)}
                      user={user}
                      setIsDone={setIsDone}
                      setUserDeleted={setUserDeleted}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              ) : users?.length > 5 ? (
                <tbody className=" w-full">
                  {currentUsers.map((user) => (
                    <TraderData
                      key={user._id}
                      length={currentUsers.length}
                      indexOfUser={currentUsers.indexOf(user)}
                      user={user}
                      setIsDone={setIsDone}
                      setUserDeleted={setUserDeleted}
                      isNotCompleted={true}
                    />
                  ))}
                  {Array(5 - currentUsers.length)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <tr className={`flex items-center w-full`}>
                          <td
                            className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.enName}
                                className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
                              >
                                {/* {product.enName} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.stock}
                                className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
                              >
                                {/* {product.stock} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <span
                              className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  `}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody className=" w-full">
                  {currentUsers.map((user) => (
                    <TraderData
                      key={user._id}
                      length={currentUsers.length}
                      indexOfUser={currentUsers.indexOf(user)}
                      user={user}
                      setIsDone={setIsDone}
                      setUserDeleted={setUserDeleted}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              )
            ) : (
              <tbody className=" w-full">
                <div className="size-full h-[50vh] flex items-center justify-center">
                  <p className="p-5 text-lg font-medium bg-white rounded-lg">
                    There is no requests of traders
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="pb-5">
        {users?.length > 5 && (
          <Pagination
            currentPage={currentPage}
            totalRecords={users.length}
            recordsLimits={usersLimits}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}

export default TraderRequests;
