import { createContext, useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const QueryClientContext = createContext(queryClient);

export const QueryClientProviderWrapper = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <QueryClientContext.Provider value={queryClient}>
            {children}
        </QueryClientContext.Provider>
    </QueryClientProvider>
);

export const useQueryClientContext = () => useContext(QueryClientContext);
