// import React, { useState } from "react";
// import FriendsList from "./FriendsList/FriendsList";
// import Message from "./Messages/Messages";
// import { useParams } from "react-router-dom";
// import { useChatContext } from "../../../Context/ChatContext";

// const Chat = () => {
//     const { selectedChat, isOpenedChat } = useChatContext();

//   return (
//     <div className="flex flex-col text-black gap-5 lg:flex-row w-full h-[80vh] overflow-hidden">
//       {/* Left side: Friends List */}
//       <div
//         className={`lg:w-2/5 w-full ${
//           selectedChat && isOpenedChat ? "hidden lg:block" : "block"
//         }`}
//       >
//         <FriendsList />
//       </div>

//       {/* Right side: Chat View */}
//       <div
//         className={`lg:w-3/5 w-full ${
//           !selectedChat && !isOpenedChat ? "hidden lg:block" : "block"
//         }`}
//       >
//         <Message />
//       </div>
//     </div>
//   );
// };

// export default Chat;





import React from "react";
import FriendsList from "./FriendsList/FriendsList";
import Message from "./Messages/Messages";
import { useChatContext } from "../../../Context/ChatContext";

const Chat = () => {
  const { selectedChat, isOpenedChat } = useChatContext();

  return (
    <div className="flex flex-col text-black gap-5 lg:flex-row w-full h-[80vh] overflow-hidden">
      <div
        className={`lg:w-2/5 w-full ${
          selectedChat && isOpenedChat ? "hidden lg:block" : "block"
        }`}
      >
        <FriendsList />
      </div>

      <div
        className={`lg:w-3/5 w-full ${
          !selectedChat && !isOpenedChat ? "hidden lg:block" : "block"
        }`}
      >
        <Message />
      </div>
    </div>
  );
};

export default Chat;