import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import axios from "axios";
import BrandData from "./BrandData/BrandData";
import BrandLoading from "./BrandLoading/BrandLoading";
import ProductRequestDataLoading from "../Products/ProductRequests/ProductRequestDataLoading/ProductRequestDataLoading";
import Pagination from "../../../utils/Pagination";
import { MdOutlineSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Brand() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brand, setBrand] = useState(null);
  const [index, setIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [brandLimits, setBrandLimits] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const getAllBrands = async () => {
    setBrandsLoading(true);
    const data = await axios
      .get(`${baseUrl}/brand/brandsFrorAdmin?sort=-createdAt`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setBrandsLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.brands) {
      setBrands(data.data.brands);
      setBrandsLoading(false);
    }
  };

  useEffect(() => {
    getAllBrands();
  }, []);

  if ((index || index == 0) && brand?._id) {
    console.log(brand);

    if (index !== -1) {
      brands[index] = brand;
    }
    setBrand(null);
    setIndex(null);
  }

  const filteredBrands =
    brands?.length &&
    brands.filter((brand) =>
      brand.enName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const lastBrandIndex = currentPage * brandLimits;
  const firstBrandIndex = lastBrandIndex - brandLimits;
  console.log({ brands });

  // const currentBrands = brands?.length
  //   ? brands.slice(firstBrandIndex, lastBrandIndex)
  //   : brands;

  const currentBrands = searchQuery?.length
    ? filteredBrands.slice(firstBrandIndex, lastBrandIndex) || []
    : brands?.length
    ? brands.slice(firstBrandIndex, lastBrandIndex)
    : brands;

  return (
    <>
      <div className="w-full p-5">
        <label
          htmlFor="searchProduct"
          className="flex items-center gap-2 rounded-lg overflow-hidden px-5 py-2 border-2 mb-4 focus:border-main bg-white"
        >
          <MdOutlineSearch className="text-2xl lg:text-4xl" />
          {brandsLoading ? (
            <input
              id="searchProduct"
              type="text"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
            />
          ) : (
            <input
              id="searchProduct"
              type="text"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
              value={brandsLoading ? "" : searchQuery}
              onChange={
                brandsLoading ? "" : (e) => setSearchQuery(e.target.value)
              }
            />
          )}
        </label>
        <div className="w-full pb-2 overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead>
              <tr className="flex items-center w-full rounded-t-md">
                <th className=" pr-3 lg:pr-5 w-[250px] xl:w-[25%]">
                  <span className="block w-full whitespace-nowrap  px-5 py-7 border-b-2">
                    Name in English
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Name in Arabic
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Status
                  </span>
                </th>
                <th className="pl-3 lg:pl-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 bg-profileColor border-b-2">
                    Action
                  </span>
                </th>
              </tr>
            </thead>

            {brandsLoading ? (
              <tbody className=" w-full">
                <ProductRequestDataLoading />
              </tbody>
            ) : currentBrands?.length ? (
              currentBrands.length == 5 ? (
                <tbody className=" w-full">
                  {currentBrands.map((brand) => (
                    <BrandData
                      brand={brand}
                      length={currentBrands.length}
                      indexOfBrand={currentBrands.indexOf(brand)}
                      setBrand={setBrand}
                      setIndex={setIndex}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              ) : (searchQuery?.length
                  ? filteredBrands?.length || 0
                  : brands?.length) > 5 ? (
                <tbody className=" w-full">
                  {currentBrands.map((brand) => (
                    <BrandData
                      brand={brand}
                      length={currentBrands.length}
                      indexOfBrand={currentBrands.indexOf(brand)}
                      setBrand={setBrand}
                      setIndex={setIndex}
                      isNotCompleted={true}
                    />
                  ))}

                  {Array(5 - currentBrands.length)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <tr className={`flex items-center w-full`}>
                          <td
                            className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.enName}
                                className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
                              >
                                {/* {product.enName} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.stock}
                                className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
                              >
                                {/* {product.stock} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <span
                              className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  `}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody className=" w-full">
                  {currentBrands.map((brand) => (
                    <BrandData
                      brand={brand}
                      length={currentBrands.length}
                      indexOfBrand={currentBrands.indexOf(brand)}
                      setBrand={setBrand}
                      setIndex={setIndex}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              )
            ) : (
              <tbody className=" w-full">
                <div className="size-full h-[50vh] flex items-center justify-center">
                  <p className="p-5 text-lg font-medium bg-white rounded-lg">
                    There is no Brand
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="pb-5">
        {(searchQuery?.length ? filteredBrands?.length || 0 : brands?.length) >
          5 && (
          <Pagination
            currentPage={currentPage}
            recordsLimits={brandLimits}
            totalRecords={
              searchQuery?.length ? filteredBrands?.length || 0 : brands?.length
            }
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}

export default Brand;
