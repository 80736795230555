import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";
import { UserContext } from "../../../Context/UserContext";
import Skeleton from "react-loading-skeleton";

function Navbar({ setIsOpened, isOpened }) {
  // const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { avatar, userData } = useContext(UserContext);

  // Handle the scroll event
  const handleScroll = () => {
    if (window.scrollY > 0) {
      // Adjust this value based on when you want to add the background
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Add event listener for scroll when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`min-h-[80px] flex items-center w-full bg-white overflow-y-auto`}
    >
      <div className=" px-5 flex gap-5 items-center w-full justify-between">
        <div className="flex  items-center gap-7">
          <button
            className={`${isOpened ? "hidden" : ""} lg:hidden outline-none`}
            onClick={() => setIsOpened((prev) => !prev)}
          >
            <VscThreeBars className="text-3xl" />
          </button>
          <h2 className="text-xl font-bold">
            Welcome, <br /> Dashboard
          </h2>
        </div>
        {!userData ? (
          <div className="flex items-center gap-2">
            <Skeleton className="size-12 rounded-full" />
            <Skeleton className="h-6 rounded-md w-32" />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <div className="size-12 rounded-full overflow-hidden">
              <img src={userData.image?.secure_url || avatar} alt="avatar" />
            </div>
            <h4 className="font-medium">{userData.userName}</h4>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
