import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { PulseLoader } from "react-spinners";
import { UserContext } from "../../Context/UserContext";
import Header from "../Header/Header";
import loginImage from "../../assets/join/Login.jpg";
import imageForms from "../../assets/imageFrorm.jpg";

function ForgetPassword() {
  const navigate = useNavigate();
  const [sendCodeLoding, setSendCodeLoding] = useState(false);
  const [forgetPasswordLoding, setForgetPasswordLoding] = useState(false);
  const [sendCodeErr, setSendCodeErr] = useState(null);
  const [forgetPasswordErr, setForgetPasswordErr] = useState(null);
  const [sendCodeSuccess, setSendCodeSuccess] = useState(null);
  const [email, setEmail] = useState(null);
  const { setToken, baseUrl } = useContext(UserContext);

  const sendCodeInitialValues = {
    email: "",
  };

  const forgetPasswordValues = {
    code: "",
    password: "",
    cPassword: "",
  };

  const sendCodeValidationSchema = Yup.object({
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "In-valid email"
      )
      .required("email is required"),
  });

  const forgetPasswordValidationSchema = Yup.object({
    code: Yup.string()
      .matches(/^\d{4}$/, "Number must be 4 digits")
      .required(),
    password: Yup.string()
      .min(8, "the minlengt of password is 8")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        "password has to contain uppercase letter and lowercase letter and number and special character "
      )
      .required("Password is required"),
    cPassword: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        "confirm password and password are not the same"
      )
      .required("confirm password is required"),
  });

  async function sendCode(values) {
    setSendCodeSuccess(null);
    setSendCodeLoding(true);
    const { data } = await axios
      .patch(`${baseUrl}/auth/sendCode`, values)
      .catch((err) => {
        if (!err.response) {
          // If there's no response (network error), handle it
          console.log("Network Error: Please check your internet connection.");
          // You can also log this error or perform other actions like redirecting to a "no internet" page
        } else {
          setSendCodeSuccess(null);
          setSendCodeLoding(false);
          setSendCodeErr(err.response.data.errMass);
        }
      });

    if (data.message == "Done") {
      setSendCodeLoding(false);
      setSendCodeErr(null);
      setSendCodeSuccess("Please, check your email. we sent you a code");
      setEmail(values.email);
    }
  }

  async function forgetPassword(values) {
    values.email = email;
    setForgetPasswordLoding(true);
    const { data } = await axios
      .patch(`${baseUrl}/auth/forgetPassword`, values)
      .catch((err) => {
        if (!err.response) {
          // If there's no response (network error), handle it
          console.log("Network Error: Please check your internet connection.");
          // You can also log this error or perform other actions like redirecting to a "no internet" page
        } else {
          setForgetPasswordLoding(false);
          setForgetPasswordErr(err.response.data.errMass);
        }
      });
    if (data.message == "Done") {
      console.log(data);

      setForgetPasswordLoding(false);
      setForgetPasswordErr(null);
      localStorage.setItem("Token", data.token);
      setToken(data.token);
      navigate("/");
    }
  }

  const sendCodeForm = useFormik({
    initialValues: sendCodeInitialValues,
    validationSchema: sendCodeValidationSchema,
    onSubmit: sendCode,
  });

  const forgetPasswordForm = useFormik({
    initialValues: forgetPasswordValues,
    validationSchema: forgetPasswordValidationSchema,
    onSubmit: forgetPassword,
  });
  return (
    <>
      <Header
        header="Forget password"
        nameComponent="Forget password"
        image={loginImage}
      />

      <div className="grid grid-cols-12 px-5 sm:px-10 lg:px-0">
        <div className="hidden lg:block lg:col-span-3">
          <img src={imageForms} alt="imageForms" className="h-full" />
        </div>

        <div className="col-span-12 lg:col-span-9 gap-8 flex flex-col justify-center py-10 lg:px-20 lg:py-5 ">
          <h3 className="text-xl font-bold mb-3 text-main text-center">
            Forget password
          </h3>
          <form
            className="text-socend flex flex-col w-full"
            onSubmit={sendCodeForm.handleSubmit}
          >
            <label htmlFor="email" className="font-medium text-sm md:text-base">
              E-mail Address
            </label>
            <input
              type="email"
              placeholder="E-mail Address"
              id="email"
              name="email"
              onBlur={sendCodeForm.handleBlur}
              onChange={sendCodeForm.handleChange}
              value={sendCodeForm.values.email}
              className={`mt-2 mb-3 bg-white font-medium outline-none shadow-2xl py-2 px-4  w-full rounded-md`}
            />
            {sendCodeForm.errors.email && sendCodeForm.touched.email ? (
              <div className="rounded-md mb-2 text-red-400 text-lg font-medium px-3 w-full">
                {sendCodeForm.errors.email}
              </div>
            ) : (
              ""
            )}
            {sendCodeErr ? (
              <div
                className={`${
                  sendCodeErr.isValid ? "" : "mb-2"
                } rounded-md text-red-400 text-lg font-medium px-3 w-full`}
              >
                {sendCodeErr}
              </div>
            ) : (
              ""
            )}

            {sendCodeSuccess ? (
              <div className="mb-2 rounded-md text-main text-lg font-medium px-3 w-full">
                {sendCodeSuccess}
              </div>
            ) : (
              ""
            )}

            {sendCodeLoding ? (
              <button
                disabled
                className="py-2 px-4 w-fit rounded-md bg-main text-white"
              >
                <PulseLoader
                  color="#fff"
                  cssOverride={{}}
                  loading
                  margin={2}
                  size={7}
                  speedMultiplier={1}
                />
              </button>
            ) : (
              <button
                className="py-2 px-4 w-fit rounded-md bg-main text-white"
                type="submit"
              >
                Send code
              </button>
            )}
          </form>
          <form
            id="forgetPsswordForm"
            onSubmit={forgetPasswordForm.handleSubmit}
            className="d-none"
          >
            <div className="">
              <label
                htmlFor="code"
                className="font-medium text-sm md:text-base"
              >
                Code
              </label>
              <input
                type="code"
                placeholder="Code"
                id="code"
                name="code"
                onBlur={forgetPasswordForm.handleBlur}
                onChange={forgetPasswordForm.handleChange}
                value={forgetPasswordForm.values.code}
                className={`mt-2 mb-3 bg-white font-medium outline-none shadow-2xl py-2 px-4  w-full rounded-md`}
              />
              {forgetPasswordForm.errors.code &&
              forgetPasswordForm.touched.code ? (
                <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {forgetPasswordForm.errors.code}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="">
              <label
                htmlFor="password"
                className="font-medium text-sm md:text-base"
              >
                Password
              </label>
              <input
                type="password"
                placeholder="password"
                id="password"
                name="password"
                onBlur={forgetPasswordForm.handleBlur}
                onChange={forgetPasswordForm.handleChange}
                value={forgetPasswordForm.values.password}
                className={`mt-2 mb-3 bg-white font-medium outline-none shadow-2xl py-2 px-4  w-full rounded-md`}
              />
              {forgetPasswordForm.errors.password &&
              forgetPasswordForm.touched.password ? (
                <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {forgetPasswordForm.errors.password}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="">
              <label
                htmlFor="cPassword"
                className="font-medium text-sm md:text-base"
              >
                Confirm password
              </label>
              <input
                type="password"
                placeholder="cPassword"
                id="cPassword"
                name="cPassword"
                onBlur={forgetPasswordForm.handleBlur}
                onChange={forgetPasswordForm.handleChange}
                value={forgetPasswordForm.values.cPassword}
                className={`mt-2 mb-3 bg-white font-medium outline-none shadow-2xl py-2 px-4  w-full rounded-md`}
              />
              {forgetPasswordForm.errors.cPassword &&
              forgetPasswordForm.touched.cPassword ? (
                <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {forgetPasswordForm.errors.cPassword}
                </div>
              ) : (
                ""
              )}
            </div>

            {forgetPasswordErr ? (
              <div
                className={`${
                  forgetPasswordErr.isValid ? "" : "mb-2"
                } rounded-md text-red-400 text-lg font-medium px-3 w-full`}
              >
                {forgetPasswordErr}
              </div>
            ) : (
              ""
            )}

            {forgetPasswordLoding ? (
              <button
                disabled
                className="py-2 px-4 w-fit rounded-md bg-main text-white"
              >
                <PulseLoader
                  color="#fff"
                  cssOverride={{}}
                  loading
                  margin={2}
                  size={7}
                  speedMultiplier={1}
                />
              </button>
            ) : (
              <button
                className="py-2 px-4 w-fit rounded-md bg-main text-white"
                type="submit"
              >
                Change password
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
