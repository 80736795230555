import React, { useContext, useState } from "react";
import { PulseLoader } from "react-spinners";
import deleteProduct from "../../../../../assets/icon.svg";
import { UserContext } from "../../../../../Context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQueryClientContext } from "../../../../../Context/QueryClientContext";
import { toast } from "react-toastify";
function ReviewData({
  length,
  indexOfReview,
  review,
  setReviewDeleted,
  setIsDone,
  isNotCompleted,
}) {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClientContext();

  const acceptReview = async (productId, id) => {
    setAcceptLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${productId}/review/${id}/accept`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setAcceptLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
        console.log(err);
      });
    if (data?.data?.message == "Done") {
      setReviewDeleted(review);
      setIsDone(true);
      setAcceptLoading(false);
      queryClient.invalidateQueries("fetchReviewsForProduct");
    }
  };

  const rejectReview = async (productId, id) => {
    setAcceptLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${productId}/review/${id}/refused`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setAcceptLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.message == "Done") {
      setReviewDeleted(review);
      setIsDone(true);
      setAcceptLoading(false);
    }
  };
  return (
    <tr className={`flex items-center w-full`}>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfReview && !isNotCompleted? "" : " border-b-2"
          }`}
        >
          <span
            title={review.productId.enName}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {review.productId.enName}
          </span>
        </div>{" "}
      </td>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfReview && !isNotCompleted? "" : " border-b-2"
          }`}
        >
          <span
            title={review.content}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {review.content}
          </span>
        </div>
      </td>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfReview && !isNotCompleted? "" : " border-b-2"
          }`}
        >
          <span
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
          >
            {review.rating}
          </span>
        </div>{" "}
      </td>

      <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <span
          className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
            length - 1 == indexOfReview ? "" : " border-b-2"
          }`}
        >
          {acceptLoading ? (
            <button
              disabled
              className="py-2 px-3 rounded-full bg-main text-white"
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => acceptReview(review.productId._id, review?._id)}
              className="py-2 px-3 rounded-full bg-main text-white"
            >
              Accept
            </button>
          )}

          {rejectLoading ? (
            <button disabled className="P-2 outline-none">
              <PulseLoader
                color="#000"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => rejectReview(review.productId._id, review?._id)}
              className="P-2 outline-none"
            >
              <img src={deleteProduct} alt="deleteProduct" />
            </button>
          )}
        </span>
      </td>

      {/* <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <span
          className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
            length - 1 === indexOfReview && !isNotCompleted? "" : " border-b-2"
          }`}
        >
          {/* {stopIsLoading ? (
            <button
              disabled
              className="py-2 px-3 rounded-full bg-red-500 text-white"
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
            //   onClick={() => stopBrand(brand?._id)}
              className={`${
                brand.deleted ? "bg-main" : "bg-red-500"
              } py-2 px-3 rounded-full  outline-none text-white`}
            >
              {!brand.deleted ? "Stop" : "Active"}
            </button>
          )} 

          <Link
            to={`/dashboard/updatebrand/${brand._id}`}
            className="py-2 px-3 rounded-full "
          >
            <MdEdit />
          </Link>
        </span>

        {/* <button className="P-2 outline-none">
            <img src={deletecategory} alt="deletecategory" />
          </button> 
      </td> */}
    </tr>
  );
}

export default ReviewData;
