import React, { useContext, useEffect, useState } from "react";
import ReviewData from "./ReviewData/ReviewData";
import ProductRequestDataLoading from "../../Products/ProductRequests/ProductRequestDataLoading/ProductRequestDataLoading";
import axios from "axios";
import { UserContext } from "../../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../utils/Pagination";
import { toast } from "react-toastify";

function ReviewRequests() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [isDone, setIsDone] = useState(false);
  const [reviewDeleted, setReviewDeleted] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewLimits, setReviewLimits] = useState(5);
  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const navigate = useNavigate();

  const getAllReviews = async () => {
    setReviewsLoading(true);
    const data = await axios
      .get(`${baseUrl}/review/getReviewByProductForAdmin?sort=-createdAt`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setReviewsLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
        console.log(err);
      });
    if (data?.data?.reviews) {
      setReviews(data.data.reviews);
      setReviewsLoading(false);
    }
  };

  if (isDone && reviewDeleted?._id) {
    const index = reviews?.indexOf(reviewDeleted);
    if (index !== -1) {
      reviews?.splice(index, 1);
    }
  }

  useEffect(() => {
    getAllReviews();
  }, []);

  const lastReviewIndex = currentPage * reviewLimits;
  const firstReviewIndex = lastReviewIndex - reviewLimits;

  const currentReviews = reviews.length
    ? reviews.slice(firstReviewIndex, lastReviewIndex)
    : reviews;

  return (
    <>
      <div className="w-full p-5">
        <div className="w-full pb-2 overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead>
              <tr className="flex items-center w-full rounded-t-md">
                <th className=" pr-3 lg:pr-5 w-[250px] xl:w-[25%]">
                  <span className="block w-full whitespace-nowrap  px-5 py-7 border-b-2">
                    Product name
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    content
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Num of stars
                  </span>
                </th>
                <th className="pl-3 lg:pl-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 bg-profileColor border-b-2">
                    Action
                  </span>
                </th>
              </tr>
            </thead>

            {reviewsLoading ? (
              <tbody className=" w-full">
                <ProductRequestDataLoading />
              </tbody>
            ) : currentReviews?.length ? (
              currentReviews.length == 5 ? (
                <tbody className=" w-full">
                  {currentReviews.map((review) => (
                    <ReviewData
                      review={review}
                      length={reviews.length}
                      indexOfReview={reviews.indexOf(review)}
                      setIsDone={setIsDone}
                      setReviewDeleted={setReviewDeleted}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              ) : reviews?.length > 5 ? (
                <tbody className=" w-full">
                  {currentReviews.map((review) => (
                    <ReviewData
                      review={review}
                      length={reviews.length}
                      indexOfReview={reviews.indexOf(review)}
                      setIsDone={setIsDone}
                      setReviewDeleted={setReviewDeleted}
                      isNotCompleted={true}
                    />
                  ))}

                  {Array(5 - currentReviews.length)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <tr className={`flex items-center w-full`}>
                          <td
                            className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.enName}
                                className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
                              >
                                {/* {product.enName} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.stock}
                                className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
                              >
                                {/* {product.stock} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <span
                              className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  `}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody className=" w-full">
                  {currentReviews.map((review) => (
                    <ReviewData
                      review={review}
                      length={reviews.length}
                      indexOfReview={reviews.indexOf(review)}
                      setIsDone={setIsDone}
                      setReviewDeleted={setReviewDeleted}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              )
            ) : (
              <tbody className=" w-full">
                <div className="size-full h-[50vh] flex items-center justify-center">
                  <p className="p-5 text-lg font-medium bg-white rounded-lg">
                    There is no Requests of reviews
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="pb-5">
        {reviews?.length > 5 && (
          <Pagination
            currentPage={currentPage}
            recordsLimits={reviewLimits}
            totalRecords={reviews?.length}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}

export default ReviewRequests;
