import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

function NewReviewChart({ data, timePeriod }) {
  // Format the labels based on the time period
  const formatDateLabel = (dateString) => {
    const date = new Date(dateString);
    const currentYear = new Date().getFullYear();

    if (timePeriod === "week" || timePeriod === "month") {
      return `${date.getDate()}/${date.getMonth() + 1}`; // Day/Month
    } else if (timePeriod === "year") {
      return `${date.getFullYear()}/${date.getMonth() + 1}`; // Year/Month
    } else if (timePeriod === "all") {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      // Only show the last five years (current year and the previous four years)
      if (currentYear - year <= 5) {
        return `${year}/${month}`; // Year/Month (e.g., 2024/5)
      }
    }

    return date.toISOString().split("T")[0]; // Default full date
  };

  // Filter data to include only the last 5 years if timePeriod is "all"
  const filteredData =
    timePeriod === "all"
      ? data.filter((entry) => {
          const year = new Date(entry.date).getFullYear();
          const currentYear = new Date().getFullYear();
          return currentYear - year <= 5; // Keep only the last 5 years
        })
      : data;

  const chartData = {
    labels: filteredData.map((entry) => formatDateLabel(entry.date)), // Format the date labels
    datasets: [
      {
        label: `Reviews Added (${timePeriod})`,
        data: filteredData.map((entry) => entry.count), // Counts
        borderColor: "orange",
        backgroundColor: "rgba(255, 165, 0, 0.2)",
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Reviews",
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
}

export default NewReviewChart;
