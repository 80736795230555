import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import {
  FaHeart,
  FaRegHeart,
  FaRegStar,
  FaStar,
  FaStarHalfAlt,
} from "react-icons/fa";
import { TbMessageDots } from "react-icons/tb";
import ImageSlider from "./ImageSlider/ImageSlider";
import ProductDetailsLoading from "./ProductDetailsLoading";
import Skeleton from "react-loading-skeleton";
import { useChatContext } from "../../Context/ChatContext";
import { CategoryContext } from "../../Context/CategoryContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PulseLoader } from "react-spinners";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { BrandContext } from "../../Context/BrandContext";

function ProductDetails() {
  const { id } = useParams();
  const [image, setImage] = useState("");
  const [product, setProduct] = useState(null);
  const [subImages, setSubImages] = useState([]);

  const {
    baseUrl,
    avatar,
    userData,
    addToWishList,
    removeFromWishList,
    setUserData,
    setToken,
    profile,
    token,
  } = useContext(UserContext);
  const { setChats, chats, setSelectedChat, setIsOpenedChat } =
    useChatContext();
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [isLoading, setIsLoading] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [isLoadingHeart, setIsLoadingHeart] = useState(false);
  const [addReviewLoading, setAddReviewLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [starsErr, setStarsErr] = useState(null);
  const [reviewsLimits, setReviewsLimits] = useState(3);
  const navigate = useNavigate();
  const { setSelectedFilter, setSelectedCategory, setSelectedSubcategory } =
    useContext(CategoryContext);
  const { setSelectedBrand } = useContext(BrandContext);

  const handleSubmitCategory = (category) => {
    setSelectedCategory(category);
    setSelectedFilter([{ enName: category.enName, type: "Categories" }]);
    navigate("/products");
  };

  const handleSubmitSubcategory = (subcategory, category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(subcategory);
    setSelectedFilter([
      { enName: category.enName, type: "Categories" },
      { enName: subcategory.enName, type: "Subcategory" },
    ]);
    navigate("/products");
  };

  const handleSubmitBrand = (brand) => {
    setSelectedBrand(brand);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setSelectedFilter([{ enName: brand.enName, type: "Brands" }]);
    navigate("/products");
  };

  const userProfile = async () => {
    const data = await profile();
    if (data?.data?.user) {
      setWishList(data.data.user.wishList.map((product) => product?._id));
      setUserData(data.data.user);
    }
    if (
      data?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
      data?.response?.data?.errMass == "Your account is blocked"
    ) {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      navigate("/login");
    }
  };

  const addProductToWishList = async (productId) => {
    setIsLoadingHeart(true);
    const data = await addToWishList(productId);
    if (data?.data?.message == "Done") {
      const result = await profile();
      if (result?.data?.user) {
        setUserData(result.data.user);
        setWishList(result.data.user.wishList.map((product) => product?._id));
      }
      setIsLoadingHeart(false);
    }
    if (
      data?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
      data?.response?.data?.errMass == "Your account is blocked"
    ) {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      setIsLoadingHeart(false);
      navigate("/login");
    }
  };

  const removeProductFromWishList = async (productId) => {
    setIsLoadingHeart(true);
    const data = await removeFromWishList(productId);
    if (data?.data?.message == "Done") {
      const result = await profile();
      if (result?.data?.user) {
        setUserData(result.data.user);
        setWishList(result.data.user.wishList.map((product) => product?._id));
      }
      setIsLoadingHeart(false);
    }
    if (
      data?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
      data?.response?.data?.errMass == "Your account is blocked"
    ) {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      setIsLoadingHeart(false);
      navigate("/login");
    }
  };

  const handleChat = async (id) => {
    const createChat = await axios
      .post(
        `${baseUrl}/chat/${id}`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });

    if (createChat?.data?.chat) {
      const exists = chats.some(
        (item) => item._id === createChat.data.chat._id
      );

      if (!exists) {
        setChats([...chats, createChat.data.chat]);
      }
      setSelectedChat(createChat.data.chat);
      setIsOpenedChat(true);
      navigate("/profile/chat");
    }
  };

  const getProduct = async () => {
    setIsLoading(true);
    const data = await axios.get(`${baseUrl}/product/${id}`).catch((err) => {
      if (!err.response) {
        toast.error("Check the internet and reload the site");
        return 0;
      }
    });
    if (data?.data?.product) {
      setProduct(data.data.product);
      data.data.product.subImages?.length
        ? setSubImages([
            data.data.product.mainImage,
            ...data.data.product.subImages,
          ])
        : setSubImages([data.data.product.mainImage]);
      setIsLoading(false);
      if (userData) {
        if (userData?.role != "Admin") {
          await axios
            .patch(
              `${baseUrl}/user/${data.data.product.createdBy._id}/product/${data.data.product._id}/productsVisits`,
              {},
              {
                headers: {
                  authorization: `Hamada__${token}`,
                },
              }
            )
            .catch((err) => {
              if (!err.response) {
                toast.error("Check the internet and reload the site");
                return 0;
              } else if (
                err?.response?.data?.errMass ==
                  "TokenExpiredError: jwt expired" ||
                err?.response?.data?.errMass == "Your account is blocked"
              ) {
                localStorage.clear();
                setToken(null);
                setUserData(null);
                navigate("/login");
              }
            });
        }
      }
    }
  };
  const {
    data,
    isLoading: reviewsLoading,
    error,
  } = useQuery("fetchReviewsForProduct", () =>
    axios
      .get(`${baseUrl}/product/${id}/review?sort=-createdAt`)
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        }
      })
  );
  const formatTimeAgo = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - createdDate) / 1000);

    // If the difference is less than a minute
    if (diffInSeconds < 60) {
      return "just now";
    }

    // If the difference is less than an hour
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    }

    // If the difference is less than a day
    const diffInHours = Math.floor(diffInSeconds / 3600);
    if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    }

    // If the difference is less than a week
    const diffInDays = Math.floor(diffInSeconds / 86400);
    if (diffInDays < 7) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    }

    // If the difference is less than a month (30 days approx)
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
      return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
    }

    // If the difference is less than a year (365 days approx)
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    }

    // If the difference is more than a year
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
  };

  const initialValues = {
    content: "",
  };

  const validationSchema = Yup.object({
    content: Yup.string()
      .min(2, "The min length is 2")
      .max(300, "The max length is 300")
      .test(
        "no-empty-lines",
        "You've to write more than two letters",
        (value) => {
          // Check if value contains anything other than spaces or new lines
          const trimmedValue = value.trim();
          return trimmedValue.length > 1;
        }
      )
      .required("Content is required"),
  });

  const addReview = async (values) => {
    setErr(null);
    setAddReviewLoading(true);
    delete values.review;
    console.log(rating);

    if (!rating) {
      setStarsErr("You have to specify the number of stars");
      return;
    }
    values.rating = rating;
    console.log({ result: "ADD review", values });

    const data = await axios
      .post(`${baseUrl}/product/${id}/review`, values, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setAddReviewLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        } else {
          setErr(err.response.data.errMass);
        }
        console.log(err);
      });
    if (data?.data?.review) {
      setAddReviewLoading(false);
      setRating(null);
      formik.values.content = "";
      console.log(data.data.review);
      // setReviews((prev) => [data.data.review, ...prev]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: addReview,
  });

  const displayRating = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - Math.ceil(rating);
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} className="text-main" />);
    }
    if (halfStar) {
      stars.push(<FaStarHalfAlt key={fullStars} className="text-main" />);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar key={fullStars + 1 + i} className="text-main" />);
    }
    return stars;
  };
  const reviews = data?.data?.reviews?.length
    ? data.data.reviews.slice(0, reviewsLimits)
    : [];

  function calculateRatingProportions(reviews) {
    if (reviews?.length) {
      const totalReviews = reviews.length;
      const fiveStars = reviews[0].productId.fiveStars;
      const fourStars = reviews[0].productId.fourStars;
      const threeStars = reviews[0].productId.threeStars;
      const twoStars = reviews[0].productId.twoStars;
      const oneStar = reviews[0].productId.oneStar;

      const formatPercentage = (num) => {
        const percentage = (num / totalReviews) * 100;
        return Number.isInteger(percentage)
          ? `${percentage}%`
          : `${percentage.toFixed(2)}%`;
      };

      return {
        fiveStars: fiveStars ? formatPercentage(fiveStars) : "0%",
        fourStars: fourStars ? formatPercentage(fourStars) : "0%",
        threeStars: threeStars ? formatPercentage(threeStars) : "0%",
        twoStars: twoStars ? formatPercentage(twoStars) : "0%",
        oneStar: oneStar ? formatPercentage(oneStar) : "0%",
      };
    }
  }

  useEffect(() => {
    getProduct();
    if (token) {
      userProfile();
    }
  }, [token]);
  return (
    <section className="pb-8 mt-20 lg:mt-28">
      <div className="container  lg:w-[90%] px-5 mx-auto">
        {isLoading || !product ? (
          <ProductDetailsLoading />
        ) : (
          <>
            {subImages?.length ? (
              <div className="lg:hidden">
                <ImageSlider images={subImages} />
              </div>
            ) : (
              ""
            )}
            <div className="grid  grid-cols-4 items-start gap-5">
              <div className="hidden lg:block col-span-2">
                {/*  */}
                <div
                  className={`grid ${
                    subImages.length < 5 ? "grid-cols-1" : "grid-cols-6"
                  } w-full gap-3 place-items-center`}
                >
                  <div className="col-span-full flex items-center justify-center">
                    <img
                      src={image || product?.mainImage.secure_url}
                      className="w-full h-96 object-cover"
                      alt={product?.enName}
                    />
                  </div>

                  {product && subImages && subImages.length < 5 && (
                    <div className="col-span-full flex justify-center gap-3">
                      {subImages.map((image, index) => (
                        <div key={index} className="gap-3">
                          <img
                            src={image.secure_url}
                            className="w-full h-20 object-cover rounded-md cursor-pointer"
                            onClick={() => setImage(image.secure_url)}
                            alt={product.enName}
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  {product &&
                    subImages &&
                    subImages.length >= 5 &&
                    subImages.map((image, index) => (
                      <div key={index} className="col-span-1 gap-3">
                        <img
                          src={image.secure_url}
                          className="w-full h-20 object-cover rounded-md cursor-pointer"
                          onClick={() => setImage(image.secure_url)}
                          alt={product.enName}
                        />
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-span-full lg:col-span-2">
                <div className="py-3 flex items-center justify-between border-b-2">
                  <h3 className="text-xl font-bold text-black">
                    {product && product.enName}
                  </h3>
                  {/* <div className="py-1 px-2 rounded-md bg-profileColor flex items-center gap-2 text-profileColorText">
                    <FaRegHeart />
                    109
                  </div> */}
                </div>
                <div className="py-3 flex items-center gap-2 lg:gap-5 border-b-2">
                  <div className="">
                    <p className=" text-main text-4xl font-bold pb-2 pr-5 ">
                      ${product && product.finalPrice}
                    </p>
                    {product && product?.discound ? (
                      <p className="text-xl line-through">${product.price}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="py-2 px-4 rounded-full bg-headerColor flex items-center gap-2 text-mainText text-lg font-medium">
                    <FaRegStar />
                    109
                  </div> */}

                  <div className=" py-1 lg:py-2  px-2 lg:px-4 rounded-full flex items-center gap-2 text-main text-lg font-medium">
                    <TbMessageDots />
                    {product.oneStar +
                      product.twoStars +
                      product.threeStars +
                      product.fourStars +
                      product.fiveStars}{" "}
                    Reviews
                  </div>
                </div>
                {userData?.role == "Admin" ||
                userData?.role == "Individual" ||
                userData?.role == "Business" ? (
                  ""
                ) : (
                  <div className="py-3 flex items-center gap-5 border-b-2">
                    {!userData ? (
                      <Link
                        to={"/login"}
                        className=" outline-none py-2 px-4 rounded-md text-white bg-main flex items-center gap-2"
                      >
                        Add to wishlist <FaRegHeart className="text-sm" />
                      </Link>
                    ) : wishList.includes(product._id) ||
                      userData?.wishList?.includes(product._id) ? (
                      <button
                        onClick={() => {
                          if (!isLoadingHeart) {
                            removeProductFromWishList(product._id);
                          }
                        }}
                        className=" outline-none py-2 px-4 rounded-md text-white bg-main flex items-center gap-2"
                      >
                        Remove from wishlist <FaHeart className="text-sm" />
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (!isLoadingHeart) {
                            addProductToWishList(product._id);
                          }
                        }}
                        className=" outline-none py-2 px-4 rounded-md text-white bg-main flex items-center gap-2"
                      >
                        Add To wishlist <FaRegHeart className="text-sm" />
                      </button>
                    )}
                    {!userData?._id ? (
                      <Link
                        to={"/login"}
                        className=" outline-none py-2 px-4 rounded-md border-2 hover:bg-main hover:text-white duration-300 border-main flex items-center gap-2"
                      >
                        Talk to the owner
                      </Link>
                    ) : (
                      <button
                        onClick={() => handleChat(product?.createdBy._id)}
                        className=" outline-none py-2 px-4 rounded-md border-2 hover:bg-main hover:text-white duration-300 border-main flex items-center gap-2"
                      >
                        Talk to the owner
                      </button>
                    )}
                  </div>
                )}
                <div className="py-3 border-b-2 flex gap-2 flex-col">
                  <p>
                    Ctegory:{" "}
                    <button
                      onClick={() => handleSubmitCategory(product.categoryId)}
                      className="text-main font-bold underline cursor-pointer"
                    >
                      {product.categoryId.enName}
                    </button>
                  </p>
                  <p>
                    Subcategory:{" "}
                    <button
                      onClick={() =>
                        handleSubmitSubcategory(
                          product.subcategoryId,
                          product.categoryId
                        )
                      }
                      className="text-main font-bold underline cursor-pointer"
                    >
                      {product.subcategoryId.enName}
                    </button>
                  </p>
                  <p>
                    Brand:{" "}
                    <button
                      onClick={() => handleSubmitBrand(product.brandId)}
                      className="text-main font-bold underline cursor-pointer"
                    >
                      {product.brandId.enName}
                    </button>
                  </p>
                  <p>
                    Trader:{" "}
                    <Link
                      to={`/traderProducts/${product.createdBy._id}`}
                      className="text-main font-bold underline cursor-pointer"
                    >
                      {product._id ? product.createdBy.userName : ""}
                    </Link>
                  </p>
                </div>
                <div className="py-3 border-b-2">
                  <p>{product ? product.enDescription : ""}</p>
                </div>
                <div className="py-3">
                  <p>Model: {product ? product.model : ""}</p>
                  <p>
                    Status: {product ? product.status : ""}
                    {product?.status == "Used" &&
                      ` for ${product.time}/hr`}{" "}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="w-full mt-5">
          <h4 className="text-xl py-5 lg:pl-5 text-black font-medium">
            Customers Feedback
          </h4>
          <div className="grid grid-cols-6 gap-5 xl:gap-10">
            <div className="col-span-full sm:col-span-2 lg:pl-5">
              <div className="py-10 rounded-md bg-product text-center">
                {reviewsLoading ? (
                  <Skeleton className="h-5 rounded-md w-10 mx-auto" />
                ) : (
                  <>
                    <p className="text-4xl font-bold text-main">
                      {data?.data?.reviews?.length
                        ? data.data.reviews[0].productId.rating || 0
                        : "0"}
                    </p>
                  </>
                )}

                {reviewsLoading ? (
                  <Skeleton className="h-5 my-4 rounded-md w-20 mx-auto" />
                ) : (
                  <>
                    <p
                      className={`py-4 flex items-center justify-center gap-1 md:gap-3 text-main md:text-lg lg:text-xl`}
                    >
                      {reviews?.length ? (
                        displayRating(reviews[0].productId.rating || 0)
                      ) : (
                        <FaRegStar className="text-main" />
                      )}
                    </p>
                  </>
                )}

                <p>Product Rating</p>
              </div>
            </div>

            <div className="col-span-full sm:col-span-4">
              <div className="size-full bg-product rounded-md flex items-center">
                <div className="flex text-main flex-col justify-between py-5 px-2 lg:px-5 text-lg lg:text-xl size-full">
                  <div className="grid grid-cols-4 gap-2 items-center">
                    <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                      <div
                        style={{
                          width:
                            calculateRatingProportions(data?.data?.reviews)
                              ?.fiveStars || "0%",
                        }}
                        className={`bg-main h-full rounded-full`}
                      ></div>
                    </div>
                    <div className="flex items-center justify-end gap-1 md:gap-2 sm:gap-1 lg:gap-2 col-span-2">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <p className="w-16 text-end font-medium">
                        {calculateRatingProportions(data?.data?.reviews)
                          ?.fiveStars || "0%"}
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-2 items-center">
                    <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                      <div
                        style={{
                          width:
                            calculateRatingProportions(data?.data?.reviews)
                              ?.fourStars || "0%",
                        }}
                        className={` bg-main h-full rounded-full`}
                      ></div>
                    </div>

                    <div className="flex items-center justify-end gap-1 md:gap-2 sm:gap-1 lg:gap-2 col-span-2">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <p className="w-16 text-end font-medium">
                        {calculateRatingProportions(data?.data?.reviews)
                          ?.fourStars || "0%"}
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-2 items-center">
                    <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                      <div
                        style={{
                          width:
                            calculateRatingProportions(data?.data?.reviews)
                              ?.threeStars || "0%",
                        }}
                        className={` bg-main h-full rounded-full`}
                      ></div>
                    </div>
                    <div className="flex items-center justify-end gap-1 md:gap-2 sm:gap-1 lg:gap-2 col-span-2">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <p className="w-16 text-end font-medium">
                        {calculateRatingProportions(data?.data?.reviews)
                          ?.threeStars || "0%"}
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-2 items-center">
                    <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                      <div
                        style={{
                          width:
                            calculateRatingProportions(data?.data?.reviews)
                              ?.twoStars || "0%",
                        }}
                        className={` bg-main h-full rounded-full`}
                      ></div>
                    </div>
                    <div className="flex items-center justify-end gap-1 md:gap-2 sm:gap-1 lg:gap-2 col-span-2">
                      <FaStar />
                      <FaStar />
                      <p className="w-16 text-end font-medium">
                        {calculateRatingProportions(data?.data?.reviews)
                          ?.twoStars || "0%"}
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-2 items-center">
                    <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                      <div
                        style={{
                          width:
                            calculateRatingProportions(data?.data?.reviews)
                              ?.oneStar || "0%",
                        }}
                        className={` bg-main h-full rounded-full`}
                      ></div>
                    </div>
                    <div className="flex items-center justify-end gap-1 md:gap-2 sm:gap-1 lg:gap-2 col-span-2">
                      <FaStar />
                      <p className="w-16 text-end font-medium">
                        {calculateRatingProportions(data?.data?.reviews)
                          ?.oneStar || "0%"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-4/5 xl:w-3/5 mt-10">
          <div className="lg:pl-5">
            <h4 className="text-xl mb-3  text-black font-medium">Reviews</h4>
            <div className="px-3">
              {reviewsLoading ? (
                Array(3)
                  .fill(0)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`flex items-start  py-6 w-full gap-10 border-b-2`}
                    >
                      <Skeleton className="size-20 rounded-full" />
                      <div className="w-full">
                        <div className="flex items-end w-full gap-10">
                          <Skeleton className="h-6 w-40 rounded-md" />
                          <Skeleton className="h-5 w-20 rounded-md" />
                        </div>
                        <Skeleton className="h-5 w-32 rounded-md" />
                        <Skeleton count={2} className="h-5 w-full rounded-md" />
                      </div>
                    </div>
                  ))
              ) : reviews?.length ? (
                reviews.map((review) => (
                  <div
                    className={`flex items-start  py-6 ${
                      reviews.length >= 3 &&
                      reviews.indexOf(review) == reviews.length - 1
                        ? ""
                        : "border-b-2 "
                    } w-full gap-10`}
                  >
                    <div className="size-20 rounded-full overflow-hidden">
                      <img
                        src={review.userId.image?.secure_url || avatar}
                        className="w-full"
                        alt={review.userId.userName}
                      />
                    </div>
                    <div className="">
                      <div className="flex items-end gap-10">
                        <p className="text-black font-medium text-lg">
                          {review.userId.userName}
                        </p>
                        <p className="text-sm text-textbody ">
                          {formatTimeAgo(review.createdAt)}
                        </p>
                      </div>
                      <div className="flex items-center text-main mb-4 mt-2 gap-1">
                        {Array(review.rating)
                          .fill(0)
                          .map((item) => {
                            return <FaStar />;
                          })}
                      </div>
                      <p className="text-sm text-textbody">{review.content}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="font-medium">
                  There is no review to this product
                </div>
              )}
            </div>
            {data?.data?.reviews?.length > 3 && !reviewsLoading ? (
              data?.data?.reviews?.length > reviews?.length ? (
                <button
                  onClick={() => setReviewsLimits(reviewsLimits + 3)}
                  className={`bg-main text-white py-2  rounded-md mt-3 mx-auto px-4 block }`}
                >
                  View More
                </button>
              ) : (
                <button
                  className={`bg-main text-white py-2 cursor-auto  rounded-md mt-3 mx-auto px-4 block`}
                >
                  View More
                </button>
              )
            ) : null}
          </div>
        </div>

        {userData?.role == "Customer" && !reviewsLoading && !isLoading ? (
          <div className=" w-full lg:w-4/5 xl:w-3/5 mt-10">
            <h4 className="text-xl mb-3 text-black font-medium">
              Write a Review
            </h4>
            <div className="p-5">
              <div className=" flex items-center gap-2">
                {Array(5)
                  .fill(0)
                  .map((star, index) => {
                    const currentRating = index + 1;
                    return (
                      <label className="">
                        <input
                          onClick={() => {
                            setRating(currentRating);
                            setStarsErr(null);
                          }}
                          type="radio"
                          name="rating"
                          className="hidden"
                          key={index}
                          value={currentRating}
                        />
                        <FaStar
                          onMouseEnter={() => setHover(currentRating)}
                          onMouseLeave={() => setHover(null)}
                          color={
                            currentRating <= (hover || rating)
                              ? "ffc107"
                              : "e4e5e9"
                          }
                          className={`cursor-pointer`}
                          size={50}
                        />
                      </label>
                    );
                  })}
              </div>
              {starsErr ? (
                <div
                  className={` rounded-md mt-2 text-red-400 text-lg mb-2 font-medium px-3 w-full`}
                >
                  {starsErr}
                </div>
              ) : (
                ""
              )}
              <form onSubmit={formik.handleSubmit}>
                <div className="py-3">
                  <textarea
                    className={`rounded-lg p-5  border-2 w-full resize-none focus:border-main outline-none`}
                    placeholder="write your review"
                    name="content"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.content}
                    maxLength={300}
                    rows={4}
                  ></textarea>
                  {formik.errors.content && formik.touched.content ? (
                    <div className=" rounded-md text-red-400 text-lg font-medium px-3 w-full">
                      {formik.errors.content}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {err ? (
                  <div
                    className={` rounded-md text-red-400 text-lg mb-2 font-medium px-3 w-full`}
                  >
                    {err}
                  </div>
                ) : (
                  ""
                )}

                {addReviewLoading ? (
                  <button
                    disabled
                    className="bg-main text-white py-2 rounded-md outline-none px-4"
                  >
                    <PulseLoader
                      color="#fff"
                      cssOverride={{}}
                      loading
                      margin={2}
                      size={7}
                      speedMultiplier={1}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={() =>
                      !rating
                        ? setStarsErr("You have to specify the number of stars")
                        : setStarsErr(null)
                    }
                    className="bg-main text-white py-2 rounded-md outline-none px-4"
                  >
                    Submit Review
                  </button>
                )}
              </form>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
}

export default ProductDetails;
