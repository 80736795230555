import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import ProductRequestDataLoading from "../../Products/ProductRequests/ProductRequestDataLoading/ProductRequestDataLoading";
import TraderData from "../Trader/TraderRequests/TraderRequestData/TraderRequestData";
import Pagination from "../../../../utils/Pagination";
import { MdOutlineSearch } from "react-icons/md";
import { toast } from "react-toastify";

function AllUsers() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState(false);
  const [user, setUser] = useState(null);
  const [index, setIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersLimits, setUsersLimits] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const getAllUsers = async () => {
    setIsLoading(true);
    const data = await axios
      .get(`${baseUrl}/user/`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setIsLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
      if (data?.data?.users) {
        setIsLoading(false);
      const allUsers = data.data.users.filter((user) => user?.role != "Admin");
      setUsers(allUsers);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  if ((index || index == 0) && user?._id) {
    console.log(user);

    if (index !== -1) {
      users[index] = user;
    }
    setUser(null);
    setIndex(null);
  }

  const filteredUsers =
    users?.length &&
    users.filter((user) =>
      user.userName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const lastUserIndex = currentPage * usersLimits;
  const firstUserIndex = lastUserIndex - usersLimits;

  //   const currentUsers =
  //         users?.length && users.slice(firstUserIndex, lastUserIndex);

  const currentUsers = searchQuery?.length
    ? filteredUsers.slice(firstUserIndex, lastUserIndex) || []
    : users?.length
    ? users.slice(firstUserIndex, lastUserIndex)
    : users;
  
  return (
    <>
      <div className="w-full p-5">
        <label
          htmlFor="searchUser"
          className="flex items-center gap-2 rounded-lg overflow-hidden px-5 py-2 border-2 mb-4 focus:border-main bg-white"
        >
          <MdOutlineSearch className=" text-2xl lg:text-4xl" />
          {isLoading ? (
            <input
              type="text"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
              id="searchUser"
            />
          ) : (
            <input
              type="text"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
              id="searchUser"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          )}
        </label>
        <div className="w-full pb-2 overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead>
              <tr className="flex items-center w-full rounded-t-md">
                <th className=" pr-3 lg:pr-5 w-[250px] xl:w-[25%]">
                  <span className="block w-full whitespace-nowrap  px-5 py-7 border-b-2">
                    Name
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Role
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Status
                  </span>
                </th>
                <th className="pl-3 lg:pl-5 w-[250px] xl:w-[25%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 bg-profileColor border-b-2">
                    Action
                  </span>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className=" w-full">
                <ProductRequestDataLoading />
              </tbody>
            ) : currentUsers?.length ? (
              currentUsers.length == 5 ? (
                <tbody className=" w-full">
                  {currentUsers.map((user) => (
                    <TraderData
                      key={user._id}
                      length={currentUsers.length}
                      indexOfUser={currentUsers.indexOf(user)}
                      user={user}
                      setIndex={setIndex}
                      setUser={setUser}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              ) : (searchQuery?.length
                  ? filteredUsers.length || 0
                  : users.length) > 5 ? (
                <tbody className=" w-full">
                  {currentUsers.map((user) => (
                    <TraderData
                      key={user._id}
                      length={currentUsers.length}
                      indexOfUser={currentUsers.indexOf(user)}
                      user={user}
                      setIndex={setIndex}
                      setUser={setUser}
                      isNotCompleted={true}
                    />
                  ))}

                  {Array(5 - currentUsers.length)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <tr className={`flex items-center w-full`}>
                          <td
                            className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.enName}
                                className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
                              >
                                {/* {product.enName} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.stock}
                                className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
                              >
                                {/* {product.stock} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <span
                              className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  `}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody className=" w-full">
                  {currentUsers.map((user) => (
                    <TraderData
                      key={user._id}
                      length={currentUsers.length}
                      indexOfUser={currentUsers.indexOf(user)}
                      user={user}
                      setIndex={setIndex}
                      setUser={setUser}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              )
            ) : (
              <tbody className=" w-full">
                <div className="size-full h-[50vh] flex items-center justify-center">
                  <p className="p-5 text-lg font-medium bg-white rounded-lg">
                    There is no users on site
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="pb-5">
        {(searchQuery?.length ? filteredUsers.length || 0 : users.length) >
          5 && (
          <Pagination
            currentPage={currentPage}
            totalRecords={
              searchQuery?.length ? filteredUsers.length || 0 : users.length
            }
            recordsLimits={usersLimits}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}

export default AllUsers;
