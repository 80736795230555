import React, { useContext, useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { UserContext } from "../../Context/UserContext";
import { useQuery } from "react-query";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import Product from "../Products/Product/Product";
import ProductsLoading from "../Products/SkeletonComponents/ProductsLoading";
import Pagination from "../../utils/Pagination";
import Header from "../Header/Header";
import imageHeader from "../../assets/header/product.jpg";
import { useChatContext } from "../../Context/ChatContext";
import { toast } from "react-toastify";

function TraderProducts() {
  const { userData, baseUrl, avatar, setToken, setUserData, token } =
    useContext(UserContext);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [productsLimits, setProductsLimits] = useState(6);
  const [sortedValue, setSortedValue] = useState("New Arrivals");
  const [selectedNav, setSelectedNav] = useState("products");
  const [sortedOpened, setSortedOpened] = useState(false);
  const [isOpenedFilter, setIsOpenedFilter] = useState(false);
  const navigate = useNavigate();
  const { setChats, chats, setSelectedChat } = useChatContext();

  const { data, isLoading, error } = useQuery("fetchTraderProducts", () =>
    axios
      .get(
        `${baseUrl}/user/${id}/product/productsOfSpecificTrader?sort=-createdAt`
      )
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
          return 0;
        }
      })
  );

  const profileVisits = async () => {
    await axios
      .patch(
        `${baseUrl}/user/${id}/profileVisits`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
          return 0;
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (userData) {
      if (userData?.role != "Admin") {
        profileVisits();
      }
    }
  }, []);

  const handleChat = async (id) => {
    const createChat = await axios
      .post(
        `${baseUrl}/chat/${id}`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
          return 0;
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });

    if (createChat?.data?.chat) {
      const exists = chats.some(
        (item) => item._id === createChat.data.chat._id
      );

      if (!exists) {
        setChats([...chats, createChat.data.chat]);
      }
      setSelectedChat(createChat.data.chat);
      navigate("/profile/chat");
    }
  };

  const lastProductIndex = currentPage * productsLimits;
  const firstProductIndex = lastProductIndex - productsLimits;

  const currentProducts =
    data?.data?.products?.length &&
    data?.data?.products
      .sort((a, b) =>
        sortedValue === "Price: ascending"
          ? a.price - b.price
          : sortedValue === "Price: descending"
          ? b.price - a.price
          : sortedValue === "New Arrivals" &&
            new Date(b.createdAt) - new Date(a.createdAt)
      )
      .slice(firstProductIndex, lastProductIndex);

  return (
    <div>
      <Header
        header="Products of trader"
        nameComponent="Products"
        image={imageHeader}
      />

      <div className="text-black px-5 md:w-[95%] lg:w-full mx-auto py-8">
        <div className="grid gap-3 grid-cols-4 ">
          <div className="hidden lg:col-span-1 lg:block rounded-md overflow-hidden">
            {!isLoading && currentProducts?.length ? (
              <>
                <div className="bg-profileColor relative h-24 ">
                  <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 size-24 rounded-full">
                    <img
                      src={
                        currentProducts[0].createdBy?.image?.secure_url ||
                        avatar
                      }
                      className="size-full rounded-full object-cover"
                      alt={currentProducts[0].createdBy.userName}
                    />
                  </div>
                </div>
                <h2 className="text-center mt-12 py-2 border-b-2 text-xl  font-bold">
                  {currentProducts[0].createdBy.userName}
                </h2>
                <div className="flex flex-col py-2 gap-4">
                  <div className="flex gap-2 items-start">
                    <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                      <MdMail />
                    </div>
                    <div className="w-[calc(100%-48px)]">
                      <h4 className="text-main text-lg font-medium">E-mail</h4>
                      <p
                        id="sss"
                        className="mt-1  w-full break-words break-word"
                      >
                        {currentProducts[0].createdBy.email}
                      </p>
                    </div>
                  </div>
                  {currentProducts[0].createdBy.address ? (
                    <div className="flex gap-2 items-start">
                      <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                        <FaLocationDot />
                      </div>
                      <div className="w-[calc(100%-48px)]">
                        <h4 className="text-main text-lg font-medium">
                          Address
                        </h4>
                        <p
                          id="sss"
                          className="mt-1  w-full break-words break-word"
                        >
                          {currentProducts[0].createdBy.address}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex gap-2 items-start">
                    <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                      <FaPhone />
                    </div>
                    <div className="w-[calc(100%-48px)]">
                      <h4 className="text-main text-lg font-medium">
                        Phone Number
                      </h4>
                      <p
                        id="sss"
                        className="mt-1  w-full break-words break-word"
                      >
                        {currentProducts[0].createdBy.phone}
                      </p>
                    </div>
                  </div>
                  {!userData ? (
                    <Link
                      to={"/login"}
                      className=" outline-none py-2 px-4 rounded-md border-2 hover:bg-main hover:text-white duration-300 border-main flex items-center gap-2"
                    >
                      Talk to the owner
                    </Link>
                  ) : (
                    <button
                      onClick={() =>
                        handleChat(currentProducts[0].createdBy._id)
                      }
                      className=" outline-none py-2 px-4 rounded-md border-2 hover:bg-main hover:text-white duration-300 border-main flex items-center gap-2"
                    >
                      Talk to the owner
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="bg-profileColor relative h-24 ">
                  <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 size-24 rounded-full">
                    <Skeleton className="rounded-full size-full" />
                  </div>
                </div>
                <h2 className="text-center mt-12 py-2 border-b-2 text-xl  font-bold">
                  <Skeleton className="rounded-md h-7 w-full" />
                </h2>
                <div className="flex flex-col py-2 gap-4">
                  <div className="flex gap-2 items-start">
                    <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                      <MdMail />
                    </div>
                    <div className="w-[calc(100%-48px)]">
                      <h4 className="text-main text-lg font-medium">E-mail</h4>
                      <p
                        id="sss"
                        className="mt-1  w-full break-words break-word"
                      >
                        <Skeleton className="h-5 w-full rounded-md" />
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-start">
                    <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                      <FaLocationDot />
                    </div>
                    <div className="w-[calc(100%-48px)]">
                      <h4 className="text-main text-lg font-medium">Address</h4>
                      <p
                        id="sss"
                        className="mt-1  w-full break-words break-word"
                      >
                        <Skeleton className="h-5 w-full rounded-md" />
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-2 items-start">
                    <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                      <FaPhone />
                    </div>
                    <div className="w-[calc(100%-48px)]">
                      <h4 className="text-main text-lg font-medium">
                        Phone Number
                      </h4>
                      <p
                        id="sss"
                        className="mt-1  w-full break-words break-word"
                      >
                        <Skeleton className="h-5 w-full rounded-md" />
                      </p>
                    </div>
                  </div>
                  <button className=" outline-none py-2 px-4 rounded-md border-2 hover:bg-main hover:text-white duration-300 border-main flex items-center gap-2">
                    Talk to the owner
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="col-span-full lg:col-span-3">
            <div>
              <ul className="flex items-center border-b-2">
                <li>
                  <button
                    onClick={() => setSelectedNav("products")}
                    className={`
                                        ${
                                          selectedNav === "products"
                                            ? "after:h-0.5"
                                            : ""
                                        }
                                        after:absolute relative after:left-0 after:top-full
                         after:w-full  after:bg-main py-2 px-8 font-bold`}
                  >
                    Products
                  </button>
                </li>
                <li className="lg:hidden">
                  <button
                    onClick={() => setSelectedNav("trader")}
                    className={`after:absolute relative 
                        after:left-0 after:top-full after:w-full     ${
                          selectedNav === "products" ? "" : "after:h-0.5"
                        } after:bg-main py-2 px-8 font-bold`}
                  >
                    Trader data
                  </button>
                </li>
              </ul>
            </div>

            <div
              className={`${
                selectedNav === "products" ? "hidden lg:block" : ""
              } col-span-full mt-4 lg:hidden rounded-md overflow-hidden`}
            >
              {!isLoading && currentProducts?.length ? (
                <>
                  <div className="bg-profileColor relative h-24 ">
                    <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 size-24 rounded-full">
                      <img
                        src={
                          currentProducts[0].createdBy?.image?.secure_url ||
                          avatar
                        }
                        className="size-full rounded-full object-cover"
                        alt={currentProducts[0].createdBy.userName}
                      />
                    </div>
                  </div>
                  <h2 className="text-center mt-12 py-2 border-b-2 text-xl  font-bold">
                    {currentProducts[0].createdBy.userName}
                  </h2>
                  <div className="flex flex-col py-2 gap-4">
                    <div className="flex gap-2 items-start">
                      <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                        <MdMail />
                      </div>
                      <div className="w-[calc(100%-48px)]">
                        <h4 className="text-main text-lg font-medium">
                          E-mail
                        </h4>
                        <p
                          id="sss"
                          className="mt-1  w-full break-words break-word"
                        >
                          {currentProducts[0].createdBy.email}
                        </p>
                      </div>
                    </div>
                    {currentProducts[0].createdBy.address ? (
                      <div className="flex gap-2 items-start">
                        <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                          <FaLocationDot />
                        </div>
                        <div className="w-[calc(100%-48px)]">
                          <h4 className="text-main text-lg font-medium">
                            Address
                          </h4>
                          <p
                            id="sss"
                            className="mt-1  w-full break-words break-word"
                          >
                            {currentProducts[0].createdBy.address}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="flex gap-2 items-start">
                      <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                        <FaPhone />
                      </div>
                      <div className="w-[calc(100%-48px)]">
                        <h4 className="text-main text-lg font-medium">
                          Phone Number
                        </h4>
                        <p
                          id="sss"
                          className="mt-1  w-full break-words break-word"
                        >
                          {currentProducts[0].createdBy.phone}
                        </p>
                      </div>
                    </div>
                    {!userData ? (
                      <Link
                        to={"/login"}
                        className=" outline-none py-2 px-4 rounded-md border-2 hover:bg-main hover:text-white duration-300 border-main flex items-center gap-2"
                      >
                        Talk to the owner
                      </Link>
                    ) : (
                      <button
                        onClick={() =>
                          handleChat(currentProducts[0].createdBy._id)
                        }
                        className=" outline-none py-2 px-4 rounded-md border-2 hover:bg-main hover:text-white duration-300 border-main flex items-center gap-2"
                      >
                        Talk to the owner
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-profileColor relative h-24 ">
                    <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 size-24 rounded-full">
                      <Skeleton className="rounded-full size-full" />
                    </div>
                  </div>
                  <h2 className="text-center mt-12 py-2 border-b-2 text-xl  font-bold">
                    <Skeleton className="rounded-md h-7 w-full" />
                  </h2>
                  <div className="flex flex-col py-2 gap-4">
                    <div className="flex gap-2 items-start">
                      <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                        <MdMail />
                      </div>
                      <div className="w-[calc(100%-48px)]">
                        <h4 className="text-main text-lg font-medium">
                          E-mail
                        </h4>
                        <p
                          id="sss"
                          className="mt-1  w-full break-words break-word"
                        >
                          <Skeleton className="h-5 w-full rounded-md" />
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-2 items-start">
                      <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                        <FaLocationDot />
                      </div>
                      <div className="w-[calc(100%-48px)]">
                        <h4 className="text-main text-lg font-medium">
                          Address
                        </h4>
                        <p
                          id="sss"
                          className="mt-1  w-full break-words break-word"
                        >
                          <Skeleton className="h-5 w-full rounded-md" />
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 items-start">
                      <div className="size-10 flex items-center text-xl text-main justify-center rounded-full bg-gray-200">
                        <FaPhone />
                      </div>
                      <div className="w-[calc(100%-48px)]">
                        <h4 className="text-main text-lg font-medium">
                          Phone Number
                        </h4>
                        <p
                          id="sss"
                          className="mt-1  w-full break-words break-word"
                        >
                          <Skeleton className="h-5 w-full rounded-md" />
                        </p>
                      </div>
                    </div>
                    <button className="bg-main text-white rounded-md py-2 px-4 w-fit">
                      Talk to trader
                    </button>
                  </div>
                </>
              )}
            </div>

            <div
              className={`${
                selectedNav === "products" ? "" : "hidden lg:block"
              } bg-product p-2 my-4 rounded-md`}
            >
              <div className="flex items-center justify-between">
                {isLoading ? (
                  <Skeleton className="h-6 w-52" />
                ) : (
                  <div className="">
                    <p className="text-blackColor">
                      We found{" "}
                      <span className="font-medium">
                        {currentProducts?.length}
                      </span>{" "}
                      items for you!
                    </p>
                  </div>
                )}

                <button
                  className="p-1 relative px-2 rounded-md text-black bg-white flex items-center gap-3"
                  onClick={() => setSortedOpened((prev) => !prev)}
                >
                  <div>
                    Sort by: <span>{sortedValue}</span>
                  </div>
                  {sortedOpened ? <FaChevronUp /> : <FaChevronDown />}
                  <ul
                    className={`${
                      sortedOpened ? "block absolute" : "hidden"
                    } top-full left-0 w-full rounded-md z-30 overflow-hidden bg-white`}
                  >
                    <li>
                      <button
                        onClick={() => setSortedValue("New Arrivals")}
                        className="p-1 hover:bg-product w-full text-start"
                      >
                        New Arrivals
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setSortedValue("Price: ascending")}
                        className="p-1 hover:bg-product w-full text-start"
                      >
                        Price: ascending
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setSortedValue("Price: descending")}
                        className="p-1 hover:bg-product w-full text-start"
                      >
                        Price: descending
                      </button>
                    </li>
                  </ul>
                </button>
              </div>
            </div>
            <div
              className={`${isOpenedFilter ? "hidden" : "grid"} 
              ${selectedNav === "products" ? "" : "hidden lg:block"}
                              lg:grid grid-cols-12 gap-6 mt-6`}
            >
              {isLoading ? (
                Array(6)
                  .fill(0)
                  .map((item, index) => <ProductsLoading key={index} />)
              ) : currentProducts?.length ? (
                currentProducts.map((product) => (
                  <Product key={product._id} product={product} />
                ))
              ) : (
                <div className="flex items-center justify-center col-span-full h-[50vh] text-xl font-bold">
                  No products found
                </div>
              )}
              {data?.data?.products?.length > 6 && (
                <Pagination
                  currentPage={currentPage}
                  totalRecords={currentProducts?.length || 0}
                  recordsLimits={productsLimits}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TraderProducts;
