import React, { useContext, useState } from "react";
import seeMore from "../../../../../assets/see-more.svg";
import deleteProduct from "../../../../../assets/icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../Context/UserContext";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { useQueryClientContext } from "../../../../../Context/QueryClientContext";
import { toast } from "react-toastify";
function ProductRequestData({
  product,
  length,
  indexOfProduct,
  setProducrDeleted,
  setIsDone,
  isNotCompleted,
}) {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const queryClient = useQueryClientContext();
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const navigate = useNavigate();
  const acceptAccount = async (id) => {
    setAcceptLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${id}/accept`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setAcceptLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.message == "Done") {
      setProducrDeleted(product);
      setIsDone(true);
      setAcceptLoading(false);
      queryClient.invalidateQueries("fetchInitialProducts");
    }
  };
  const rejectAccount = async (id) => {
    setRejectLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${id}/refused`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setRejectLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.message == "Done") {
      setProducrDeleted(product);
      setIsDone(true);

      setRejectLoading(false);
    }
  };

  return (
    <tr className={`flex items-center w-full`}>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfProduct && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={product.createdBy.userName}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {product.createdBy.userName}
          </span>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfProduct && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={product.stock}
            className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
          >
            {product.stock}
          </span>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 == indexOfProduct && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={product.finalPrice}
            className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block `}
          >
            {product.finalPrice}
          </span>
        </div>
      </td>
      <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <span
          className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
            length - 1 == indexOfProduct && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          {acceptLoading ? (
            <button
              disabled
              className="py-2 px-3 rounded-full bg-main text-white"
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => acceptAccount(product?._id)}
              className="py-2 px-3 rounded-full bg-main text-white"
            >
              Accept
            </button>
          )}
          <Link to={`/dashboard/productRequestDetails/${product._id}`}>
            <img src={seeMore} alt="seeMore" />
          </Link>

          {rejectLoading ? (
            <button disabled className="P-2 outline-none">
              <PulseLoader
                color="#000"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => rejectAccount(product._id)}
              className="P-2 outline-none"
            >
              <img src={deleteProduct} alt="deleteProduct" />
            </button>
          )}
        </span>
      </td>
    </tr>
  );
}

export default ProductRequestData;
