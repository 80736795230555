import React, { useContext, useEffect, useState } from "react";
import logo1 from "../../../assets/dashboard/log1.svg";
import logo2 from "../../../assets/dashboard/logo2.png";
import logo3 from "../../../assets/dashboard/logo3.png";
import logo4 from "../../../assets/dashboard/logo4.png";
import logo5 from "../../../assets/dashboard/logo5.png";
import ReviewChart from "./ReviewChart/ReviewChart";
import imageRate from "../../../assets/dashboard/Group 19.png";
import {
  FaChevronDown,
  FaChevronUp,
  FaRegStar,
  FaStar,
  FaStarHalfAlt,
} from "react-icons/fa";
import { UserContext } from "../../../Context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function Dashboard() {
  const { userData, baseUrl, avatar, setToken, setUserData, token } =
    useContext(UserContext);
  const [avrRate, setAvrRate] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("week");
  const [chartData, setChartData] = useState([]);
  const navigate = useNavigate();
  const getReviews = async () => {
    setIsLoading(true);
    const data = await axios
      .get(
        `${baseUrl}/user/${userData?._id}/review/getReviewByTrader?timePeriod=${timePeriod}`,
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        setIsLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.reviews) {
      setIsLoading(true);
      setAvrRate(data.data.avrRate);
      setReviews(data.data.reviews);
    }
  };

  const displayRating = () => {
    const stars = [];
    const fullStars = Math.floor(avrRate);
    const halfStar = avrRate % 1 !== 0;
    const emptyStars = 5 - Math.ceil(avrRate);
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} className="text-main" />);
    }
    if (halfStar) {
      stars.push(<FaStarHalfAlt key={fullStars} className="text-main" />);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar key={fullStars + 1 + i} className="text-main" />);
    }
    return stars;
  };

  function calculateRatingProportions(reviews) {
    if (reviews?.length) {
      const totalReviews = reviews.length;
      const fiveStars = reviews[0].productId?.fiveStars || 0;
      const fourStars = reviews[0].productId?.fourStars || 0;
      const threeStars = reviews[0].productId?.threeStars || 0;
      const twoStars = reviews[0].productId?.twoStars || 0;
      const oneStar = reviews[0].productId?.oneStar || 0;

      const formatPercentage = (num) => {
        const percentage = (num / totalReviews) * 100;
        return Number.isInteger(percentage)
          ? `${percentage}%`
          : `${percentage.toFixed(2)}%`;
      };

      return {
        fiveStars: fiveStars ? formatPercentage(fiveStars) : "0%",
        fourStars: fourStars ? formatPercentage(fourStars) : "0%",
        threeStars: threeStars ? formatPercentage(threeStars) : "0%",
        twoStars: twoStars ? formatPercentage(twoStars) : "0%",
        oneStar: oneStar ? formatPercentage(oneStar) : "0%",
      };
    }
  }

  const processChartData = () => {
    const now = new Date();
    let startDate;

    // Determine the start date based on timePeriod
    if (timePeriod === "week") {
      startDate = new Date();
      startDate.setDate(now.getDate() - 7);
    } else if (timePeriod === "month") {
      startDate = new Date();
      startDate.setMonth(now.getMonth() - 1);
    } else if (timePeriod === "year") {
      startDate = new Date();
      startDate.setFullYear(now.getFullYear() - 1);
    } else {
      startDate = new Date(0); // For "all", include all reviews
    }

    // Filter reviews within the selected time period
    const filteredReviews = reviews.filter((review) => {
      const createdAt = new Date(review.createdAt);
      return createdAt >= startDate && createdAt <= now;
    });

    // Group reviews by day
    const groupedData = {};
    filteredReviews.forEach((review) => {
      const date = new Date(review.createdAt).toISOString().split("T")[0];
      if (!groupedData[date]) {
        groupedData[date] = 0;
      }
      groupedData[date]++;
    });

    // Convert grouped data into an array format suitable for the chart
    const result = [];
    let currentDate = new Date(startDate);
    while (currentDate <= now) {
      const dateStr = currentDate.toISOString().split("T")[0];
      result.push({
        date: dateStr,
        count: groupedData[dateStr] || 0,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setChartData(result);
  };

  useEffect(() => {
    getReviews();
  }, [timePeriod]);

  useEffect(() => {
    processChartData();
  }, [reviews, timePeriod]);

  return (
    <div className="">
      <div className="grid grid-cols-6 gap-5">
        <div className="col-span-full md:col-span-3 xl:col-span-2">
          <div className="flex items-center gap-5 p-5 bg-[#FFEEE8]">
            <div className="p-3 bg-white">
              <img className="size-6" src={logo1} alt="logo1" />
            </div>
            <div className="">
              <p className="text-xl text-black">
                {userData?.profileVisits.length || 0}
              </p>
              <p className="text-sm text-textbody">Profile visits</p>
            </div>
          </div>
        </div>
        {/* <div className="col-span-full md:col-span-3 xl:col-span-2">
          <div className="flex items-center gap-5 p-5 bg-[#FFEEE8]">
            <div className="p-3 bg-white">
              <img src={logo2} alt="logo2" />
            </div>
            <div className="">
              <p className="text-xl text-black">952</p>
              <p className="text-sm text-textbody">
                percentage of repeat visits
              </p>
            </div>
          </div>
        </div> */}
        <div className="col-span-full md:col-span-3 xl:col-span-2">
          <div className="flex items-center gap-5 p-5 bg-[#FFEEE8]">
            <div className="p-3 bg-white">
              <img className="size-6" src={logo3} alt="logo3" />
            </div>
            <div className="">
              <p className="text-xl text-black">
                {userData?.productsVisits.length || 0}
              </p>
              <p className="text-sm text-textbody"> products visits</p>
            </div>
          </div>
        </div>
        <div className="col-span-full md:col-span-3 xl:col-span-2">
          <div className="flex items-center gap-5 p-5 bg-[#FFEEE8]">
            <div className="p-3 bg-white">
              <img className="size-6" src={logo4} alt="logo4" />
            </div>
            <div className="">
              <p className="text-xl text-black">
                {userData?.totalReviews || 0}
              </p>
              <p className="text-sm text-textbody">Total reviews</p>
            </div>
          </div>
        </div>
        {/* <div className="col-span-full md:col-span-3 xl:col-span-2">
          <div className="flex items-center gap-5 p-5 bg-[#FFEEE8]">
            <div className="p-3 bg-white">
              <img src={logo5} alt="logo5" />
            </div>
            <div className="">
              <p className="text-xl text-black">241</p>
              <p className="text-sm text-textbody">
                number of requests to display contact information
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="mt-5 lg:w-2/3">
        <div className="flex items-center justify-between lg:px-4 border-b">
          <p className="">Products Rating</p>
          <button
            onClick={() => setIsOpened((prev) => !prev)}
            className="flex items-center gap-2 outline-none relative py-4"
          >
            {timePeriod == "all" ? "All reviews" : `This ${timePeriod}`}
            {isOpened ? <FaChevronUp /> : <FaChevronDown />}
            <div
              className={`absolute ${
                isOpened ? "block" : "hidden"
              }  p-2 border-2 bg-white left-1/2 -translate-x-1/2 top-full  right-0 border-main text-center w-full`}
            >
              <ul className=" flex flex-col gap-1">
                <li
                  onClick={() => setTimePeriod("week")}
                  className="w-full hover:bg-main hover:text-white rounded-md font-medium duration-300"
                >
                  week
                </li>
                <li
                  onClick={() => setTimePeriod("month")}
                  className="w-full hover:bg-main hover:text-white rounded-md font-medium duration-300"
                >
                  month
                </li>
                <li
                  onClick={() => setTimePeriod("year")}
                  className="w-full hover:bg-main hover:text-white rounded-md font-medium duration-300"
                >
                  year
                </li>
                <li
                  onClick={() => setTimePeriod("all")}
                  className="w-full hover:bg-main hover:text-white rounded-md font-medium duration-300"
                >
                  all
                </li>
              </ul>
            </div>
          </button>
        </div>

        <div className="grid grid-cols-6 gap-5 xl:gap-10 py-4 lg:px-4 border-b">
          <div className="col-span-3">
            <div className="py-10 rounded-md bg-profileColor text-center">
              <p className="text-4xl font-bold text-main">{avrRate || "0"}</p>
              <p
                className={`py-4 flex items-center justify-center gap-1 md:gap-3 text-main md:text-lg lg:text-xl`}
              >
                {avrRate ? (
                  displayRating()
                ) : (
                  <FaRegStar className="text-main" />
                )}
              </p>
              <p className="font-medium text-black">Overall Rating</p>
            </div>
          </div>
          <div className="col-span-3">
            <div className="size-full flex items-end">
              <ReviewChart
                timePeriod={timePeriod}
                data={chartData}
                // reviewChanges={reviewChanges}
                // reviews={reviews}
              />
            </div>
          </div>
        </div>

        <div className="py-4 lg:px-4">
          <div className="size-full rounded-md flex items-center">
            <div className="flex text-main flex-col justify-between text-lg lg:text-xl size-full">
              <div className="grid grid-cols-4 gap-2 items-center">
                <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                  <div
                    style={{
                      width:
                        calculateRatingProportions(reviews)?.fiveStars || "0%",
                    }}
                    className={`bg-main h-full rounded-full`}
                  ></div>
                </div>
                <div className="flex items-center justify-end gap-2  col-span-2">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <p className="w-16 text-end font-medium">
                    {calculateRatingProportions(reviews)?.fiveStars || "0%"}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 items-center">
                <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                  <div
                    style={{
                      width:
                        calculateRatingProportions(reviews)?.fourStars || "0%",
                    }}
                    className={` bg-main h-full rounded-full`}
                  ></div>
                </div>

                <div className="flex items-center justify-end gap-2  col-span-2">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <p className="w-16 text-end font-medium">
                    {calculateRatingProportions(reviews)?.fourStars || "0%"}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 items-center">
                <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                  <div
                    style={{
                      width:
                        calculateRatingProportions(reviews)?.threeStars || "0%",
                    }}
                    className={` bg-main h-full rounded-full`}
                  ></div>
                </div>
                <div className="flex items-center justify-end gap-2  col-span-2">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <p className="w-16 text-end font-medium">
                    {calculateRatingProportions(reviews)?.threeStars || "0%"}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 items-center">
                <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                  <div
                    style={{
                      width:
                        calculateRatingProportions(reviews)?.twoStars || "0%",
                    }}
                    className={` bg-main h-full rounded-full`}
                  ></div>
                </div>
                <div className="flex items-center justify-end gap-2  col-span-2">
                  <FaStar />
                  <FaStar />
                  <p className="w-16 text-end font-medium">
                    {calculateRatingProportions(reviews)?.twoStars || "0%"}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 items-center">
                <div className="col-span-2 bg-slate-400 h-2 rounded-full">
                  <div
                    style={{
                      width:
                        calculateRatingProportions(reviews)?.oneStar || "0%",
                    }}
                    className={` bg-main h-full rounded-full`}
                  ></div>
                </div>
                <div className="flex items-center justify-end gap-2  col-span-2">
                  <FaStar />
                  <p className="w-16 text-end font-medium">
                    {calculateRatingProportions(reviews)?.oneStar || "0%"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
