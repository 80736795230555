import React from 'react'
import Skeleton from 'react-loading-skeleton';

function MyProductDetailsLoading() {
  return (
    <div className="grid grid-cols-12 mt-4 gap-4">
      <div className="col-span-full lg:col-span-6">
        <label className=" font-medium">Name in Arabic</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full lg:col-span-6">
        <label className=" font-medium">Name in English</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full lg:col-span-6">
        <label className=" font-medium">Description in Arabic</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full lg:col-span-6">
        <label className=" font-medium">Description in English</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className=" col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Price</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Discound</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Stock</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className=" col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Model</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Status</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Hours</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Category Name</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Subcategory Name</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Brand Name</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full grid grid-cols-12 gap-4">
        <div className="col-span-full sm:col-span-6 lg:col-span-3">
          <label className=" font-medium">Main image</label>
          <Skeleton className="h-80 sm:h-72 lg:h-52 mt-1 rounded-md" />
        </div>
        <div className="col-span-full">
          <label className=" font-medium">Image list</label>
          <div className="mt-1  grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-10 gap-4">
            {Array(5)
              .fill(0)
              .map((item, index) => {
                return (
                  <div key={index} className="col-span-2">
                    <Skeleton className="h-80 sm:h-72 lg:h-52 mt-1 rounded-md" />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Category Status</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Subcategory Status</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Brand Status</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Product Status</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full md:col-span-6 lg:col-span-4">
        <label className=" font-medium">Trader Status (Your status)</label>
        <Skeleton className="h-10 mt-1 rounded-md" />
      </div>
      <div className="col-span-full">
        <div className="mt-1 grid grid-cols-12 gap-4">
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <Skeleton className="h-10 mt-1 rounded-md" />
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <Skeleton className="h-10 mt-1 rounded-md" />
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <Skeleton className="h-10 mt-1 rounded-md" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProductDetailsLoading
