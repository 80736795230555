import React from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

function TraderDetailsLoading() {
  const {pathname} = useLocation()
  return (
    <div>
      <div className="grid grid-cols-4 gap-4 p-5 ">
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="h-10 w-FULL rounded-md" />
        </div>
        <div className="col-span-full ">
          <Skeleton className="h-7 w-52 rounded-md" />
          <Skeleton className="size-[50vh] rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="size-[50vh] rounded-md" />
        </div>
        <div className="col-span-full lg:col-span-2">
          <Skeleton className="h-7 w-32 rounded-md" />
          <Skeleton className="size-[50vh] rounded-md" />
        </div>
        {pathname.includes("userDetails") ? (
          <div className="col-span-full lg:col-span-2">
            <button
              className={`py-2 px-4 outline-none rounded-md w-full text-white bg-red-500`}
            >
              Block user
            </button>
          </div>
        ) : (
          <div className="col-span-full grid grid-cols-4 gap-4">
            <div
              className={`col-span-full lg:col-span-2`}
            >
              <button
                className="py-2 px-4 outline-none rounded-md text-white w-full bg-main"
              >
                Accept
              </button>
            </div>
            <div
              className={` col-span-full lg:col-span-2`}
            >
              <button className="py-2 px-4 outline-none rounded-md w-full bg-profileColor">
                Reject
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TraderDetailsLoading;
