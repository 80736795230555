import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { UserContext } from "../../../../Context/UserContext";
import { useQueryClientContext } from "../../../../Context/QueryClientContext";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";

function Subcategory({
  subcategory,
  setSubcategoryIndex,
  setSubcategory,
  indexOfSubcategory,
  categoryId,
}) {
  const [isLoadingStopSubCategory, setIsLoadingStopSubCategory] =
    useState(false);
  const navigate = useNavigate();
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const queryClient = useQueryClientContext();
  const stopSubCategory = async (subcategory) => {
    setIsLoadingStopSubCategory(true);
    const data = await axios
      .patch(
        `${baseUrl}/subcategory/${subcategory._id}`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        console.log(err);

        setIsLoadingStopSubCategory(false);

        if (!err.response) {
          // If there's no response (network error), handle it
          console.log("Network Error: Please check your internet connection.");
          // You can also log this error or perform other actions like redirecting to a "no internet" page
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });

    if (data?.data?.message == "Done") {
      setIsLoadingStopSubCategory(false);

      setSubcategory(data?.data?.subcategory);
      setSubcategoryIndex(indexOfSubcategory);

      queryClient.invalidateQueries("getAllCategoriesForAdmin");
      queryClient.invalidateQueries("fetchInitialProducts");
      queryClient.invalidateQueries("getAllProductsNames");
    }
  };

  return (
    <div className="py-1 rounded-md flex items-center px-2 justify-between gap-5">
      <p
        title={subcategory.enName}
        className={`overflow-hidden text-ellipsis whitespace-nowrap max-w-16`}
      >
        {subcategory.enName}
      </p>
      <div className=" flex items-center gap-2">
        {isLoadingStopSubCategory ? (
          <button
            disabled
            className={`py-1 px-2 rounded-full ${
              subcategory.deleted ? "bg-red-500" : "bg-main"
            } text-white`}
          >
            <PulseLoader
              color="#fff"
              cssOverride={{}}
              loading
              // margin={5}
              size={7}
              speedMultiplier={1}
            />
          </button>
        ) : (
          <button
            onClick={() => stopSubCategory(subcategory)}
            className={` ${
              subcategory.deleted ? "bg-red-500" : "bg-main"
            } cursor-pointer text-sm rounded-full relative group text-white py-1 px-2`}
          >
            {subcategory.deleted ? "Stopped" : "Active"}
          </button>
        )}

        <Link
          to={`/dashboard/${categoryId}/updatesubcategory/${subcategory._id}`}
        >
          <MdEdit />
        </Link>
      </div>
    </div>
  );
}

function CategoryData({
  category,
  length,
  indexOfCategory,
  index,
  openCategory,
  onCategoryClick,
  setCategory,
  setIndex,
  isNotCompleted,
}) {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const queryClient = useQueryClientContext();
  const isOpen = openCategory === category._id;
  const [isLoadingStopCategory, setStopIsLoadingCategory] = useState(false);
  const [subcategoryIndex, setSubcategoryIndex] = useState(null);
  const [subcategory, setSubcategory] = useState(null);

  const navigate = useNavigate();

  const stopCategory = async (id) => {
    setStopIsLoadingCategory(true);
    const data = await axios
      .patch(
        `${baseUrl}/category/${id}`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        console.log(err);

        setStopIsLoadingCategory(false);

        if (!err.response) {
toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.message == "Done") {
      setStopIsLoadingCategory(false);
      setCategory(data.data.category);
      setIndex(indexOfCategory);
      queryClient.invalidateQueries("getAllCategoriesForAdmin");
      queryClient.invalidateQueries("fetchInitialProducts");
      queryClient.invalidateQueries("getAllProductsNames");
    }
  };

  if ((subcategoryIndex || subcategoryIndex == 0) && category?._id) {
    console.log(category);

    if (subcategoryIndex !== -1) {
      category.subcategory[subcategoryIndex] = subcategory;
    }
    setSubcategory(null);
    setSubcategoryIndex(null);
  }


  return (
    <tr className={`flex items-center w-full`}>
      <td className={`pr-3 lg:pr-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfCategory && !isNotCompleted
              ? ""
              : " border-b-2"
          }`}
        >
          <span
            title={category.enName}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {category.enName}
          </span>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
        <div
          className={`flex items-center justify-center  h-20 px-5 ${
            length - 1 === indexOfCategory && !isNotCompleted
              ? ""
              : " border-b-2"
          }`}
        >
          <img
            src={category.image.secure_url}
            alt={category.enName}
            className="size-12 object-cover"
          />
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfCategory && !isNotCompleted
              ? ""
              : " border-b-2"
          }`}
        >
          <div className="relative w-full">
            <div
              onClick={() => onCategoryClick(category._id)} // Handle category click
              className={`p-2 block text-lg select-none w-full border-2 outline-none  ${
                isOpen ? "border-main " : ""
              } text-black cursor-pointer`}
            >
              Subcategories
              {(length - 1 === indexOfCategory ||
                length - 2 === indexOfCategory) &&
              !isNotCompleted ? (
                !isOpen ? (
                  <AiOutlineCaretUp className="text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]" />
                ) : (
                  <AiOutlineCaretDown
                    className={`text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]`}
                  />
                )
              ) : isOpen ? (
                <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
              ) : (
                <AiOutlineCaretDown
                  className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
                />
              )}
            </div>
            <div
              className={`${isOpen ? "block" : "hidden"} ${
                (length - 1 === indexOfCategory ||
                length - 2 === indexOfCategory) && !isNotCompleted
                  ? ` bottom-full`
                  : `top-full`
              } absolute z-40 max-h-[25vh]  min-w-full p-2 overflow-y-auto scrollbar-thin scrollbar-webkit   flex-col gap-1 border-2 bg-white  left-1/2 -translate-x-1/2  border-main text-center w-max`}
            >
              {category?.subcategory?.length ? (
                category.subcategory.map((subcategory) => (
                  <Subcategory
                    categoryId={category._id}
                    subcategory={subcategory}
                    key={subcategory._id}
                    indexOfSubcategory={category.subcategory.indexOf(
                      subcategory
                    )}
                    setSubcategoryIndex={setSubcategoryIndex}
                    setSubcategory={setSubcategory}
                  />
                ))
              ) : (
                <p>There are no subcategories in this category</p>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className={`px-3 lg:px-5 w-[200px] min-w-[20%] xl:max-w-[20%] `}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfCategory && !isNotCompleted
              ? ""
              : " border-b-2"
          }`}
        >
          <span
            title={category.deleted ? "Stopped" : "Active"}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5 ${
              category.deleted ? "text-red-500" : ""
            }`}
          >
            {category.deleted ? "Stopped" : "Active"}
          </span>
        </div>
      </td>
      <td className={`pl-3 lg:pl-5 w-[200px] min-w-[20%] xl:max-w-[20%]`}>
        <span
          className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
            length - 1 === indexOfCategory && !isNotCompleted
              ? ""
              : " border-b-2"
          }`}
        >
          {isLoadingStopCategory ? (
            <button
              disabled
              className="py-2 px-3 rounded-full bg-red-500 text-white"
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => stopCategory(category?._id)}
              className={`${
                category.deleted ? "bg-main" : "bg-red-500"
              } py-2 px-3 rounded-full  outline-none text-white`}
            >
              {!category.deleted ? "Stop" : "Active"}
            </button>
          )}
          <Link
            to={`/dashboard/updatecategory/${category._id}`}
            className="py-2 px-3 rounded-full"
          >
            <MdEdit />
          </Link>
        </span>
      </td>
    </tr>
  );
}

export default CategoryData;
