import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Sidebar from "../Dashboard/Sidebar/Sidebar";
import Navbar from "../Dashboard/Navbar/Navbar";

function AdminLayout() {
  const { profile, setUserData, setToken, token } = useContext(UserContext);
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();

  const getUserProfile = async () => {
    const result = await profile();

    if (result.data?.user) {
      setUserData(result.data.user);
    }

    if (result?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      navigate("/login");
    }
  };

  useEffect(() => {
    if (token) {
      getUserProfile();
    }
  }, [token]);

  return (
    <>
      <div className="overflow-hidden">
        <div className="grid grid-cols-10 h-screen">
          <ScrollToTop />
          <div
            className={`${
              isOpened ? "block" : "hidden"
            } col-span-full md:col-span-3 lg:block lg:col-span-2`}
          >
            <Sidebar isOpened={isOpened} setIsOpened={setIsOpened} />
          </div>
          <div
            className={`bg-product ${
              isOpened ? "hidden md:col-span-7" : "block "
            } col-span-full md:block lg:col-span-8 h-screen`}
          >
            <Navbar setIsOpened={setIsOpened} isOpened={isOpened} />
            <div className="h-[calc(100%-80px)] overflow-y-auto">
            <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLayout;
