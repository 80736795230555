import { createContext, useContext, useState } from "react";
import { UserContext } from "./UserContext";
import { useQuery } from "react-query";
import axios from "axios";
import { useQueryClientContext } from "./QueryClientContext";
import { toast } from "react-toastify";
export const BrandContext = createContext()
function BrandContextProvider(props) {
    const queryClient = useQueryClientContext();
    const [selectedBrand, setSelectedBrand] = useState(null);
    const { searchQuery } = useContext(UserContext)

    const { baseUrl } = useContext(UserContext);

    let { data, isLoading, error } = useQuery("getAllBrandsForSearch", () =>
        axios.get(`${baseUrl}/brand?sort=-createdAt"`).catch((err) => {
            if (!err.response) {
                toast.error("Check the internet and reload the site");
            }
        })
    );


    const filteredBrandNames = data?.data?.brands?.filter((brand) =>
        brand.enName.toLowerCase().includes(searchQuery?.toLowerCase())
    )

    return (
        <BrandContext.Provider value={{ selectedBrand, filteredBrandNames, setSelectedBrand,brands: data?.data?.brands, brandIsLoading: isLoading }}>
            {props.children}
        </BrandContext.Provider>
    );
}

export default BrandContextProvider;
