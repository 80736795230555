import React, { createContext, useState, useContext } from 'react';
import { UserContext } from './UserContext';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const { userData } = useContext(UserContext)
    const [selectedChat, setSelectedChat] = useState(null);
    const [isOpenedChat, setIsOpenedChat] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [chats, setChats] = useState([])
    // const [lastMessage, setLastMessage] = useState(null)


    const filteredFriends = chats.filter(chat =>
        chat.POne._id == userData?._id ? chat.POne.userName.toLowerCase().includes(searchQuery.toLowerCase())
            : chat.PTwo.userName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <ChatContext.Provider value={{ isOpenedChat, setIsOpenedChat, chats, setChats, selectedChat, setSelectedChat, searchQuery, setSearchQuery, filteredFriends }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => useContext(ChatContext);
