import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { CategoryContext } from "../../../Context/CategoryContext";

function ShopByCategory() {
  const {
    setSelectedFilter,
    setSelectedCategory,
    categories,
    categoryIsLoading,
  } = useContext(CategoryContext);
  const navigate = useNavigate();

  const handleSubmit = (category) => {
    setSelectedCategory(category);
    setSelectedFilter([{ enName: category.enName, type: "Categories" }]);
    navigate("/products");
  };
  return (
    <section className="py-8">
      <div className="container max-w-screen-xl lg:w-[90%] mx-auto px-5">
        <h2 className="text-center text-3xl font-bold uppercase">
          Shop By Category
        </h2>
        {!categoryIsLoading && !categories?.length ? (
          <div className="pt-5">
            <p className="text-xl font-bold">
              There is no data on site till now
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-12 mt-10">
            {!categoryIsLoading && categories?.length
              ? categories?.slice(0, 7).map((category) => {
                  return (
                    <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 p-3">
                      <button
                        onClick={() => handleSubmit(category)}
                        key={category._id}
                        className="w-full outline-none"
                      >
                        <div className="bg-headerColor rounded-xl p-3 shadow-lg">
                          <div className="flex items-center gap-5">
                            <img
                              src={category.image.secure_url}
                              alt={category.enName}
                              className="size-14 object-cover"
                            />
                            <div className="">
                              <h3 className="text-2xl font-bold text-socend overflow-hidden text-ellipsis whitespace-nowrap max-w-40">
                                {category.enName}
                              </h3>
                              {category.subcategory?.length ? (
                                <p>
                                  ({category.subcategory?.length}) subcategories
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  );
                })
              : Array(7)
                  .fill(0)
                  .map((item, index) => (
                    <div
                      key={index}
                      className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 p-3"
                    >
                      <div className="bg-headerColor rounded-xl p-3 shadow-lg">
                        <div className="flex items-center gap-5">
                          <Skeleton className="size-14 object-cover" />
                          <Skeleton className="h-7 rounded-md w-32" />
                        </div>
                      </div>
                    </div>
                  ))}
            <div
              className={`col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 p-3 ${
                categoryIsLoading ? "hidden" : "flex"
              } items-center justify-center`}
            >
              <div className="">
                <Link className=" flex text-white" to="/category">
                  <span className=" py-3 pl-3 pr-5 bg-main">See More</span>
                  <span className="bg-socend skew-x-12  py-3 px-2 -translate-x-2">
                    <MdKeyboardDoubleArrowRight className="text-2xl" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ShopByCategory;
