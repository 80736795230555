import React, { useContext, useState } from "react";
import deletecategory from "../../../../assets/icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { PulseLoader } from "react-spinners";
import { UserContext } from "../../../../Context/UserContext";
import axios from "axios";
import { useQueryClientContext } from "../../../../Context/QueryClientContext";

function BrandData({
  brand,
  length,
  indexOfBrand,
  setIndex,
  setBrand,
  isNotCompleted,
}) {
  const [stopIsLoading, setStopIsLoading] = useState(false);
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const queryClient = useQueryClientContext();
  const stopBrand = async (id) => {
    console.log(id);
    setStopIsLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/brand/${id}/`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        setStopIsLoading(false);

        if (!err.response) {
          // If there's no response (network error), handle it
          console.log("Network Error: Please check your internet connection.");
          // You can also log this error or perform other actions like redirecting to a "no internet" page
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    setStopIsLoading(false);
    if (data?.data?.message == "Done") {
      setBrand(data.data.brand);
      setIndex(indexOfBrand);
      queryClient.invalidateQueries("getBrands");
      queryClient.invalidateQueries("fetchInitialProducts");
      queryClient.invalidateQueries("getAllProductsNames");
    }
  };

  return (
    <tr className={`flex items-center w-full`}>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfBrand && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={brand.enName}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {brand.enName}
          </span>
        </div>{" "}
      </td>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfBrand && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={brand.arName}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
          >
            {brand.arName}
          </span>
        </div>{" "}
      </td>
      <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <div
          className={`flex items-center justify-center  h-20 ${
            length - 1 === indexOfBrand && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          <span
            title={brand.deleted ? "Stopped" : "Active"}
            className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5 ${
              brand.deleted ? "text-red-500" : ""
            }`}
          >
            {brand.deleted ? "Stopped" : "Active"}
          </span>
        </div>{" "}
      </td>
      <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
        <span
          className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  ${
            length - 1 === indexOfBrand && !isNotCompleted ? "" : " border-b-2"
          }`}
        >
          {stopIsLoading ? (
            <button
              disabled
              className="py-2 px-3 rounded-full bg-red-500 text-white"
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => stopBrand(brand?._id)}
              className={`${
                brand.deleted ? "bg-main" : "bg-red-500"
              } py-2 px-3 rounded-full  outline-none text-white`}
            >
              {!brand.deleted ? "Stop" : "Active"}
            </button>
          )}

          <Link
            to={`/dashboard/updatebrand/${brand._id}`}
            className="py-2 px-3 rounded-full "
          >
            <MdEdit />
          </Link>
        </span>

        {/* <button className="P-2 outline-none">
            <img src={deletecategory} alt="deletecategory" />
          </button> */}
      </td>
    </tr>
  );
}

export default BrandData;
