import React, { useContext, useEffect, useState } from "react";
import Header from "./Header/Header";
import ShopByCategory from "./ShopByCategory/ShopByCategory";
import Products from "./Products/Products";
import RequiredProducts from "./RequiredProducts/RequiredProducts";
import Brands from "./Brands/Brands";
import Membership from "./Membership/Membership";
import Join from "./Join/Join";
import axios from "axios";
import { UserContext } from "../../Context/UserContext";
import { toast } from "react-toastify";
function Home() {
  const { baseUrl, userData } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const getAllCategories = async () => {
    setCategoriesLoading(true);
    const data = await axios.get(`${baseUrl}/category`).catch((err) => {
      setCategoriesLoading(false);
      if (!err.response) {
        toast.error("Check the internet and reload the site");
      }
    });
    setCategories(data?.data?.categories);
    setCategoriesLoading(false);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <div className="text-blackColor overflow-hidden">
      <Header />
      <ShopByCategory />
      <Products categories={categories} />
      <RequiredProducts categories={categories} />
      <Membership />
      {!userData && <Join />}

      <Brands />
    </div>
  );
}

export default Home;
