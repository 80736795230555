import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useChatContext } from "../../Context/ChatContext";
import { UserContext } from "../../Context/UserContext";
import Skeleton from "react-loading-skeleton";
import MyProductDetailsLoading from "./MyProductDetailsLoading/MyProductDetailsLoading";
import { PulseLoader } from "react-spinners";
import { useQueryClientContext } from "../../Context/QueryClientContext";
import { toast } from "react-toastify";

function MyProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  const {
    baseUrl,
    avatar,
    userData,
    addToWishList,
    removeFromWishList,
    setUserData,
    setToken,
    profile,
    token,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [stopIsLoading, setStopIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const queryClient = useQueryClientContext();
  const navigate = useNavigate();
  const getProduct = async () => {
    setIsLoading(true);
    const data = await axios
      .get(`${baseUrl}/product/${id}/getMyProductById`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    setProduct(data?.data?.product && data.data.product);
    setIsLoading(false);
  };
  const stopProduct = async () => {
    setStopIsLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${id}`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        setStopIsLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.product) {
      setStopIsLoading(false);
      console.log(data.data.product);
      setProduct(data.data.product);
      queryClient.invalidateQueries("fetchInitialProducts");
      queryClient.invalidateQueries("getAllProductsNames");
    }
  };
  const deleteProduct = async () => {
    setDeleteIsLoading(true);
    const data = await axios
      .delete(`${baseUrl}/product/${id}`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        setDeleteIsLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.message == "Done") {
      setDeleteIsLoading(false);
      queryClient.invalidateQueries("fetchInitialProducts");
      queryClient.invalidateQueries("getAllProductsNames");
      navigate("/profile/myProducts");
    }
  };

  //   const displayRating = (length) => {
  //     if (!length) {
  //       return <FaRegStar />;
  //     } else {
  //       const stars = [];
  //       for (let i = 0; i < length; i++) {
  //         stars.push(<FaStar />);
  //       }
  //       return stars;
  //     }
  //   };

  useEffect(() => {
    getProduct();
    // userProfile();
  }, []);
  return (
    <div className="text-black">
      <h3 className=" font-semibold text-lg lg:text-xl">Product details</h3>
      {isLoading || !product ? (
        <MyProductDetailsLoading />
      ) : (
        <div className="grid grid-cols-12 mt-4 gap-4">
          <div className="col-span-full lg:col-span-6">
            <label className=" font-medium">Name in Arabic</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.arName}
            </p>
          </div>
          <div className="col-span-full lg:col-span-6">
            <label className=" font-medium">Name in English</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.enName}
            </p>
          </div>
          <div className="col-span-full lg:col-span-6">
            <label className=" font-medium">Description in Arabic</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.arDescription}
            </p>
          </div>
          <div className="col-span-full lg:col-span-6">
            <label className=" font-medium">Description in English</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.enDescription}
            </p>
          </div>
          <div className=" col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Price</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.price}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Discound</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.discound || 0}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Stock</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.stock}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Model</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.model}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Status</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.status}
            </p>
          </div>
          {product.status == "Used" ? (
            <div className="col-span-full md:col-span-6 lg:col-span-4">
              <label className=" font-medium">Hours</label>
              <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
                {product.time}
              </p>
            </div>
          ) : (
            <div className="hidden md:block md:col-span-6 lg:col-span-4"></div>
          )}

          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Category Name</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.categoryId.enName}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Subcategory Name</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.subcategoryId.enName}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Brand Name</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words ">
              {product.brandId.enName}
            </p>
          </div>

          <div className="col-span-full grid grid-cols-12 gap-4">
            <div className="col-span-full sm:col-span-6 lg:col-span-3">
              <label className=" font-medium">Main image</label>
              <img
                className="mt-1 rounded-md w-full"
                src={product?.mainImage.secure_url}
                alt=""
              />
            </div>

            <div className="col-span-full">
              <label className=" font-medium">Image list</label>
              <div className="mt-1 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-10 gap-4">
                {product?.subImages?.length
                  ? product.subImages.map((image) => {
                      return (
                        <div key={image._id} className="col-span-2">
                          <img
                            className="rounded-md w-full"
                            src={image.secure_url}
                            alt=""
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Category Status</label>
            <p
              className={`mt-1 font-medium text-sm  ${
                product.categoryId?.deleted ? "text-red-400" : "text-textbody"
              }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
            >
              {product.categoryId?.deleted ? "Not active" : "Active"}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Subcategory Status</label>
            <p
              className={`mt-1 font-medium text-sm  ${
                product.subcategoryId?.deleted
                  ? "text-red-400"
                  : "text-textbody"
              }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
            >
              {product.subcategoryId?.deleted ? "Not active" : "Active"}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Brand Status</label>
            <p
              className={`mt-1 font-medium text-sm  ${
                product.brandId?.deleted ? "text-red-400" : "text-textbody"
              }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
            >
              {product.brandId?.deleted ? "Not active" : "Active"}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Product Status</label>
            <p
              className={`mt-1 font-medium text-sm ${
                product.blocked ||
                product.deleted ||
                product.categoryId?.deleted ||
                product.subcategoryId?.deleted ||
                product.brandId?.deleted ||
                product.createdBy?.deleted
                  ? "text-red-400"
                  : "text-textbody"
              } border-2 py-2 px-4 whitespace-pre-wrap break-words `}
            >
              {product.blocked
                ? "Blocked"
                : product.deleted
                ? "Stopped"
                : product.categoryId?.deleted
                ? "Not active"
                : product.subcategoryId?.deleted
                ? "Not active"
                : product.brandId?.deleted
                ? "Not active"
                : product.createdBy?.deleted
                ? "Not active"
                : "Active"}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Trader Status (Your status)</label>
            <p
              className={`mt-1 font-medium text-sm ${
                product.createdBy?.deleted ? "text-red-400" : "text-textbody"
              }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
            >
              {product.createdBy?.deleted ? "Not active" : "Active"}
            </p>
          </div>
          <div className="col-span-full">
            <div className="mt-1 grid grid-cols-12 gap-4">
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                <Link
                  to={`/profile/updateProduct/${product._id}`}
                  className="py-2 block text-center px-4 outline-none text-white bg-yellow-500 rounded-md w-full"
                >
                  Update product
                </Link>
              </div>
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                {stopIsLoading ? (
                  <button
                    disabled
                    className={`py-2 px-4 outline-none text-white ${
                      product.deleted ? "bg-green-400" : "bg-red-500"
                    }  rounded-md w-full`}
                  >
                    <PulseLoader
                      color="#fff"
                      cssOverride={{}}
                      loading
                      margin={2}
                      size={7}
                      speedMultiplier={1}
                    />
                  </button>
                ) : (
                  <button
                    onClick={() => stopProduct()}
                    className={`py-2 px-4 outline-none text-white ${
                      product.deleted ? "bg-green-400" : "bg-red-500"
                    }  rounded-md w-full`}
                  >
                    {product.deleted ? "Active product" : "Stop product"}
                  </button>
                )}
              </div>
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                {deleteIsLoading ? (
                  <button
                    disabled
                    className="py-2 px-4 outline-none text-white bg-red-800 rounded-md w-full"
                  >
                    <PulseLoader
                      color="#fff"
                      cssOverride={{}}
                      loading
                      margin={2}
                      size={7}
                      speedMultiplier={1}
                    />
                  </button>
                ) : (
                  <button
                    onClick={() => deleteProduct()}
                    className="py-2 px-4 outline-none text-white bg-red-800 rounded-md w-full"
                  >
                    Delete product
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyProductDetails;
