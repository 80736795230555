import React, { useContext, useEffect, useState } from "react";
import { BsCart2 } from "react-icons/bs";
import {
  FaHeart,
  FaRegHeart,
  FaRegStar,
  FaStar,
  FaStarHalfAlt,
} from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../Context/UserContext";
function Product({ product, productType }) {
  const {
    addToWishList,
    removeFromWishList,
    setUserData,
    userData,
    setToken,
    token,
    profile,
  } = useContext(UserContext);

  const [wishList, setWishList] = useState([]);
  const [isLoadingHeart, setIsLoadingHeart] = useState(false);
  const navigate = useNavigate();

  const userProfile = async () => {
    const data = await profile();
    if (data?.data?.user) {
      setWishList(data.data.user.wishList.map((product) => product._id));
      setUserData(data.data.user);
    }
    if (
      data?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
      data?.response?.data?.errMass == "Your account is blocked"
    ) {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      navigate("/login");
    }
  };
  const addProductToWishList = async (productId) => {
    console.log({ productId, type: "add" });

    setIsLoadingHeart(true);
    const data = await addToWishList(productId);
    if (data?.data?.message == "Done") {
      const result = await profile();
      if (result?.data?.user) {
        setUserData(result.data.user);
        setWishList(result.data.user.wishList.map((product) => product._id));
      }

      setIsLoadingHeart(false);
    }
    console.log(data?.response?.data);
    
    if (
      data?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
      data?.response?.data?.errMass == "Your account is blocked"
    ) {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      setIsLoadingHeart(false);
      navigate("/login");
    }
  };
  const removeProductFromWishList = async (productId) => {
    console.log({productId,type:"remove"});
    
    setIsLoadingHeart(true);
    const data = await removeFromWishList(productId);
    if (data?.data?.message == "Done") {
      const result = await profile();
      if (result?.data?.user) {
        setUserData(result.data.user);
        setWishList(result.data.user.wishList.map((product) => product._id));
      }
      setIsLoadingHeart(false);
    }
    if (
      data?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
      data?.response?.data?.errMass == "Your account is blocked"
    ) {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      setIsLoadingHeart(false);
      navigate("/login");
    }
  };
  useEffect(() => {
    if (token) {
      userProfile();
    }
  }, []);

  const displayRating = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - Math.ceil(rating);
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} className="text-main" />);
    }
    if (halfStar) {
      stars.push(<FaStarHalfAlt key={fullStars} className="text-main" />);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar key={fullStars + 1 + i} className="text-main" />);
    }
    return stars;
  };

  return (
    <div
      className={`${
        productType == "requiredProduct"
          ? "col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
          : "col-span-12 sm:col-span-6 xl:col-span-4"
      } `}
    >
      <div className="rounded-lg overflow-hidden bg-white shadow-md relative">
        {userData?.role == "Admin" ||
        userData?.role == "Individual" ||
        userData?.role == "Business" ? (
          ""
        ) : !userData ? (
          <Link
            to="/login"
            className="absolute top-3 right-3 outline-none p-2 rounded-full text-white bg-main "
          >
            <FaRegHeart className="text-sm" />
          </Link>
        ) : wishList.includes(product._id) ||
          userData?.wishList?.includes(product._id) ? (
          <button
            onClick={() => {
              if (!isLoadingHeart) {
                removeProductFromWishList(product._id);
              }
            }}
            className="absolute top-3 right-3 outline-none p-2 rounded-full text-white bg-main "
          >
            <FaHeart className="text-sm" />
          </button>
        ) : (
          <button
            onClick={() => {
              if (!isLoadingHeart) {
                addProductToWishList(product._id);
              }
            }}
            className="absolute top-3 right-3 outline-none p-2 rounded-full text-white bg-main "
          >
            <FaRegHeart className="text-sm" />
          </button>
        )}
        <Link
          to={
            productType === "myProduct"
              ? `/profile/myProductDetails/${product._id}`
              : `/product/${product._id}`
          }
        >
          <img
            src={product.mainImage.secure_url}
            alt={product.name}
            className="w-full h-80 lg:h-60 object-cover"
          />
          <div className="p-3">
            <div className="flex justify-between items-center  py-3">
              <div className="">
                <h3 className=" text-black text-xl font-medium overflow-hidden text-ellipsis whitespace-nowrap max-w-80 sm:max-w-40">
                  {product.enName}
                </h3>
              </div>
              <div className="flex text-main">
                {displayRating(product.rating || 0)}
              </div>
            </div>
            <p className="font-medium overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
              {product.model}
            </p>

            <div className="flex justify-between items-center py-2">
              <p className="text-xl flex items-center gap-2 text-black font-medium">
                <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-40 sm:max-w-24">
                  {product.status == "New" ? "New" : `Used for ${product.time}`}
                </span>
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Product;
