import { createContext, useContext, useState } from "react";
import { UserContext } from "./UserContext";
import { useQuery } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
export const ProductContext = createContext()
function ProductContextProvider(props) {
    const [searchProduct, setSearchProduct] = useState(null);
    const [change, setChange] = useState(null);
    const { searchQuery }=useContext(UserContext)
    // const [errorMessage, setErrorMessage] = useState(null);
    const { baseUrl } = useContext(UserContext);

    const { data, isLoading, error } = useQuery("getAllProductsNames", () =>
        axios.get(`${baseUrl}/product?sort=-createdAt"`).catch((err) => {
            if (!err.response) {
                toast.error("Check the internet and reload the site");
            }
        })
    );

    const filteredProductNames = data?.data?.products?.filter((product) =>
        product.enName.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    const filteredProducts = data?.data?.products?.filter((product) =>
        product.enName.toLowerCase().includes(searchProduct?.toLowerCase())
    )

    return (
        <ProductContext.Provider value={{ change, setChange, searchProduct, filteredProductNames, setSearchProduct, allProducts: data?.data?.products, productsErrors: error, initialProductsData: data, productsLoading: isLoading, filteredProducts }}>
            {props.children}
        </ProductContext.Provider>
    );
}

export default ProductContextProvider;
