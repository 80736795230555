import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { UserContext } from "../../Context/UserContext";

function ProductDetailsLoading() {
  const responsiveSettings = {
    0: {
      items: 1, // 1 item on small screens (mobile)
    },
    600: {
      items: 2, // 2 items on medium screens (tablets)
    },
    1000: {
      items: 3, // 3 items on large screens (desktops)
    },
  };
  return (
    <>
      <div className="lg:hidden">
        <OwlCarousel
          className="owl-theme cursor-grab"
          autoplayTimeout={2000}
          items={1}
          loop
          dots={true}
          responsive={responsiveSettings}
        >
          {Array(5)
            .fill(0)
            .map((image, index) => {
              return (
                <div key={index} className="p-5">
                  <Skeleton className="w-full rounded-md h-80" />
                </div>
              );
            })}
        </OwlCarousel>
      </div>

      <div className="grid  grid-cols-4 items-center lg:items-start gap-5">
        <div className="hidden lg:block col-span-2">
          <div className={`grid grid-cols-6 w-full gap-3 place-items-center`}>
            <div className="col-span-full flex items-center justify-center w-full">
              <div className="w-[70%] h-96">
                <Skeleton className="size-full ronded-md" />
              </div>
            </div>

            {Array(6)
              .fill(0)
              .map((item, index) => (
                <div key={index} className="col-span-1 gap-3 w-full">
                  <div className="w-full h-20 ">
                    <Skeleton className="size-full rounded-md" />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className=" col-span-full lg:col-span-2">
          <div className="py-3 border-b-2">
            <Skeleton className="h-8 rounded-md" />
          </div>
          <div className="py-3  border-b-2">
              <Skeleton className="h-10  rounded-md" />
            </div>
          
          <div className="py-3 border-b-2">
            <Skeleton className="h-10 rounded-md" />
          </div>
          <div className="py-3 border-b-2 flex gap-2 flex-col">
            <Skeleton count={4} className="h-6 rounded-md" />
          </div>
          <div className="py-3">
            <Skeleton count={2} className="h-6 rounded-md" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetailsLoading;
