import React, { useContext, useEffect } from "react";
import { useChatContext } from "../../../../Context/ChatContext";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../Context/UserContext";
import axios from "axios";
import { socket } from "../../../../utils/Socket";
// import { Link } from "react-router-dom";

const FriendsList = () => {
  const { userData, avatar, setUserData, setToken, baseUrl, token } =
    useContext(UserContext);
  const {
    filteredFriends,
    setSelectedChat,
    searchQuery,
    setSearchQuery,
    selectedChat,
    chats,
    setChats,
    isOpenedChat,
    setIsOpenedChat,
  } = useChatContext();
  const navigate = useNavigate();
  // const getAllChats = async () => {
  //   const { data } = await axios
  //     .get(`${baseUrl}/chat`, {
  //       headers: {
  //         authorization: `Hamada__${token}`,
  //       },
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
  //         localStorage.clear();
  //         setToken(null);
  //         setUserData(null);
  //         navigate("/login");
  //       }
  //     });
  //   console.log(data);
  // };

  useEffect(() => {
    socket.emit("updateSocketId", { authorization: `Hamada__${token}` });
    socket.on("updateSocketId", (data) => {
      console.log(data);
    });

    // getAllChats();

    socket.emit("getChats", { authorization: `Hamada__${token}` });

    socket.on("authSocket", (data) => {
      if (data == "Expire token") {
        localStorage.clear();
        setToken(null);
        setUserData(null);
        navigate("/login");
      }
    });

    socket.on("getChats", (data) => {
      const chatsWithMessages = data.filter((chat) => chat.messages.length > 0);
      const chatsWithoutMessages = data.filter(
        (chat) => chat.messages.length === 0
      );

      // Sort chats with messages by the most recent message date (modern to old)
      const sortedChatsWithMessages = chatsWithMessages.sort((a, b) => {
        const lastMessageA = a.messages[a.messages.length - 1];
        const lastMessageB = b.messages[b.messages.length - 1];
        return new Date(lastMessageB.date) - new Date(lastMessageA.date);
      });

      // Sort chats without messages by createdAt (old to new)
      const sortedChatsWithoutMessages = chatsWithoutMessages.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      // Combine both arrays: Chats without messages at the top, sorted by createdAt
      const sortedChats = [
        ...sortedChatsWithoutMessages,
        ...sortedChatsWithMessages,
      ];

      setChats(sortedChats);
    });

    // return () => {
    //   socket.off("updateSocketId");
    //   socket.off("getChats");
    //   socket.off("authSocket");
    // };
  }, [token, setChats, selectedChat, isOpenedChat]);

  const handleSelectFriend = (chat) => {
    setSelectedChat(chat);
    setIsOpenedChat(true);
  };

  const formatDate = (date) => {
    const now = new Date();
    const messageDate = new Date(date);

    // Difference in time
    const timeDifference = now - messageDate;

    // Less than a day (show hours and minutes)
    if (timeDifference < 24 * 60 * 60 * 1000) {
      return messageDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    // Less than a year ago (show month and day)
    if (timeDifference < 365 * 24 * 60 * 60 * 1000) {
      return messageDate.toLocaleDateString([], {
        month: "numeric",
        day: "numeric",
      });
    }

    // More than a year ago (show year, month, and day)
    return messageDate.toLocaleDateString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  return (
    <div className="flex flex-col rounded-md border-2 h-[80vh] overflow-y-auto">
      <h2 className="text-lg font-bold mt-4 text-center">Message</h2>
      <div className="px-4">
        <input
          type="text"
          className="w-full p-2 my-4 rounded-lg border-2  focus:border-main outline-none"
          placeholder="Search for a friend..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex-1 overflow-y-auto space-y-1 p-2 px-4 scrollbar-thin scrollbar-webkit">
        {searchQuery
          ? filteredFriends?.map((chat) => (
              <div
                // to={`/profile/chat/messages/${friend.id}`}
                key={chat._id}
                className={`flex items-center p-2 ${
                  selectedChat._id == chat._id && isOpenedChat
                    ? "bg-profileColor"
                    : "hover:bg-headerColor"
                } hover:bg-profileColor cursor-pointer px-6`}
                onClick={() => handleSelectFriend(chat)}
              >
                <img
                  src={
                    chat.POne._id == userData._id
                      ? chat.PTwo?.image?.secure_url || avatar
                      : chat.POne?.image?.secure_url || avatar
                  }
                  alt={
                    chat.POne._id == userData._id
                      ? chat.PTwo.userName
                      : chat.POne.userName
                  }
                  className="w-10 h-10 rounded-full mr-4"
                />
                <div className="w-full">
                  <div className="flex items-center justify-between">
                    <p className="font-semibold">
                      {chat.POne._id == userData._id
                        ? chat.PTwo.userName.length > 15
                          ? `${chat.PTwo.userName.slice(0, 15)}....`
                          : chat.PTwo.userName
                        : chat.POne.userName.length > 15
                        ? `${chat.POne.userName.slice(0, 15)}....`
                        : chat.POne.userName}
                    </p>
                    {chat.messages.length ? (
                      <span className=" text-sm text-gray-400">
                        {new Date(
                          chat.messages[chat.messages.length - 1].date
                        ).toLocaleTimeString()}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    {chat.messages.length ? (
                      <p className="text-sm text-gray-600">
                        {chat.messages[chat.messages.length - 1].content
                          .length > 20
                          ? `${chat.messages[
                              chat.messages.length - 1
                            ].content.slice(0, 20)}....`
                          : chat.messages[chat.messages.length - 1].content}
                      </p>
                    ) : (
                      ""
                    )}

                    {/* <div
                  className={`size-2 ${
                    friend.isActive ? "bg-main" : ""
                  }  rounded-full`}
                ></div> */}
                  </div>
                </div>
              </div>
            ))
          : chats?.map((chat) => (
              <div
                key={chat._id}
                className={`flex  ${
                  selectedChat?._id == chat?._id
                    ? "bg-profileColor"
                    : "hover:bg-headerColor"
                } items-center p-2 rounded-md cursor-pointer px-6`}
                onClick={() => handleSelectFriend(chat)}
              >
                <img
                  src={
                    chat.POne._id == userData?._id
                      ? chat.PTwo?.image?.secure_url || avatar
                      : chat.POne?.image?.secure_url || avatar
                  }
                  alt={
                    chat.POne._id == userData?._id
                      ? chat.PTwo.userName
                      : chat.POne.userName
                  }
                  className="w-10 h-10 rounded-full mr-4"
                />
                <div className="w-full">
                  <div className=" flex items-center justify-between">
                    <p className="font-semibold overflow-hidden text-ellipsis whitespace-nowrap max-w-32 ">
                      {chat.POne._id == userData?._id
                        ? chat.PTwo.userName
                        : chat.POne.userName}
                    </p>

                    {chat.messages.length ? (
                      <span className=" text-sm text-gray-400 ">
                        {formatDate(
                          chat.messages[chat.messages.length - 1].date
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className=" flex items-center justify-between">
                    {chat.messages.length ? (
                      <p className="text-sm text-gray-600  overflow-hidden text-ellipsis whitespace-nowrap max-w-40 ">
                        {chat.messages[chat.messages.length - 1].content}
                      </p>
                    ) : (
                      ""
                    )}

                    {/* <div
                      className={`ml-auto size-2  ${
                        chat.POne._id == userData?._id
                          ? chat.PTwo?.status == "online"
                            ? "bg-main"
                            : ""
                          : chat.POne?.status == "online"
                          ? "bg-main"
                          : ""
                      }  rounded-full`}
                    ></div> */}
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default FriendsList;
