import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
function ProductRequestDataLoading() {
  const array = Array(5).fill(0);

  return (
    <>
      {array.map((item, index) => (
        <tr className={`flex items-center w-full`}>
          <td className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
            <div
              className={`flex items-center justify-center w-full  px-5 h-20 ${
                array.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                <Skeleton className="w-full h-7 rounded-md" />
              </div>
            </div>
          </td>
          <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
            <div
              className={`flex items-center justify-center w-full  px-5 h-20 ${
                array.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                <Skeleton className="w-full h-7 rounded-md" />
              </div>
            </div>
          </td>
          <td className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
            <div
              className={`flex items-center justify-center w-full  px-5 h-20 ${
                array.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                <Skeleton className="w-full h-7 rounded-md" />
              </div>
            </div>
          </td>
          <td className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}>
            <div
              className={`flex items-center justify-center w-full  px-5 h-20 ${
                array.length - 1 == index ? "" : " border-b-2"
              }`}
            >
              <div className="w-full">
                {" "}
                <Skeleton className="w-full h-7 rounded-md" />
              </div>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default ProductRequestDataLoading;
