import React, { useContext, useEffect, useRef, useState } from "react";
import { useChatContext } from "../../../../Context/ChatContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { PiPencilSimpleLineLight } from "react-icons/pi";
import { BsThreeDots } from "react-icons/bs";
import { socket } from "../../../../utils/Socket";
import { UserContext } from "../../../../Context/UserContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios, { all } from "axios";

const Message = () => {
  let {
    setSelectedChat,
    selectedChat,
    setIsOpenedChat,
    chats,
    setChats,
    isOpenedChat,
  } = useChatContext();
  const { userData, avatar, baseUrl, token, setUserData, setToken } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [messageHandled, setMessageHandled] = useState(false);
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const chatContainerRef = useRef(null); // Create a ref for the chat container

  useEffect(() => {
    if (selectedChat?._id) {
      setMessages(selectedChat.messages);
    }
  }, [selectedChat]);

  // Scroll to the bottom whenever selectedChat.messages changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const initialValues = {
    content: "",
  };

  const validationSchema = Yup.object({
    content: Yup.string()
      .required("Message is required")
      .min(1, "the minlengt of user name  is 2")
      .max(250, "the maxlength of user name is 20"),
  });

  const sendMessage = async (values) => {
    setIsLoading(true);
    const resieverId =
      selectedChat.POne._id == userData._id
        ? selectedChat.PTwo._id
        : selectedChat.POne._id;
    delete values.sendMessage;
    delete values.message;
    values.destId = resieverId;

    values.authorization = `Hamada__${token}`;
    socket.emit("sendMessage", values);

    // const data = await axios
    //   .post(`${baseUrl}/chat/sendMessage/${resieverId}`, values, {
    //     headers: {
    //       authorization: `Hamada__${token}`,
    //     },
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     console.log(err.response);

    //     if (err?.response?.data?.errMass == "TokenExpiredError: jwt expired") {
    //       localStorage.clear();
    //       setToken(null);
    //       setUserData(null);
    //       navigate("/login");
    //     }
    //   });
    // if (data?.data?.message == "Done") {
    //   setIsLoading(false);
    // }

    // if (newMessage.trim()) {
    // Add new message (In a real app, send it to the server)
    // messages.push({
    //   sender: "me",
    //   content: newMessage,
    //   date: new Date().toISOString(),
    //   senderImage: "https://randomuser.me/api/portraits/men/4.jpg",
    // });
    //   setNewMessage("");
    // }
  };

  socket.on("sendMessage", (data) => {
    const { finalChat, senderId, recieverId, message } = data;

    const currentUserId = userData?._id;

    const selectedChatId = selectedChat?._id;

    const allChats = chats.map((chat) => {
      if (chat._id === finalChat._id) {
        return finalChat;
      } else {
        return chat;
      }
    });

    console.log(selectedChat);

    // if (selectedChat?._id === finalChat._id && isOpenedChat) {

    //   setSelectedChat(finalChat);

    //           if (senderId === currentUserId) {
    //             formik.values.content = "";
    //   }

    //   return
    // }

    if (senderId === currentUserId) {
      setMessages(finalChat.messages);
      formik.values.content = "";
    }

    if (recieverId === currentUserId && selectedChat) {
      if (selectedChat._id === finalChat._id) {
        setMessages(finalChat.messages);
      }
    }

    setChats(allChats);

    // // Case 1: Current user sent the message and is in the selected chat
    // if (senderId === currentUserId && selectedChatId === finalChat._id) {
    //   setSelectedChat(finalChat);
    //   formik.values.content = "";
    //   console.log("Sender is the current user, and chat is selected");
    //   return;
    // }

    // // Case 2: Current user is the receiver, and the chat is opened
    // if (
    //   selectedChatId === finalChat._id &&
    //   recieverId === currentUserId &&
    //   isOpenedChat
    // ) {
    //   setSelectedChat(finalChat);
    //   console.log("Receiver is the current user, and chat is open");
    //   return;
    // }

    // // Case 3: Current user is the receiver, and the selected chat is different
    // if (
    //   recieverId === currentUserId &&
    //   selectedChatId !== finalChat._id &&
    //   !isOpenedChat
    // ) {
    //   const updatedChats = chats?.map((chat) =>
    //     chat._id === finalChat._id ? finalChat : chat
    //   );
    //   setChats(updatedChats);
    //   console.log("Receiver is the current user, and chat is different");
    //   return;
    // }

    // // Default: Handle other cases
    // console.log("No matching conditions.");
    return;
  });

  socket.on("authSocket", (data) => {
    console.log(data);
    if (data == "Expire token") {
      localStorage.clear();
      setToken(null);
      setUserData(null);
      navigate("/login");
    }
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: sendMessage,
  });

  if (!selectedChat) {
    return (
      <div className="hidden text-center text-xl size-full lg:flex items-center justify-center">
        Select a friend to chat with
      </div>
    );
  }

  const handleBack = () => {
    setSelectedChat(null);
    setIsOpenedChat(false);
  };

  const formatDate = (date) => {
    const now = new Date();
    const messageDate = new Date(date);

    // Difference in time
    const timeDifference = now - messageDate;

    // Less than a day (show hours and minutes)
    if (timeDifference < 24 * 60 * 60 * 1000) {
      return messageDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    // More than a day but less than a year (show month, day, hours, and minutes with space between them)
    if (timeDifference < 365 * 24 * 60 * 60 * 1000) {
      const time = messageDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const date = messageDate.toLocaleDateString([], {
        month: "numeric",
        day: "numeric",
      });
      return `${date} ${time}`;
    }

    // More than a year ago (show full date: year, month, day, hour, and minute)
    return (
      messageDate.toLocaleDateString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }) +
      " " +
      messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
  };

  return (
    <div
      className={` ${
        !selectedChat._id && !isOpenedChat ? "hidden lg:flex" : "flex"
      } flex-col border-2 py-4 h-[80vh] rounded-md overflow-y-auto`}
    >
      <div className="flex items-center justify-between px-4 pb-4  border-b-2">
        <div className="flex items-center gap-4">
          <button onClick={handleBack} className="lg:hidden">
            <FaArrowLeft />
          </button>
          <img
            src={
              selectedChat?.POne?._id == userData?._id
                ? selectedChat.PTwo?.image?.secure_url || avatar
                : selectedChat.POne?.image?.secure_url || avatar
            }
            alt={
              selectedChat?.POne?._id == userData?._id
                ? selectedChat.PTwo.userName
                : selectedChat.POne.userName
            }
            className="w-12 h-12 rounded-full"
          />
          <div>
            <p className="text-xl font-semibold">
              {selectedChat?.POne?._id == userData?._id
                ? selectedChat.PTwo.userName
                : selectedChat.POne.userName}
            </p>
            {/* <p className="text-sm text-gray-500">
              Last active:
              { new Date(selectedChat?.messages[-1].date).toLocaleTimeString()}
            </p> */}
          </div>
        </div>
        {/* <div className="bg-product p-2">
          <BsThreeDots />
        </div> */}
      </div>

      {/* Messages Section (Scrollable) */}
      {messages?.length ? (
        <div
          ref={chatContainerRef} // Attach the ref to the container
          className="flex-1 overflow-y-auto space-y-4 p-2 h-80 px-4 scrollbar-thin scrollbar-webkit"
        >
          {messages.map((msg) => (
            <div
              key={msg._id}
              className={`flex  ${
                msg.from._id === userData._id ? "justify-end" : "justify-start"
              }`}
            >
              <div className={`flex flex-col max-w-[70%] justify-center gap-2`}>
                {msg.from._id === userData._id ? (
                  ""
                ) : (
                  <div className="flex items-center gap-2">
                    <img
                      src={msg.to?.image?.secure_url || avatar}
                      alt={msg.to.userName}
                      className="w-8 h-8 rounded-full mr-2"
                    />
                    <p className="font-medium">{msg.to.userName}</p>
                  </div>
                )}

                <div
                  className={`py-2 px-4 rounded-lg ${
                    msg.from._id === userData._id
                      ? "bg-main text-white"
                      : "bg-profileColor"
                  }`}
                >
                  <p className="whitespace-pre-wrap break-words">
                    {msg.content}
                  </p>
                  <span
                    className={`text-xs ${
                      msg.from._id === userData._id
                        ? "text-right block text-white"
                        : "text-profileColorText"
                    }`}
                  >
                    {formatDate(msg.date)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-2 h-80 px-4  text-xl text-center  flex items-center justify-center">
          <p>
            There is no Messages <br /> Start chating
          </p>
        </div>
      )}

      {/* Message Input Section */}
      <form
        className="flex items-center gap-2 p-4 pb-0 border-t"
        onSubmit={formik.handleSubmit}
      >
        <div className=" w-full flex items-center gap-3 border px-2">
          <label htmlFor="content">
            <PiPencilSimpleLineLight className="text-main text-2xl" />
          </label>
          <input
            type="text"
            className="w-full py-2 outline-none"
            id="content"
            name="content"
            max={250}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.content}
            placeholder="Type a message..."
          />
        </div>
        <button
          disabled={!(formik.isValid && formik.dirty)}
          type="submit"
          className=" px-4 py-2 bg-main flex items-center gap-1 text-white "
        >
          Send <IoIosSend />
        </button>
      </form>
    </div>
  );
};

export default Message;

// Only execute the logic once (or based on your conditions)
// if (!messageHandled) {
//   if (
//     (senderId === currentUserId || recieverId === currentUserId) &&
//     selectedChat?._id === finalChat._id
//   ) {
//     console.log("Received a message for the selected chat");
//     setMessageHandled(true);

//     setSelectedChat(finalChat); // Update the selected chat for the sender
//      // Set the flag to true after handling the message
//   } else if (senderId === currentUserId) {
//     console.log("Message sent by the user");
//     setMessageHandled(true);

//     setSelectedChat(finalChat); // Update the selected chat for the sender
//    // Set the flag to true after handling the message
//   }
// }
